import Vue from 'vue';
import Router from 'vue-router';
import Views from './views';

import SharedRoutes from './views/shared/routes';
import AuthService from './services/AuthService';

Vue.use(Router);

const routes = [{
  path: '/login',
  name: 'login',
  component: Views.Auth.Index,
  props: true,
  beforeEnter: (to, from, next) => {
    if (AuthService.isAuthenticated()) {
      return next({
        name: 'home',
      });
    }

    return next();
  },
}, SharedRoutes, {
  path: '**',
  name: 'not-found',
  component: Views.Page404.Index,
}];

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some(record => record.meta.authenticated)) return next();

  if (!AuthService.isAuthenticated()) {
    return next({
      name: 'login',
      query: {
        p: window.location.href,
      },
      params: {
        msg: 'Sessão expirada... Faça o login novamente!',
      },
    });
  }

  AuthService.verifyToken();

  return next();
});

export default router;
