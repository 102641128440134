<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Gerenciar Clientes</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <form @submit.prevent="filterData(1)">
            <div class="row form-group">
              <div class="form-group m0 col-md-3">
                <label class="control-label">Código</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.codigo" />
              </div>

              <div class="form-group m0 col-md-7">
                <label class="control-label">Nome</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.nome" />
              </div>

              <div class="form-group m0 col-md-2">
                <label class="control-label">Estado</label>
                <select class="c-select form-control underlined" v-model="filters.estadoId">
                  <option value>Todos</option>
                  <option
                    v-for="estado in estados"
                    :key="estado.id"
                    :value="estado.id"
                  >{{ estado.uf }}</option>
                </select>
              </div>
            </div>

            <div class="row form-group">
              <div
                class="form-group m0 col-md-4"
                :class="{ 'has-error': $v.filters.document.$error }"
              >
                <label class="control-label">CPF/CNPJ</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.filters.document.$model"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
                <template v-if="$v.filters.document.$dirty">
                  <span
                    v-if="!$v.filters.document.isCPFCNPJValid"
                    class="has-error aasd"
                  >CPF/CNPJ inválido</span>
                </template>
              </div>

              <div class="form-group m0 col-md-8" :class="{ 'has-error': $v.filters.email.$error }">
                <label class="control-label">E-mail</label>
                <input
                  type="email"
                  class="form-control underlined"
                  v-model.trim="$v.filters.email.$model"
                />
                <template v-if="$v.filters.email.$dirty">
                  <span v-if="!$v.filters.email.Email" class="has-error">E-mail inválido</span>
                </template>
              </div>
            </div>
            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? 'BUSCANDO...' : 'BUSCAR' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="result" class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block text-right">
            <button
              type="button"
              class="btn btn-primary m0 asdasdsasdasd"
              @click="addEdit(0)"
            >ADICIONAR NOVO CLIENTE</button>
          </div>
          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th width="20%">CPF/CNPJ</th>
                  <th width="20%">Nome</th>
                  <th width="20%">Cidade - UF</th>
                  <th width="10%">Situação</th>
                  <th width="9%"></th>
                  <th width="10%">
                    <select
                      class="c-select form-control underlined"
                      v-model="filters.limit"
                      @change="filterData(1)"
                    >
                      <option value>Qtd</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody v-if="result && result.data.length > 0">
                <tr v-for="item in result.data" :key="item.id">
                  <th scope="row">
                    <a
                      @click.prevent="view(item.id)"
                      href="#"
                      class="link-detalhes text-info"
                    >{{ item.documento }}</a>
                  </th>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.cidade }} - {{ item.estado }}</td>
                  <td>{{ item.situacao }}</td>
                  <td colspan="2" align="right">
                    <button
                      type="button"
                      class="btn btn-sm btn-info-outline m0"
                      @click="addEdit(item.id)"
                    >EDITAR</button>
                    &nbsp;
                    <button
                      v-if="item.ativo"
                      type="button"
                      class="btn btn-sm btn-danger-outline m0"
                      @click="remove(item.id)"
                    >DESATIVAR</button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-sm btn-info-outline m0"
                      @click="active(item.id)"
                    >ATIVAR</button>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="result.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum resultado encontrado!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="result.pages > 1" class="form-group text-right">
            <paginate
              v-model="result.page"
              :page-count="result.pages"
              :click-handler="nextPrevPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>

    <detalhe-cliente :key="detalheClienteId"></detalhe-cliente>
    <modal-cliente :key="clienteId" @reload="reload"></modal-cliente>
    <modal-remove-cliente :key="desativarClienteId" @reload="reload"></modal-remove-cliente>
    <modal-ativa-cliente :key="ativarClienteId" @reload="reload"></modal-ativa-cliente>
  </article>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { email as Email } from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';
import ClienteService from '../../../services/ClienteService';
import EstadoService from '../../../services/EstadoService';

import DetalheCliente from '../../../components/cliente/Detalhe.vue';
import ModalCliente from '../../../components/cliente/Modal.vue';
import ModalRemoveCliente from '../../../components/cliente/Remove.vue';
import ModalAtivaCliente from '../../../components/cliente/Ativa.vue';

export default {
  name: 'clientes',
  components: {
    Paginate,
    DetalheCliente,
    ModalCliente,
    ModalRemoveCliente,
    ModalAtivaCliente,
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const {
        page,
        limit,
        codigo,
        nome,
        email,
        document,
        estadoId,
      } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.filters.codigo = codigo;
      this.filters.nome = nome;
      this.filters.email = email;
      this.filters.document = document;
      this.filters.estadoId = estadoId;
    }

    this.load();
  },
  data() {
    return {
      enviando: false,
      estados: [],
      result: null,
      detalheClienteId: null,
      clienteId: null,
      desativarClienteId: null,
      ativarClienteId: null,
      filters: {
        page: 1,
        limit: 5,
        codigo: '',
        nome: '',
        email: '',
        document: '',
        estadoId: '',
      },
    };
  },
  validations: {
    filters: {
      email: {
        Email: value => Email(value.toLowerCase()),
      },
      document: {
        isCPFCNPJValid,
      },
    },
  },
  methods: {
    async load() {
      try {
        showLoading();

        this.estados = await EstadoService.findAll();

        this.result = await ClienteService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterData(page) {
      this.$v.filters.$touch();

      if (this.$v.filters.$invalid) return;

      try {
        this.enviando = true;
        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.result = await ClienteService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
      }
    },
    nextPrevPage(page) {
      this.filterData(page);
    },
    addEdit(item) {
      this.clienteId = item;
    },
    remove(item) {
      this.desativarClienteId = item;
    },
    active(item) {
      this.ativarClienteId = item;
    },
    view(item) {
      this.detalheClienteId = item;
    },
    reload() {
      this.filters.codigo = '';
      this.filters.nome = '';
      this.filters.email = '';
      this.filters.document = '';
      this.filters.estadoId = '';
      this.filterData(1);
    },
  },
};
</script>
