<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Gerenciar Tipos de Atendimento</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <form @submit.prevent="filterData(1)">
            <div class="row form-group">
              <div class="form-group m0 col-md-10">
                <label class="control-label">Nome</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.nome" />
              </div>
              <div class="form-group col-md-2 m0 alignVertical">
                <button
                  type="submit"
                  class="btn btn-primary m0"
                  :disabled="enviando"
                >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="result" class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block text-right">
            <button
              type="button"
              class="btn btn-primary m0 asdasdsasdasd"
              @click="addEdit(0)"
            >ADICIONAR NOVO TIPO DE ATENDIMENTO</button>
          </div>
          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th width="60%">Nome</th>
                  <th width="10%"></th>
                  <th width="10%">
                    <select
                      class="c-select form-control underlined"
                      v-model="filters.limit"
                      @change="filterData(1)"
                    >
                      <option value>Qtd</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody v-if="result && result.data.length > 0">
                <tr v-for="item in result.data" :key="item.id">
                  <th scope="row">{{ item.nome }}</th>
                  <td colspan="2" align="right">
                    <button
                      type="button"
                      class="btn btn-sm btn-info-outline m0"
                      @click="addEdit(item.id)"
                    >EDITAR</button>
                    &nbsp;
                    <button
                      type="button"
                      class="btn btn-sm btn-danger-outline m0"
                      @click="remove(item.id)"
                    >REMOVER</button>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="result.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum resultado encontrado!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="result.pages > 1" class="form-group text-right">
            <paginate
              v-model="result.page"
              :page-count="result.pages"
              :click-handler="nextPrevPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>

    <modal-atendimento :key="atendimentoId" @reload="reload"></modal-atendimento>
    <modal-remove-atendimento
      class="asdasdas asdas asdsad"
      :key="removeAtendimentoId"
      @reload="reload"
    ></modal-remove-atendimento>
  </article>
</template>

<script>
import Paginate from 'vuejs-paginate';

import { showLoading, closeLoading } from '../../../../helpers';
import ModalAtendimento from '../../../../components/configuracao/atendimento/Modal.vue';
import ModalRemoveAtendimento from '../../../../components/configuracao/atendimento/Remove.vue';
import TipoAtendimentoService from '../../../../services/TipoAtendimentoService';

export default {
  name: 'tipo-atendimento',
  components: {
    Paginate,
    ModalAtendimento,
    ModalRemoveAtendimento,
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const { page, limit, nome } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.filters.nome = nome;
    }

    this.load();
  },
  data() {
    return {
      enviando: false,
      result: null,
      removeAtendimentoId: null,
      atendimentoId: null,
      filters: {
        page: 1,
        limit: 5,
        nome: '',
      },
    };
  },
  methods: {
    async load() {
      try {
        showLoading();

        this.result = await TipoAtendimentoService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterData(page) {
      try {
        this.enviando = true;

        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.result = await TipoAtendimentoService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    nextPrevPage(page) {
      this.filterData(page);
    },
    addEdit(item) {
      this.atendimentoId = item;
    },
    remove(item) {
      this.removeAtendimentoId = item;
    },
    reload() {
      this.filters.nome = '';
      this.filterData(1);
    },
  },
};
</script>
