<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="scss">
.alignVertical {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dtable {
  display: table;
}
.shadow0 {
  box-shadow: none;
}
.mb0 {
  margin-bottom: 0;
}
.p0 {
  padding: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.m0 {
  margin: 0;
}
.m-auto {
  margin: 0 auto;
}
.read-form {
  .control-label {
    font-size: 0.875;
    margin: 0;
    padding: 0;
  }
  p.form-control.underlined {
    display: table;
    font-size: 0.75rem;
    border-bottom: 1px solid #52bcd3;
    &:disabled {
      background: #fff;
    }
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }

  &.info {
    $color: rgba(#004499, 0.9);

    .tooltip-inner {
      background: $color;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
      max-width: 250px;
      text-align: left;
    }

    .tooltip-arrow {
      border-color: $color;
    }
  }
}

.modal-max-poli {
  .modal-header {
    background-color: #fff;
    color: #52bcd3;
    .close {
      opacity: 0.2;
    }
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 0.3rem;
  }
}

.pagination {
  .page-item.disabled {
    display: none;
  }
}

.link-detalhes {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.radio:checked:disabled + span:before,
.checkbox:checked:disabled + span:before {
  color: #52bcd3;
}
</style>

<script>
import AuthService from './services/AuthService';

export default {
  name: 'app',
  created() {
    this.$eventBus.$off('notificationError');
    // eslint-disable-next-line
    this.$eventBus.$on('notificationError', err => {
      if (err.isConnection || err.isError500) {
        this.$toast.error(err.message);
      } else if (err.is401) {
        AuthService.removeAuth();
        this.$router.push({
          name: 'login',
          query: {
            p: window.location.href,
          },
          params: {
            msg: 'Sessão expirada... Faça o login novamente!',
          },
        });
      } else {
        this.$toast.info(err.message);
      }
    });
  },
};
</script>
