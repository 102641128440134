<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Cadastrar Cliente para Novo Pedido</h3>
    </div>
    <section class="section">
      <div class="row sameheight-container">
        <div class="col-md-6 m-auto">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title"></h3>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group" :class="{ 'has-error': $v.cliente.cpfCnpj.$error }">
                <label class="control-label">CNPJ/CPF:</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.cliente.cpfCnpj.$model"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
                <template v-if="$v.cliente.cpfCnpj.$dirty">
                  <span
                    v-if="!$v.cliente.cpfCnpj.required"
                    class="has-error lblerror"
                  >Campo obrigatório</span>
                  <span
                    v-if="$v.cliente.cpfCnpj.required && !$v.cliente.cpfCnpj.isCPFCNPJValid"
                    class="has-error"
                  >CPF/CNPJ inválido</span>
                </template>
              </div>

              <div class="form-group" :class="{ 'has-error': $v.cliente.nome.$error }">
                <label class="control-label">Nome:</label>
                <input
                  type="text"
                  class="form-control underlined"
                  v-model.trim="$v.cliente.nome.$model"
                />
                <template v-if="$v.cliente.nome.$dirty">
                  <span v-if="!$v.cliente.nome.required" class="has-error">Campo obrigatório</span>
                </template>
              </div>

              <div class="form-group">
                <label class="control-label">Código:</label>
                <input type="text" class="form-control underlined" v-model.trim="cliente.codigo" />
              </div>

              <div class="form-group" :class="{ 'has-error': $v.cliente.estadoId.$error }">
                <label class="control-label">Estado:</label>
                <select
                  class="c-select form-control underlined"
                  v-model="$v.cliente.estadoId.$model"
                  @change="buscarCidades()"
                >
                  <option value>
                    {{
                    estados.length > 0 ? 'Selecione um Estado' : 'Carregando Estados...'
                    }}
                  </option>
                  <option
                    v-for="estado in estados"
                    :key="estado.id"
                    :value="estado.id"
                  >{{ estado.nome }}</option>
                </select>
                <template v-if="$v.cliente.estadoId.$dirty">
                  <span
                    v-if="!$v.cliente.estadoId.required"
                    class="has-error lblerror"
                  >Campo obrigatório</span>
                </template>
              </div>

              <div class="form-group" :class="{ 'has-error': $v.cliente.cidadeId.$error }">
                <label class="control-label">Cidade:</label>
                <select
                  class="c-select form-control underlined"
                  v-model="$v.cliente.cidadeId.$model"
                >
                  <option value>
                    {{
                    (cidades.length > 0 || cliente.estadoId === '')
                    ? 'Selecione uma Cidade'
                    : 'Carregando Cidades...'
                    }}
                  </option>
                  <option
                    v-for="cidade in cidades"
                    :key="cidade.id"
                    :value="cidade.id"
                  >{{ cidade.nome }}</option>
                </select>
                <template v-if="$v.cliente.cidadeId.$dirty">
                  <span
                    v-if="!$v.cliente.cidadeId.required"
                    class="has-error lblerror"
                  >Campo obrigatório</span>
                </template>
              </div>

              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  :disabled="enviando"
                >{{ enviando ? 'CADASTRANDO...' : 'CADASTRAR' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';

import EstadoService from '../../../services/EstadoService';
import ClienteService from '../../../services/ClienteService';

export default {
  name: 'pedido-cliente',
  props: {
    document: {
      type: String,
    },
    nome: {
      type: String,
    },
    telefone: {
      type: String,
    },
    codigo: {
      type: String,
    },
  },
  data() {
    return {
      estados: [],
      cidades: [],
      enviando: false,
      cliente: {
        cpfCnpj: '',
        codigo: '',
        nome: '',
        estadoId: '',
        cidadeId: '',
      },
    };
  },
  validations() {
    const validates = {
      cpfCnpj: {
        required,
        isCPFCNPJValid,
      },
      nome: {
        required,
      },
      estadoId: {
        required,
      },
      cidadeId: {
        required,
      },
    };

    return { cliente: validates };
  },
  created() {
    if (!this.document && !this.nome && !this.telefone && !this.codigo) {
      this.$router.push({ name: 'pedido' });
      return;
    }

    this.cliente.cpfCnpj = this.document;
    this.cliente.nome = this.nome;
    this.cliente.codigo = this.codigo;

    this.load();
  },
  methods: {
    async load() {
      try {
        showLoading();

        this.estados = await EstadoService.findAll();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async buscarCidades() {
      try {
        this.cliente.cidadeId = '';

        if (!this.cliente.estadoId || this.cliente.estadoId <= 0) {
          this.cidades = [];
          return;
        }

        showLoading();

        this.cidades = await EstadoService.findCities(this.cliente.estadoId);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.enviando = true;
        showLoading();

        // eslint-disable-next-line
        const { nome, cidadeId, codigo, cpfCnpj: documento } = this.cliente;

        const data = await ClienteService.save({
          nome,
          documento,
          cidadeId,
          codigo,
        });

        this.$router.push({
          name: 'cliente-pedidos',
          params: { id: data.id },
        });
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
      }
    },
  },
};
</script>
