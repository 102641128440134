<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Relatório de Atendimentos</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="buscarDados()">
          <div class="card card-block">
            <div class="row form-group">
              <div class="form-group m0 col-md-8">
                <label class="control-label">Cliente</label>
                <input
                  type="text"
                  class="form-control underlined"
                  v-model.trim="filters.client"
                />
              </div>
              <div class="form-group m0 col-md-4">
                <label class="control-label">CNPJ/CPF</label>
                <input
                  type="text"
                  class="form-control underlined"
                  v-model.trim="filters.document"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                />
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-2">
                <label class="control-label">Campanha</label>
                <select
                  class="c-select form-control underlined"
                  v-model="mCampaigns"
                  @change="selectedAllCampaigns"
                  multiple
                >
                  <option value="-1">Todas</option>
                  <option
                    v-for="campanha in campanhas"
                    :key="campanha.id"
                    :value="campanha.id"
                  >{{ campanha.nome }}</option>
                </select>
              </div>
              <div class="form-group m0 col-md-2">
                <label class="control-label">Vendedores</label>
                <select
                  class="c-select form-control underlined"
                  v-model="mSellers"
                  @change="selectedAllSellers"
                  multiple
                >
                  <option value="-1">Todos</option>
                  <option
                    v-for="vendedor in vendedores"
                    :key="vendedor.id"
                    :value="vendedor.id"
                  >{{ vendedor.apelido }}</option>
                </select>
              </div>
              <div class="form-group m0 col-md-2">
                <label class="control-label">Ramo de Atividade</label>
                <select
                  class="c-select form-control underlined"
                  v-model="mRamoAtividade"
                  @change="selectedAllRamoAtividade"
                  multiple
                >
                  <option value="-1">Todas</option>
                  <option
                    v-for="ramo in ramoAtividade"
                    :key="ramo.id"
                    :value="ramo.id"
                  >{{ ramo.nome }}</option>
                </select>
              </div>
              <div class="form-group m0 col-md-3">
                <label class="control-label">Clientes sem atendimento?</label>
                <div>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="1"
                      v-model.trim="filters.isNotAttendance"
                    />
                    <span>Sim</span>
                  </label>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="0"
                      v-model.trim="filters.isNotAttendance"
                    />
                    <span>Não</span>
                  </label>
                </div>
              </div>
              <div class="form-group m0 col-md-3">
                <label class="control-label">Data</label>
                <vc-date-picker
                  v-model="range"
                  mode="range"
                  locale="pt-BR"
                  :max-date="date"
                  :popover="{ placement: 'bottom', visibility: 'click' }"
                >
                  <input
                    type="tel"
                    v-mask="'##/##/#### - ##/##/####'"
                    class="form-control underlined"
                    slot-scope="{ inputProps, inputEvents }"
                    v-bind="inputProps"
                    v-on="inputEvents"
                  />
                </vc-date-picker>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-4">
                <label class="control-label">Campos vendedores</label>
                <select
                  class="c-select form-control underlined"
                  v-model="fSellers"
                  @change="selectedAllFieldsSellers"
                  multiple
                >
                  <option value="-1">Todos</option>
                  <option value="0">Nome</option>
                  <option value="1">E-mail</option>
                  <option value="2">CPF</option>
                  <option value="3">Telefone</option>
                </select>
              </div>

              <div class="form-group m0 col-md-4">
                <label class="control-label">Campos clientes</label>
                <select
                  class="c-select form-control underlined"
                  v-model="fClients"
                  @change="selectedAllFieldsClients"
                  multiple
                >
                  <option value="-1">Todos</option>
                  <option value="0">Código</option>
                  <option value="1">Nome</option>
                  <option value="2">CNPJ/CPF</option>
                  <option value="3">Telefone</option>
                  <option value="4">Celular</option>
                  <option value="5">Comercial</option>
                  <option value="6">Email</option>
                  <option value="7">CEP</option>
                  <option value="8">Endereço</option>
                  <option value="9">Número</option>
                  <option value="10">Complemento</option>
                  <option value="11">Bairro</option>
                  <option value="12">Cidade</option>
                  <option value="13">Estado</option>
                  <option value="14">Grupo</option>
                </select>
              </div>

              <div class="form-group m0 col-md-4">
                <label class="control-label">Campos atendimentos</label>
                <select
                  class="c-select form-control underlined"
                  v-model="fAttendances"
                  @change="selectedAllFieldsAttendances"
                  multiple
                >
                  <option value="-1">Todos</option>
                  <option value="0">Protocolo</option>
                  <option value="1">Data</option>
                  <option value="2">Campanha</option>
                  <option value="3">Categoria</option>
                  <option value="4">Contato</option>
                  <option value="5">Email Contato</option>
                  <option value="6">Telefone Contato</option>
                  <option value="7">Data Retorno</option>
                  <option value="8">Último Atendimento</option>
                  <option value="9">Histórico Atendimentos</option>
                  <option value="10">Último Orçamento</option>
                  <option value="11">Histórico Orçamentos</option>
                </select>
              </div>
            </div>

            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "EXPORTANDO..." : "EXPORTAR" }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </article>
</template>

<script>
import moment from 'moment';

import { showLoading, closeLoading } from '../../../../helpers';

import VendedorService from '../../../../services/VendedorService';
import CampanhaService from '../../../../services/CampanhaService';
import AtendimentoService from '../../../../services/AtendimentoService';
import RamoAtividadeService from '../../../../services/RamoAtividadeService';

export default {
  name: 'relatorio-atendimentos',
  created() {
    this.load();
  },
  data() {
    return {
      enviando: false,
      exportando: false,
      ramoAtividade: [],
      campanhas: [],
      vendedores: [],
      date: new Date(),
      range: {
        start: new Date(),
        end: new Date(),
      },
      mSellers: ['-1'],
      mCampaigns: ['-1'],
      mRamoAtividade: ['-1'],
      fSellers: ['-1'],
      fClients: ['-1'],
      fAttendances: ['-1'],
      filters: {
        client: '',
        document: '',
        isNotAttendance: '0',
        sellersId: '',
        campaignsId: '',
        start: '',
        end: '',
        fSellers: '',
        fClients: '',
        fAttendances: '',
        mRamoAtividade: '',
      },
    };
  },
  methods: {
    selectedAllRamoAtividade(e) {
      if (e.target.value === '-1') {
        this.mRamoAtividade = ['-1'];
      }
    },
    selectedAllCampaigns(e) {
      if (e.target.value === '-1') {
        this.mCampaigns = ['-1'];
      }
    },
    selectedAllSellers(e) {
      if (e.target.value === '-1') {
        this.mSellers = ['-1'];
      }
    },
    selectedAllFieldsAttendances(e) {
      if (e.target.value === '-1') {
        this.fAttendances = ['-1'];
      }
    },
    selectedAllFieldsClients(e) {
      if (e.target.value === '-1') {
        this.fClients = ['-1'];
      }
    },
    selectedAllFieldsSellers(e) {
      if (e.target.value === '-1') {
        this.fSellers = ['-1'];
      }
    },
    async load() {
      try {
        showLoading();

        this.campanhas = await CampanhaService.findAll();
        this.vendedores = await VendedorService.findAll();
        this.ramoAtividade = await RamoAtividadeService.findAll();

        this.searchParamsAndFilter();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    searchParamsAndFilter() {
      if (Object.keys(this.$route.query).length > 0) {
        const {
          client,
          document,
          campaignsId,
          mRamoAtividade,
          sellersId,
          isNotAttendance,
          start,
          end,
          fSellers,
          fClients,
          fAttendances,
        } = this.$route.query;

        this.filters.client = client;
        this.filters.document = document;
        this.filters.campaignsId = campaignsId;
        this.filters.sellersId = sellersId;
        this.filters.isNotAttendance = isNotAttendance;

        this.filters.start = start;
        this.filters.end = end;

        this.filters.fSellers = fSellers;
        this.filters.fClients = fClients;
        this.filters.fAttendances = fAttendances;

        this.mCampaigns = campaignsId.split(',');
        this.mRamoAtividade = mRamoAtividade.split(',');
        this.mSellers = sellersId.split(',');
        this.fSellers = fSellers.split(',');
        this.fClients = fClients.split(',');
        this.fAttendances = fAttendances.split(',');

        if (moment(start, 'DD/MM/YYYY').isValid()) {
          this.range.start = moment(start, 'DD/MM/YYYY').toDate();
        }

        if (moment(end, 'DD/MM/YYYY').isValid()) {
          this.range.end = moment(end, 'DD/MM/YYYY').toDate();
        }

        this.buscarDados();
      }
    },
    async buscarDados() {
      try {
        this.enviando = true;

        this.filters.start = moment(this.range.start).format('DD/MM/YYYY');
        this.filters.end = moment(this.range.end).format('DD/MM/YYYY');

        this.filters.campaignsId = this.mCampaigns.join(',');
        this.filters.mRamoAtividade = this.mRamoAtividade.join(',');
        this.filters.sellersId = this.mSellers.join(',');

        this.filters.fSellers = this.fSellers.join(',');
        this.filters.fClients = this.fClients.join(',');
        this.filters.fAttendances = this.fAttendances.join(',');

        this.$router.push({ query: this.filters });

        showLoading();

        await AtendimentoService.dynamicReports({ ...this.filters, export: 1 });

        setTimeout(() => {
          closeLoading();
          this.exportando = false;
        }, 200);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
