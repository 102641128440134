<style lang="scss"></style>

<template>
  <div id="modal-faturamento" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4 class="modal-title">{{ id > 0 ? 'ALTERAR' : 'CADASTRAR' }} TIPO DE FATURAMENTO</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-block shadow0 m0 pb0">
                  <div class="row form-group">
                    <div class="form-group m0 col-md-8" :class="{ 'has-error': $v.nome.$error }">
                      <label class="control-label">Nome</label>
                      <input
                        type="text"
                        class="form-control underlined"
                        v-model.trim="$v.nome.$model"
                      />
                      <template v-if="$v.nome.$dirty">
                        <span
                          v-if="!$v.nome.required"
                          class="has-error lblerror lblerror"
                        >Campo obrigatório</span>
                      </template>
                    </div>
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Percentual</label>
                      <money
                        v-model.trim="percentual"
                        v-bind="maskDefault"
                        class="form-control underlined"
                      ></money>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? "SALVANDO..." : "SALVAR" }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../helpers';
import TipoFaturamentoService from '../../../services/TipoFaturamentoService';

export default {
  name: 'modal-faturamento',
  data() {
    return {
      maskDefault: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '%',
        precision: 2,
        masked: false,
      },
      enviando: false,
      id: 0,
      nome: '',
      percentual: 0,
    };
  },
  validations: {
    nome: {
      required,
    },
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-faturamento').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.faturamentoId = null;
    });

    if (this.$parent.faturamentoId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.id = 0;
      this.nome = '';
      this.percentual = 0;
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.faturamentoId === 0) return;

        const faturamento = await TipoFaturamentoService.findById(
          this.$parent.faturamentoId,
        );

        this.id = faturamento.id;
        this.nome = faturamento.nome;
        this.percentual = faturamento.percentual;
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-faturamento').modal('show');
      }
    },
    async salvar() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const faturamento = {
          id: this.id,
          nome: this.nome,
          percentual: this.percentual,
        };

        await TipoFaturamentoService.save(faturamento);

        this.$toast.success('Tipo de faturamento salva com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-faturamento').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
