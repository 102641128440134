<style lang="scss"></style>

<template>
  <div id="modal-vendedor-detalhe" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-if="vendedor">
        <div class="modal-header">
          <h4 class="modal-title">DETALHES DO VENDEDOR #{{ vendedor.nome }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="read-form">
                  <div class="row">
                    <div class="form-group m0 col-md-8">
                      <label class="control-label">Nome</label>
                      <p class="form-control underlined">{{ vendedor.nome }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">E-mail</label>
                      <p class="form-control underlined">{{ vendedor.email }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Apelido</label>
                      <p class="form-control underlined">{{ vendedor.apelido }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">CPF</label>
                      <p class="form-control underlined">{{ vendedor.cpf }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Telefone</label>
                      <p class="form-control underlined">{{ vendedor.telefone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, closeLoading } from '../../helpers';
import VendedorService from '../../services/VendedorService';

export default {
  name: 'vendedor-detalhe',
  data() {
    return {
      vendedor: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-vendedor-detalhe').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.detalheVendedorId = null;
    });

    if (this.$parent.detalheVendedorId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.vendedor = null;
    },
    async load() {
      try {
        showLoading();

        this.vendedor = await VendedorService.findById(
          this.$parent.detalheVendedorId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-vendedor-detalhe').modal('show');
      }
    },
  },
};
</script>
