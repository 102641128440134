import Vue from 'vue';
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import VueToast from 'vue-toast-notification';
import vueMoment from 'vue-moment';
import VTooltip from 'v-tooltip';
import VueCurrencyFilter from 'vue-currency-filter';
import VCalendar from 'v-calendar';
import money from 'v-money';
import App from './App.vue';
import router from './router';
import store from './store';
import EventBusService from './services/EventBusService';

import 'vue-toast-notification/dist/index.css';

const SocketInstance = socketio(process.env.VUE_APP_URL_SOCKET, {
  transports: ['websocket', 'polling'],
});
Vue.use(new VueSocketIO({
  connection: SocketInstance,
}));

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
});
Vue.use(money, {
  precision: 2,
});
Vue.use(VTooltip);
Vue.use(vueMoment);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueToast);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    'pt-BR': {
      firstDayOfWeek: 1,
      masks: {
        L: 'DD/MM/YYYY',
      },
    },
  },
});

Vue.config.productionTip = false;
Vue.prototype.$eventBus = EventBusService;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
