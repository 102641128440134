import Index from './Index.vue';
import AuthService from '../../../services/AuthService';

export default {
  path: 'usuarios',
  name: 'usuario',
  beforeEnter: (to, from, next) => {
    if (AuthService.isAdmin()) return next();

    return next({
      name: 'home',
      params: {
        accessDenied: true,
      },
    });
  },
  component: Index,
};
