<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <section class="section">
      <dashboard-atendimentos
        title="Atendimentos sem contato mais de 30 dias"
        :podeEditar="false"
        :atendimentos="atendimentoSemContato"
        @load="loadAtedimentosSemContato">
      </dashboard-atendimentos>

      <dashboard-atendimentos
        title="Atendimentos do dia"
        :podeEditar="true"
        :atendimentos="atendimentosDia"
        @load="loadAtedimentosDia">
      </dashboard-atendimentos>

      <dashboard-atendimentos
        title="Atendimentos com retorno do dia"
        :podeEditar="false"
        :atendimentos="atendimentosRetornoDia"
        @load="loadAtedimentosRetornoDia">
      </dashboard-atendimentos>
    </section>
  </article>
</template>

<script>
import moment from 'moment';
import { showLoading, closeLoading } from '../../../helpers';

import AtendimentoService from '../../../services/AtendimentoService';

import DashboardAtendimentos from '../../../components/dashboard/Atendimentos.vue';

export default {
  name: 'dashboard',
  components: {
    DashboardAtendimentos,
  },
  data() {
    return {
      atendimentoSemContato: null,
      atendimentosDia: null,
      atendimentosRetornoDia: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async loadAtedimentosSemContato(filters) {
      try {
        showLoading();

        const filtros = {
          ...filters,
          isNotAttendance: '1',
          start: moment(new Date()).add(-60, 'days').format('DD/MM/YYYY'),
          end: moment(new Date()).add(-30, 'days').format('DD/MM/YYYY'),
          fSellers: '-1',
          fClients: '-1',
          fAttendances: '-1',
        };

        const result = await AtendimentoService.dynamicReports(filtros);

        const data = result.data.map(item => ({
          ...item,
          clienteId: item.cliente.id,
          cliente: item.cliente.nome,
          telefone: item.contato_telefone,
          observacao: item.ultimo_atendimento.observacao,
          tipo: item.categoria,
          vendedor: item.vendedor.nome,
        }));
        this.atendimentoSemContato = { ...result, data };
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async loadAtedimentosDia(filters) {
      try {
        showLoading();
        this.atendimentosDia = await AtendimentoService.dashboardDia(filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async loadAtedimentosRetornoDia(filters) {
      try {
        showLoading();
        this.atendimentosRetornoDia = await AtendimentoService.dashboardRetorno(filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async load() {
      await this.loadAtedimentosSemContato();
      await this.loadAtedimentosDia();
      await this.loadAtedimentosRetornoDia();
    },
  },
};
</script>
