<style lang="scss"></style>

<template>
  <router-view />
</template>

<script>
export default {
  name: 'shared-cliente-pedido-atendimento',
};
</script>
