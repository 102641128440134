<style lang="scss"></style>

<template>
  <div id="modal-op-baixa" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4 class="modal-title">BAIXA DE ORDEM DE PRODUÇÃO - {{ numOP }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="row">
                <div class="form-group col-md-4" :class="{ 'has-error': $v.dataBaixa.$error }">
                  <label class="control-label">Data de Baixa</label>
                  <vc-date-picker
                    v-model.trim="$v.dataBaixa.$model"
                    locale="pt-BR"
                    :popover="{ placement: 'bottom', visibility: 'click' }"
                  >
                    <input
                      type="tel"
                      v-mask="'##/##/####'"
                      class="form-control underlined"
                      slot-scope="{ inputProps, inputEvents }"
                      v-bind="inputProps"
                      v-on="inputEvents"
                    />
                  </vc-date-picker>
                  <template v-if="$v.dataBaixa.$dirty">
                    <span
                      v-if="!$v.dataBaixa.required"
                      class="has-error lblerror lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div class="form-group col-md-8">
                  <label class="control-label">Foi utilizado material do estoque?</label>
                  <div>
                    <label class="m0">
                      <input
                        class="radio"
                        name="rdbMaterialEstoque"
                        type="radio"
                        :value="true"
                        v-model.trim="materialEstoque"
                        @change="materialUtilizadoEstoque(true)"
                      />
                      <span>Sim</span>
                    </label>
                    <label class="m0">
                      <input
                        class="radio"
                        name="rdbMaterialEstoque"
                        type="radio"
                        :value="false"
                        v-model.trim="materialEstoque"
                        @change="materialUtilizadoEstoque(false)"
                      />
                      <span>Não</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!materialEstoque">
                <div class="form-group col-md-4" :class="{ 'has-error': $v.volume.$error }">
                  <label class="control-label">Volume</label>
                  <money
                    v-model.trim="$v.volume.$model"
                    v-bind="maskDefault"
                    class="form-control underlined"
                  ></money>
                  <template v-if="$v.volume.$dirty">
                    <span
                      v-if="!$v.volume.required"
                      class="has-error lblerror lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': validate && $v.quantidade.$error }"
                >
                  <label class="control-label">Quantidade Produzida</label>
                  <money
                    v-model.trim="$v.quantidade.$model"
                    v-bind="maskDefault"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.quantidade.$dirty">
                    <span
                      v-if="$v.quantidade.$error"
                      class="has-error lblerror lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div class="form-group col-md-4" :class="{ 'has-error': $v.dataProducao.$error }">
                  <label class="control-label">Data de Produção</label>
                  <vc-date-picker
                    v-model.trim="$v.dataProducao.$model"
                    locale="pt-BR"
                    :popover="{ placement: 'bottom', visibility: 'click' }"
                  >
                    <input
                      type="tel"
                      v-mask="'##/##/####'"
                      class="form-control underlined"
                      slot-scope="{ inputProps, inputEvents }"
                      v-bind="inputProps"
                      v-on="inputEvents"
                    />
                  </vc-date-picker>
                  <template v-if="$v.dataProducao.$dirty">
                    <span
                      v-if="!$v.dataProducao.required"
                      class="has-error lblerror lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? "SALVANDO..." : "SALVAR" }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { required, minValue } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../helpers';

import OrdemProducaoService from '../../services/OrdemProducaoService';

export default {
  name: 'modal-op-baixa',
  data() {
    return {
      validate: false,
      maskDefault: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      enviando: false,
      ordemProducaoId: 0,
      numOP: null,
      dataBaixa: '',
      materialEstoque: false,
      quantidade: '',
      dataProducao: '',
      volume: '',
    };
  },
  validations() {
    let validates = {
      dataBaixa: {
        required,
      },
    };

    if (!this.materialEstoque) {
      validates = {
        dataBaixa: {
          required,
        },
        quantidade: {
          required,
          minValue: minValue(0.0001),
        },
        dataProducao: {
          required,
        },
        volume: {
          required,
        },
      };
    }

    return validates;
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-op-baixa').on('hidden.bs.modal', () => {
      this.$parent.baixaOrdemProducaoId = null;
    });

    if (this.$parent.baixaOrdemProducaoId === null) return;

    this.load();
  },
  methods: {
    materialUtilizadoEstoque(value) {
      if (value) {
        this.dataProducao = '';
        this.quantidade = '';
        this.volume = '';
        this.validate = false;
      }
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.baixaOrdemProducaoId === 0) return;

        const { baixa, ...ordemProducao } = await OrdemProducaoService.findById(
          this.$parent.baixaOrdemProducaoId,
        );

        this.ordemProducaoId = ordemProducao.opId;
        this.numOP = ordemProducao.numOP;

        if (baixa.baixada) {
          this.dataBaixa = moment(baixa.dataBaixa, 'DD/MM/YYYY').toDate();

          if (baixa.qtd === -1) {
            this.materialEstoque = true;
          } else {
            this.dataProducao = moment(baixa.dataProducao, 'DD/MM/YYYY').toDate();
            this.quantidade = baixa.qtd;
            this.volume = baixa.volume;
          }
        }
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-op-baixa').modal('show');
      }
    },
    async salvar() {
      this.validate = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$toast.info(
          'Por favor, verifique se todos os campos foi preenchido corretamente!',
        );
        return;
      }

      try {
        this.enviando = true;

        showLoading();
        const baixa = {
          dataBaixa: moment(this.dataBaixa, 'DD/MM/YYYY').toJSON(),
          quantidade: this.quantidade,
          dataProducao: moment(this.dataProducao, 'DD/MM/YYYY').toJSON(),
          materialEstoque: this.materialEstoque,
          volume: this.volume,
        };

        await OrdemProducaoService.down(this.ordemProducaoId, baixa);

        this.$toast.success('Ordem de produção baixada com sucesso!');

        // eslint-disable-next-line
        $('#modal-op-baixa').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
      }
    },
  },
};
</script>
