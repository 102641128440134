<style lang="scss">
.rel-content,
.rel-content tbody,
.rel-content tr,
.rel-content td {
  background: transparent !important;
  text-align: center;
}
</style>

<template>
  <table class="table p-0 m-0 rel-content">
    <tbody>
      <tr>
        <td
          class="p-0 m-0 border-0"
          v-for="produto in headersProduto()"
          :key="generateKey(produto.nome)"
        >
          <table class="table p-0 m-0">
            <tbody>
              <tr>
                <td
                  class="p-0 m-0 border-0"
                  v-for="material in headersMateriais(produto)"
                  :key="generateKey(material.nome)"
                >
                  <table class="table p-0 m-0">
                    <tbody>
                      <tr>
                        <td
                          class="p-0 m-0 border-0"
                          v-for="composicao in headersComposicao(material)"
                          :key="generateKey(composicao.nome)"
                        >
                          <table class="table p-0 m-0">
                            <tbody>
                              <tr>
                                <td
                                  class="p-0 m-0 border-0"
                                  v-for="cor in headersCores(composicao)"
                                  :key="generateKey(cor.nome)"
                                >
                                  <table class="table p-0 m-0" style="min-width:110px; width:100%">
                                    <tbody>
                                      <tr>
                                        <td
                                          class="p-0 m-0 border-0"
                                        >{{ qtdVendasDoDia(cor.vendas) }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'rel-content',
  props: {
    dia: {
      type: String,
      required: true,
    },
    produtos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    headersProduto() {
      return this.produtos;
    },
    headersMateriais(produto) {
      return produto.materiais;
    },
    headersComposicao(material) {
      return material.composicoes;
    },
    headersCores(composicao) {
      return composicao.cores;
    },
    qtdVendasDoDia(data) {
      const vendas = data.filter(d => d.dia === this.dia)[0];

      if (!vendas) return 0;

      return vendas.qtd;
    },
    generateKey(nome) {
      const data = new Date();

      const miliseconds = data.getMilliseconds();
      const seconds = data.getSeconds();
      const minutes = data.getMinutes();
      const hours = data.getMinutes();
      return `${nome}-${miliseconds}-${seconds}-${minutes}-${hours}`;
    },
  },
  computed: {
    rowspanProdutos() {
      const qtdProduto = this.produtos.filter(p => p.nome).length;
      return qtdProduto;
    },
  },
};
</script>
