import Index from './Index.vue';
import EstadoRoutes from './estado/routes';
import CidadeRoutes from './cidade/routes';
import CampanhaRoutes from './campanha/routes';
import TipoAtendimentoRoutes from './atendimento/routes';
import TipoFaturamentoRoutes from './faturamento/routes';
import RamoAtividadeRoutes from './ramo-atividade/routes';
import AuthService from '../../../services/AuthService';

const sharedRoutes = [];

const children = sharedRoutes.concat(EstadoRoutes, CidadeRoutes, CampanhaRoutes,
  TipoAtendimentoRoutes, TipoFaturamentoRoutes, RamoAtividadeRoutes);

export default {
  path: 'configuracao/',
  component: Index,
  beforeEnter: (to, from, next) => {
    if (AuthService.isAdmin() || AuthService.isOperacional()) return next();

    return next({
      name: 'home',
      params: {
        accessDenied: true,
      },
    });
  },
  children,
};
