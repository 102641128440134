<template>
  <div class="app blank sidebar-opened">
    <article class="content">
      <div class="error-card global">
        <div class="error-title-block">
          <h1 class="error-title">404</h1>
          <h2 class="error-sub-title">Desculpe, página não encontrada!</h2>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'not-found-index',
};
</script>
