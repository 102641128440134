<style lang="scss"></style>

<template>
  <article v-if="ordemProducao" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Detalhes da Ordem de Produção - {{ ordemProducao.numOP }}</h3>
    </div>
    <section class="section">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Cliente</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-6">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ ordemProducao.cliente.nome }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">CNPJ/CPF</label>
                  <p class="form-control underlined">{{ ordemProducao.cliente.documento }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">UF</label>
                  <p class="form-control underlined">{{ ordemProducao.cliente.uf }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Pedido</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Número</label>
                  <p class="form-control underlined">{{ ordemProducao.pedido.numPedido }}</p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Data</label>
                  <p class="form-control underlined">
                    {{
                    formatDate(ordemProducao.pedido.dataPedido) }}
                  </p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Vendedor</label>
                  <p class="form-control underlined">{{ ordemProducao.pedido.vendedor }}</p>
                </div>
              </div>

              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Data de Entrega</label>
                  <p
                    class="form-control underlined"
                  >{{ formatDate(ordemProducao.pedido.dataEntrega) }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Transportadora</label>
                  <p class="form-control underlined">{{ ordemProducao.pedido.transportadora }}</p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Nota Fiscal</label>
                  <p class="form-control underlined">{{ ordemProducao.pedido.faturamento }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Ordem Produção</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Número</label>
                  <p class="form-control underlined">{{ ordemProducao.numOP }}</p>
                </div>
                <div class="form-group m0 col-md-8">
                  <label class="control-label">Produto</label>
                  <p class="form-control underlined">{{ ordemProducao.produto }}</p>
                </div>
              </div>

              <div class="row" v-if="ordemProducao.cores">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Cores</label>
                  <p class="form-control underlined">{{ ordemProducao.cores }}</p>
                </div>
              </div>

              <div class="row" v-if="ordemProducao.masterbatch || ordemProducao.resina">
                <div class="form-group m0 col-md-4" v-if="ordemProducao.masterbatch">
                  <label class="control-label">MasterBatch</label>
                  <p class="form-control underlined">{{ ordemProducao.masterbatch }}</p>
                </div>

                <div class="form-group m0 col-md-8" v-if="ordemProducao.resina">
                  <label class="control-label">Resina</label>
                  <p class="form-control underlined">{{ ordemProducao.resina }}</p>
                </div>
              </div>

              <div
                class="row"
                v-if="ordemProducao.acabamento
                || ordemProducao.composicao
                || ordemProducao.material"
              >
                <div class="form-group m0 col-md-4" v-if="ordemProducao.acabamento">
                  <label class="control-label">Acabamento</label>
                  <p class="form-control underlined">{{ ordemProducao.acabamento }}</p>
                </div>

                <div class="form-group col-md-4" v-if="ordemProducao.composicao">
                  <label class="control-label">Composição</label>
                  <p class="form-control underlined">{{ ordemProducao.composicao }}</p>
                </div>

                <div class="form-group col-md-4" v-if="ordemProducao.material">
                  <label class="control-label">Material</label>
                  <p class="form-control underlined">{{ ordemProducao.material }}</p>
                </div>
              </div>

              <div class="row" v-if="ordemProducao.medida && ordemProducao.medida !== '-'">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Tipo Medida</label>
                  <p class="form-control underlined">{{ ordemProducao.tipoMedida }}</p>
                </div>

                <div class="form-group m0 col-md-8">
                  <label class="control-label">Medida</label>
                  <p class="form-control underlined">{{ ordemProducao.medida }}</p>
                </div>
              </div>

              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Quantidade</label>
                  <p
                    class="form-control underlined"
                  >{{ ordemProducao.quantidade | currency(maskDefault) }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Unidade de Medida</label>
                  <p class="form-control underlined">{{ ordemProducao.unidadeMedida }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Preço</label>
                  <p class="form-control underlined">{{ ordemProducao.preco | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Baixa</h3>
            </div>
            <div class="read-form">
              <div class="row" v-if="!ordemProducao.baixa.baixada">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Ordem de produção sem dados de baixa</label>
                </div>
              </div>
              <template v-else>
                <div class="row">
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Data de Baixa</label>
                    <p class="form-control underlined">
                      {{
                      formatDate(ordemProducao.baixa.dataBaixa)
                      }}
                    </p>
                  </div>
                  <div class="form-group m0 col-md-8">
                    <label class="control-label">Foi utilizado material do estoque?</label>
                    <p class="form-control underlined">
                      {{
                      ordemProducao.baixa.materialEstoque ? 'Sim' : 'Não'
                      }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="!ordemProducao.baixa.materialEstoque">
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Volume</label>
                    <p
                      v-if="ordemProducao.baixa.volume"
                      class="form-control underlined"
                    >{{ ordemProducao.baixa.volume | currency(maskDefault) }}</p>
                    <p class="form-control underlined" v-else>-</p>
                  </div>
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Quantidade</label>
                    <p class="form-control underlined">
                      {{
                      ordemProducao.baixa.qtd | currency(maskDefault)
                      }}
                    </p>
                  </div>
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Data de Produção</label>
                    <p class="form-control underlined">
                      {{
                      formatDate(ordemProducao.baixa.dataProducao)
                      }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Coleta</h3>
            </div>
            <div class="read-form">
              <div class="row" v-if="!ordemProducao.coleta.coletada">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Ordem de produção sem dados de coleta</label>
                </div>
              </div>
              <div class="row" v-else>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Núm. da Coleta</label>
                  <p class="form-control underlined">{{ ordemProducao.coleta.num || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Responsável da Coleta</label>
                  <p class="form-control underlined">
                    {{ ordemProducao.coleta.responsavel
                    || '-' }}
                  </p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Data da Coleta</label>
                  <p class="form-control underlined">{{ formatDate(ordemProducao.coleta.data) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Conferência</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Ordem de produção foi conferida?</label>
                  <p class="form-control underlined">
                    {{ ordemProducao.conferido ?
                    'Sim' : 'Não'
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <router-link
            class="btn btn-secondary asd asd asd asd"
            :to="{ name: 'ordem-producao' }"
          >VOLTAR</router-link>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import moment from 'moment';
import { showLoading, closeLoading } from '../../../helpers';

import OrdemProducaoService from '../../../services/OrdemProducaoService';

export default {
  name: 'ordem-producao-detalhes',
  data() {
    return {
      maskDefault: {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
      },
      ordemProducaoId: null,
      ordemProducao: null,
    };
  },
  created() {
    const main = document.querySelector('.main-wrapper');
    if (main) {
      main.scrollTop = 0;
    }

    const ordemProducaoId = this.$route.params.id * 1;
    if (!ordemProducaoId || Number.isNaN(ordemProducaoId)) {
      this.$router.push({
        name: 'ordem-producao',
      });
      return;
    }

    this.ordemProducaoId = ordemProducaoId;

    this.loadOP();
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    },
    async loadOP() {
      try {
        showLoading();

        this.ordemProducao = await OrdemProducaoService.findById(
          this.ordemProducaoId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({
          name: 'ordem-producao',
        });
      } finally {
        closeLoading();
      }
    },
  },
};
</script>
