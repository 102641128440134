<style lang="scss"></style>

<template>
  <article v-if="cliente" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Cadastrar Atendimento</h3>
    </div>
    <section class="section">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Cliente</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div class="form-group m0 col-md-6">
                    <label class="control-label">Nome</label>
                    <p class="form-control underlined">{{ cliente.nome }}</p>
                  </div>
                  <div class="form-group m0 col-md-3">
                    <label class="control-label">CNPJ/CPF</label>
                    <p class="form-control underlined">{{ cliente.documento }}</p>
                  </div>
                  <div class="form-group m0 col-md-3">
                    <label class="control-label">UF</label>
                    <p class="form-control underlined">{{
                      cliente && cliente.cidade && cliente.cidade.estado && cliente.cidade.estado.uf
                    }}</p>
                  </div>
                </div>

                <div v-if="cliente.grupo" class="row">
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Grupo</label>
                    <p class="form-control underlined">{{ cliente.grupo.nome }}</p>
                  </div>
                  <div class="form-group m0 col-md-8">
                    <label class="control-label">Clientes</label>
                    <p class="form-control underlined">{{
                      (cliente.grupo.clientes || []).join(', ').trim() || '-'
                    }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Contato</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div
                    class="form-group col-md-12"
                    :class="{ 'has-error': $v.atendimento.dados.contato.$error }"
                  >
                    <label class="control-label">Nome</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.contato.$model"
                    />
                    <template v-if="$v.atendimento.dados.contato.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.contato.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="form-group col-md-9"
                    :class="{ 'has-error': $v.atendimento.dados.emailContato.$error }"
                  >
                    <label class="control-label">E-mail</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.emailContato.$model"
                    />
                    <template v-if="$v.atendimento.dados.emailContato.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.emailContato.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                  <div
                    class="form-group col-md-3"
                    :class="{ 'has-error': $v.atendimento.dados.telefone.$error }"
                  >
                    <label class="control-label">Telefone</label>
                    <the-mask
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.telefone.$model"
                      :masked="true"
                      :mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <template v-if="$v.atendimento.dados.telefone.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.telefone.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Atendimento</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div
                    class="form-group col-md-9"
                    :class="{ 'has-error': $v.atendimento.dados.tipoId.$error }"
                  >
                    <label class="control-label">Tipo</label>
                    <select
                      class="c-select form-control underlined"
                      v-model="$v.atendimento.dados.tipoId.$model"
                    >
                      <option value>
                        {{
                        tiposAtendimentos.length > 0 ?
                        'Selecione um Tipo de Atendimento' :
                        'Carregando Tipos de Atendimento...'
                        }}
                      </option>
                      <option
                        v-for="tipo in tiposAtendimentos"
                        :key="tipo.id"
                        :value="tipo.id"
                      >{{ tipo.nome }}</option>
                    </select>
                    <template v-if="$v.atendimento.dados.tipoId.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.tipoId.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                  <div class="form-group col-md-3">
                    <label class="control-label">Retorno</label>
                    <vc-date-picker
                      v-model="atendimento.dados.retorno"
                      locale="pt-BR"
                      :min-date="new Date()"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="form-group col"
                    :class="{ 'has-error': $v.atendimento.dados.campanhaId.$error }"
                  >
                    <label class="control-label">Campanha</label>
                    <select
                      class="c-select form-control underlined"
                      v-model="$v.atendimento.dados.campanhaId.$model"
                    >
                      <option value>Selecionar uma campanha...</option>
                      <option
                        v-for="campanha in campanhas"
                        :key="campanha.id"
                        :value="campanha.id"
                      >{{ campanha.nome }}</option>
                      <option value="-1">OUTROS</option>
                    </select>
                    <template v-if="$v.atendimento.dados.campanhaId.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.campanhaId.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    v-if="atendimento.dados.campanhaId == -1"
                    class="form-group col-md-3"
                    :class="{ 'has-error': $v.atendimento.dados.campanha.$error }"
                  >
                    <label class="control-label">Nome da campanha</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.campanha.$model"
                    />
                    <template v-if="$v.atendimento.dados.campanha.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.campanha.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group col"
                    :class="{ 'has-error': $v.atendimento.dados.ramoAtividadeId.$error }"
                  >
                    <label class="control-label">Ramo de Atividade</label>
                    <select
                      class="c-select form-control underlined"
                      v-model="$v.atendimento.dados.ramoAtividadeId.$model"
                    >
                      <option value>Selecionar uma ramo de atividade...</option>
                      <option
                        v-for="ramo in ramoAtividade"
                        :key="ramo.id"
                        :value="ramo.id"
                      >{{ ramo.nome }}</option>
                      <option value="-1">OUTROS</option>
                    </select>
                    <template v-if="$v.atendimento.dados.ramoAtividadeId.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.ramoAtividadeId.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    v-if="atendimento.dados.ramoAtividadeId == -1"
                    class="form-group col-md-3"
                    :class="{ 'has-error': $v.atendimento.dados.ramoAtividade.$error }"
                  >
                    <label class="control-label">Nome do ramo de atividade</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.ramoAtividade.$model"
                    />
                    <template v-if="$v.atendimento.dados.ramoAtividade.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.ramoAtividade.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="form-group col-md-12"
                    :class="{ 'has-error': $v.atendimento.dados.observacao.$error }"
                  >
                    <label class="control-label">Histórico</label>
                    <textarea
                      rows="3"
                      class="form-control underlined"
                      v-model.trim="$v.atendimento.dados.observacao.$model"
                    ></textarea>
                    <template v-if="$v.atendimento.dados.observacao.$dirty">
                      <span
                        v-if="!$v.atendimento.dados.observacao.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="ehOrcamento">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Orçamento</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div class="form-group m0 col-md-12">
                    <table class="table m0 table-striped">
                      <thead class="thead-inverse">
                        <tr>
                          <th width="15%">Produto</th>
                          <th width="15%">Cor</th>
                          <th width="40%">Medida (C x L x E)</th>
                          <th width="20%">Quantidade</th>
                          <th width="15%">Preço</th>
                          <th width="10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(orcamento, index) in $v.atendimento.orcamentos.$each.$iter"
                          :key="index"
                        >
                          <td
                            class="form-group"
                            :class="{ 'has-error': !orcamento.produtoId.$dirty
                            || orcamento.produtoId.$error }"
                          >
                            <select
                              class="c-select form-control underlined"
                              v-model="orcamento.produtoId.$model"
                              @change="selecionarTipoProduto(orcamento)"
                            >
                              <option value>-</option>
                              <option
                                v-for="tipo in tiposProduto"
                                :key="tipo.id"
                                :value="tipo.id"
                              >{{ tipo.nome }}</option>
                            </select>
                          </td>
                          <td
                            class="form-group"
                            :class="{ 'has-error': !orcamento.corId.$dirty
                            || orcamento.corId.$error }"
                          >
                            <select
                              class="c-select form-control underlined"
                              v-model="orcamento.corId.$model"
                              :disabled="orcamento.produto.$model
                              && !orcamento.produto.temCor.$model"
                            >
                              <option value>-</option>
                              <option
                                v-for="cor in cores"
                                :key="cor.id"
                                :value="cor.id"
                              >{{ cor.nome }}</option>
                            </select>
                          </td>
                          <td>
                            <div class="form-group row m0">
                              <div
                                class="col-md-3"
                                :class="{ 'has-error': !orcamento.tipoMedida.$dirty
                                || orcamento.tipoMedida.$error }"
                              >
                                <select
                                  class="c-select form-control underlined"
                                  v-model="orcamento.tipoMedida.$model"
                                  :disabled="orcamento.produto.$model
                                  && !orcamento.produto.temMedida.$model"
                                  @change="selecionarTipoMedida(orcamento)"
                                >
                                  <option value>-</option>
                                  <option value="chapa">Chapa</option>
                                  <option value="bobina">Bobina</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <money
                                  v-model.trim="orcamento.comprimento"
                                  v-bind="maskDefault"
                                  class="form-control underlined"
                                  :disabled="orcamento.produto.$model
                                  && !orcamento.produto.temMedida.$model"
                                ></money>
                              </div>
                              <div class="col-md-3">
                                <money
                                  v-model.trim="orcamento.largura"
                                  v-bind="maskDefault"
                                  :disabled="orcamento.produto.$model &&
                                  (!orcamento.produto.temMedida.$model ||
                                  orcamento.tipoMedida.$model === 'bobina')"
                                  class="form-control underlined"
                                ></money>
                              </div>
                              <div class="col-md-3">
                                <money
                                  v-model.trim="orcamento.espessura"
                                  v-bind="maskEspessura"
                                  :disabled="orcamento.produto.$model
                                  && !orcamento.produto.temMedida.$model"
                                  class="form-control underlined"
                                ></money>
                              </div>
                            </div>
                          </td>
                          <td class="form-group">
                            <div class="form-group row m0">
                              <div
                                class="col-md-6"
                                :class="{ 'has-error': !orcamento.qtd.$dirty
                            || orcamento.qtd.$error }"
                              >
                                <money
                                  v-model.trim="orcamento.qtd.$model"
                                  v-bind="maskDefault"
                                  class="form-control underlined"
                                ></money>
                              </div>
                              <div
                                class="col-md-6"
                                :class="{ 'has-error': !orcamento.unidadeMedida.$dirty
                                || orcamento.unidadeMedida.$error }"
                              >
                                <select
                                  class="c-select form-control underlined"
                                  v-model="orcamento.unidadeMedida.$model"
                                  :disabled="orcamento.produto.$model &&
                                  orcamento.produto.unidadeMedida.$model !== 'UN/KG'"
                                >
                                  <option value>-</option>
                                  <option value="UN">UN</option>
                                  <option value="KG">KG</option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td
                            class="form-group"
                            :class="{ 'has-error': !orcamento.preco.$dirty
                            || orcamento.preco.$error }"
                          >
                            <money
                              v-model.trim="orcamento.preco.$model"
                              v-bind="maskPrice"
                              class="form-control underlined"
                            ></money>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger-outline m0"
                              @click="removerOrcamento(index)"
                            >REMOVER</button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="6" align="right" style="padding: 1rem 0;">
                            <button
                              type="button"
                              class="btn m0 btn-sm btn-primary"
                              @click.prevent="addNovoOrcamento"
                            >ADICIONAR NOVO ORÇAMENTO</button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div
                      class="form-group"
                      :class="{ 'has-error': $v.atendimento.orcamentos.$error }"
                    >
                      <template v-if="$v.atendimento.orcamentos.$dirty">
                        <span
                          v-if="!$v.atendimento.orcamentos.minLength
                          || !$v.atendimento.orcamentos.required"
                          class="has-error lblerror"
                        >Insira pelo menos 1 orçamento no grid</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="atendimento.enviando"
            >{{ atendimento.enviando ? 'CADASTRANDO...' : 'CADASTRAR' }}</button>
            &nbsp;&nbsp;
            <button
              @click.prevent="cancelar"
              type="button"
              class="btn btn-secondary"
              :disabled="atendimento.enviando"
            >CANCELAR</button>
          </div>
        </div>
      </form>
    </section>
  </article>
</template>

<script>
import moment from 'moment';
// eslint-disable-next-line
import {
  required,
  minLength,
  minValue,
  requiredIf,
} from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../../helpers';

import ClienteService from '../../../../services/ClienteService';
import AtendimentoService from '../../../../services/AtendimentoService';
import TipoProdutoService from '../../../../services/TipoProdutoService';
import CampanhaService from '../../../../services/CampanhaService';
import CorService from '../../../../services/CorService';
import RamoAtividadeService from '../../../../services/RamoAtividadeService';

export default {
  name: 'cliente-atendimento-cadastrar',
  data() {
    return {
      maskDefault: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      maskEspessura: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      maskPrice: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      orcamentoId: 0,
      clienteId: null,
      cliente: {},
      tiposAtendimentos: [],
      campanhas: [],
      tiposProduto: [],
      cores: [],
      ramoAtividade: [],
      atendimento: {
        enviando: false,
        dados: {
          tipoId: '',
          contato: '',
          emailContato: '',
          telefone: '',
          observacao: '',
          retorno: '',
          campanhaId: '',
          campanha: '',
          ramoAtividadeId: '',
          ramoAtividade: '',
        },
        orcamentos: [],
      },
    };
  },
  validations() {
    const validates = {
      atendimento: {
        dados: {
          tipoId: {
            required,
          },
          contato: {
            required,
          },
          emailContato: {
            required,
          },
          telefone: {
            required,
          },
          observacao: {
            required,
          },
          campanhaId: {
            required,
          },
          ramoAtividadeId: {
            required,
          },
        },
      },
    };

    if (this.atendimento.dados.campanhaId * 1 === -1) {
      validates.atendimento.dados.campanha = {
        required,
      };
    }

    if (this.atendimento.dados.ramoAtividadeId * 1 === -1) {
      validates.atendimento.dados.ramoAtividade = {
        required,
      };
    }

    if (this.ehOrcamento) {
      validates.atendimento.orcamentos = {
        required,
        minLength: minLength(1),
        $each: {
          id: {},
          produto: {
            temCor: {},
            temMedida: {},
            unidadeMedida: {},
          },
          produtoId: {
            required,
          },
          corId: {
            required,
          },
          tipoMedida: {
            required: requiredIf(model => model.produto.temMedida),
          },
          unidadeMedida: {
            required,
          },
          qtd: {
            required,
            minValue: minValue(0.0001),
          },
          preco: {
            required,
            minValue: minValue(0.01),
          },
        },
      };
    }

    return validates;
  },
  computed: {
    ehOrcamento() {
      return this.orcamentoId === this.atendimento.dados.tipoId * 1;
    },
  },
  created() {
    this.orcamentoId = process.env.VUE_APP_ID_ORCAMENTO * 1;

    const main = document.querySelector('.main-wrapper');
    if (main) {
      main.scrollTop = 0;
    }

    const clienteId = this.$route.params.id * 1;

    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'atendimento' });
      return;
    }

    this.clienteId = clienteId;

    this.loadClient();
  },
  methods: {
    addNovoOrcamento() {
      const orcamento = {
        id: 0,
        produto: {
          temCor: true,
          temMedida: true,
          unidadeMedida: 'UN/KG',
        },
        produtoId: '',
        corId: '',
        tipoMedida: '',
        comprimento: '',
        largura: '',
        espessura: '',
        unidadeMedida: '',
        qtd: '0',
        preco: 'R$ 0,00',
      };
      this.atendimento.orcamentos.push(orcamento);
    },
    removerOrcamento(index) {
      const orcamentos = this.atendimento.orcamentos.filter(
        (item, i) => i !== index * 1,
      );
      this.atendimento.orcamentos = orcamentos;
    },
    selecionarTipoMedida(orcamento) {
      const data = orcamento;
      const { tipoMedida } = data;

      if (tipoMedida.$model && tipoMedida.$model === 'bobina') {
        data.largura.$model = '';
      }
    },
    selecionarTipoProduto(orcamento) {
      const data = orcamento;
      const { produtoId } = orcamento;

      if (produtoId.$model) {
        const [tipo] = this.tiposProduto.filter(
          item => item.id === produtoId.$model * 1,
        );
        data.produto.$model = tipo;
      } else {
        data.produto.$model = {
          temCor: true,
          temMedida: true,
          unidadeMedida: 'UN/KG',
        };
      }

      if (data.produto.$model) {
        if (!data.produto.temCor.$model) {
          data.corId.$model = '';
        }

        if (!data.produto.temMedida.$model) {
          data.tipoMedida.$model = '';
          data.comprimento = '';
          data.largura = '';
          data.espessura = '';
        }

        data.unidadeMedida.$model = '';
        if (data.produto.unidadeMedida.$model !== 'UN/KG') {
          data.unidadeMedida.$model = data.produto.unidadeMedida.$model;
        }
      }
    },
    async loadClient() {
      try {
        showLoading();

        this.cliente = await ClienteService.findById(this.clienteId);
        this.verifyInteractionEnabled();
      } catch (error) {
        closeLoading();
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({ name: 'atendimento' });
      }
    },
    async verifyInteractionEnabled() {
      try {
        const {
          podeFazerAtendimento,
          sugestaoContato,
        } = await AtendimentoService.interactionEnabled(this.clienteId);

        if (!podeFazerAtendimento) {
          closeLoading();
          this.$toast.info(
            'Usuário não tem permissão para atendimento desse cliente!',
          );
          this.$router.push({
            name: 'cliente-atendimentos',
            params: { id: this.clienteId },
          });
          return;
        }

        if (sugestaoContato) {
          const { contato, email: emailContato, telefone } = sugestaoContato;
          this.atendimento.dados.contato = contato;
          this.atendimento.dados.emailContato = emailContato;
          this.atendimento.dados.telefone = telefone;
        }

        this.load();
      } catch (error) {
        closeLoading();
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({
          name: 'cliente-atendimentos',
          params: { id: this.clienteId },
        });
      }
    },
    async load() {
      try {
        this.tiposAtendimentos = await AtendimentoService.findCategories();
        this.campanhas = await CampanhaService.findAll();
        this.tiposProduto = await TipoProdutoService.findAll();
        this.cores = await CorService.findAll();
        this.ramoAtividade = await RamoAtividadeService.findAll();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async submit() {
      let formIsValid = true;
      this.$v.atendimento.dados.$touch();

      if (this.$v.atendimento.dados.$invalid) {
        formIsValid = false;
      }

      if (this.ehOrcamento) {
        this.$v.atendimento.orcamentos.$touch();

        if (this.$v.atendimento.orcamentos.$invalid) {
          formIsValid = false;
        }
      }

      if (!formIsValid) {
        this.$toast.info(
          'Por favor, verifique se todos os campos foi preenchido corretamente!',
        );
        return;
      }

      try {
        const {
          dados: {
            tipoId,
            contato,
            emailContato,
            telefone,
            observacao,
            retorno,
            campanha,
            campanhaId,
            ramoAtividade,
            ramoAtividadeId,
          },
          orcamentos,
        } = this.atendimento;

        this.atendimento.enviando = true;
        showLoading();

        const novoAtendimento = {
          clienteId: this.clienteId,
          tipoId: tipoId * 1,
          contato,
          email: emailContato,
          telefone,
          observacao,
          campanha,
          campanhaId: campanhaId * 1,
          ramoAtividade,
          ramoAtividadeId: ramoAtividadeId * 1,
          retorno: moment(retorno, 'DD/MM/YYYY').toJSON(),
        };

        if (this.ehOrcamento) {
          // eslint-disable-next-line
          novoAtendimento.orcamentos = orcamentos.map(item => {
            const data = Object.assign({}, item);
            delete data.produto;
            return data;
          });
        } else {
          novoAtendimento.orcamentos = [];
        }

        await AtendimentoService.save(novoAtendimento);

        this.$toast.success('Atendimento cadastrado com sucesso!');

        this.$router.push({
          name: 'cliente-atendimentos',
          params: { id: this.clienteId },
        });
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.atendimento.enviando = false;
      }
    },
    cancelar() {
      this.$router.push({
        name: 'cliente-atendimentos',
        params: { id: this.clienteId },
      });
    },
  },
};
</script>
