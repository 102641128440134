import Index from './Index.vue';
import Detail from './Detail.vue';
import Report from './Report.vue';
import Cliente from './Cliente.vue';

export default [{
  path: 'atendimento',
  name: 'atendimento',
  component: Index,
}, {
  path: 'atendimento/detalhes/:id',
  name: 'atendimento-detalhes',
  component: Detail,
}, {
  path: 'atendimentos',
  name: 'atendimento-relatorios',
  component: Report,
}, {
  path: 'atendimento/cliente',
  name: 'atendimento-cliente',
  component: Cliente,
  props: true,
}];
