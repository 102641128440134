import Api from './Api';

class GrupoClienteService {
  constructor() {
    this.$api = Api;
  }

  async findAll() {
    return this.$api.get('/grupo-cliente');
  }
}

export default new GrupoClienteService();
