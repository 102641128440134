<style lang="scss"></style>

<template>
  <header class="header">
    <div class="header-block header-block-collapse d-lg-none d-xl-none">
      <button class="collapse-btn" id="sidebar-collapse-btn">
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <div class="header-block header-block-nav">
      <ul class="nav-profile">
        <li class="profile dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="name">{{ usuarioLogado.nome }}</span>
          </a>
          <div class="dropdown-menu profile-dropdown-menu" aria-labelledby="dropdownMenu1">
            <router-link :to="{ name: 'meus-dados' }" class="dropdown-item">
              <i class="fa fa-user icon"></i> Meus Dados
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent="sair">
              <i class="fa fa-power-off icon"></i> Sair
            </a>
          </div>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  name: 'shared-header',
  computed: {
    usuarioLogado() {
      const auth = AuthService.getAuth();

      if (!auth) return '-';

      return auth;
    },
  },
  methods: {
    sair() {
      AuthService.removeAuth();

      this.$router.push({ name: 'login' });
    },
  },
};
</script>
