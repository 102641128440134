<style lang="scss"></style>

<template>
  <div id="modal-cliente-detalhe" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-if="cliente">
        <div class="modal-header">
          <h4 class="modal-title">DETALHES DO CLIENTE #{{ cliente.nome }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="read-form">
                  <div class="title-block">
                    <h3 class="title">Dados</h3>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-3">
                      <label class="control-label">Código</label>
                      <p class="form-control underlined">{{ cliente.codigo || '-' }}</p>
                    </div>
                    <div class="form-group m0 col-md-3">
                      <label class="control-label">CNPJ/CPF</label>
                      <p class="form-control underlined">{{ cliente.documento || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-6">
                      <label class="control-label">Nome</label>
                      <p class="form-control underlined">{{ cliente.nome || '-' }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Telefone</label>
                      <p class="form-control underlined">{{ cliente.telefone || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Celular</label>
                      <p class="form-control underlined">{{ cliente.celular || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Comercial</label>
                      <p class="form-control underlined">{{ cliente.comercial || '-' }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-12">
                      <label class="control-label">E-mail</label>
                      <p class="form-control underlined">{{ cliente.email || '-' }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-6">
                      <label class="control-label">Grupo</label>
                      <p class="form-control underlined">{{
                        cliente.grupo ? cliente.grupo.nome : 'Nenhum'
                      }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="read-form">
                  <div class="title-block">
                    <h3 class="title">Endereço</h3>
                  </div>
                  <div class="row">
                    <div class="form-group m0 col-md-3">
                      <label class="control-label">CEP</label>
                      <p class="form-control underlined">{{ cliente.cep || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-9">
                      <label class="control-label">Endereço</label>
                      <p class="form-control underlined">{{ cliente.endereco || '-' }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-3">
                      <label class="control-label">Número</label>
                      <p class="form-control underlined">{{ cliente.numero || '-' }}</p>
                    </div>
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Complemento</label>
                      <p class="form-control underlined">{{ cliente.complemento || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-5">
                      <label class="control-label">Bairro</label>
                      <p class="form-control underlined">{{ cliente.bairro || '-' }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-8">
                      <label class="control-label">Cidade</label>
                      <p class="form-control underlined">{{ cliente.cidade.nome || '-' }}</p>
                    </div>

                    <div class="form-group m0 col-md-2">
                      <label class="control-label">Estado</label>
                      <p class="form-control underlined">{{ cliente.cidade.estado.uf || '-' }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, closeLoading } from '../../helpers';
import ClienteService from '../../services/ClienteService';

export default {
  name: 'cliente-detalhe',
  data() {
    return {
      cliente: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-cliente-detalhe').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.detalheClienteId = null;
    });

    if (this.$parent.detalheClienteId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.cliente = null;
    },
    async load() {
      try {
        showLoading();

        this.cliente = await ClienteService.findById(
          this.$parent.detalheClienteId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-cliente-detalhe').modal('show');
      }
    },
  },
};
</script>
