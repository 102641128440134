<style lang="scss"></style>

<template>
  <div id="modal-ramo-atividade" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4 class="modal-title">{{ id > 0 ? 'ALTERAR' : 'CADASTRAR' }} RAMO DE ATIVIDADE</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-block shadow0 m0 pb0">
                  <div class="row form-group">
                    <div class="form-group m0 col-md-12" :class="{ 'has-error': $v.nome.$error }">
                      <label class="control-label">Nome</label>
                      <input
                        type="text"
                        class="form-control underlined"
                        v-model.trim="$v.nome.$model"
                      />
                      <template v-if="$v.nome.$dirty">
                        <span
                          v-if="!$v.nome.required"
                          class="has-error lblerror lblerror"
                        >Campo obrigatório</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? "SALVANDO" : "SALVAR" }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../helpers';
import RamoAtividadeService from '../../../services/RamoAtividadeService';

export default {
  name: 'modal-ramo-atividade',
  data() {
    return {
      enviando: false,
      id: 0,
      nome: '',
    };
  },
  validations: {
    nome: {
      required,
    },
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-ramo-atividade').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.ramoAtividadeId = null;
    });

    if (this.$parent.ramoAtividadeId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.id = 0;
      this.nome = '';
      this.uf = '';
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.ramoAtividadeId === 0) return;

        const ramoAtividade = await RamoAtividadeService.findById(
          this.$parent.ramoAtividadeId,
        );

        this.id = ramoAtividade.id;
        this.nome = ramoAtividade.nome;
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-ramo-atividade').modal('show');
      }
    },
    async salvar() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const ramoAtividade = {
          id: this.id,
          nome: this.nome,
        };

        await RamoAtividadeService.save(ramoAtividade);

        this.$toast.success('Ramo de atividade salvo com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-ramo-atividade').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
