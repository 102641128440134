import moment from 'moment';
import Api from './Api';

class AtendimentoService {
  constructor() {
    this.$url = '/atendimento';
    this.$api = Api;
  }

  async interactionEnabled(clientId) {
    const url = `/cliente/${clientId}/atendimento/habilitado`;
    return this.$api.get(url);
  }

  async create(atendimento) {
    const url = this.$url;
    return this.$api.post(url, atendimento);
  }

  async save(data) {
    const url = this.$url;
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post(url, data);
    }

    return this.$api.put(`${url}/${id}`, data);
  }

  async findAll(filters) {
    const url = '/atendimentos/report';
    return this.$api.get(url, filters);
  }

  async dashboardDia(filters) {
    const url = '/atendimentos/dia';
    return this.$api.get(url, filters);
  }

  async dashboardRetorno(filters) {
    const url = '/atendimentos/retorno';
    return this.$api.get(url, filters);
  }

  async findById(id) {
    const url = `/atendimento/${id}`;
    return this.$api.get(url);
  }

  async exportExcel(filters) {
    const params = filters;
    params.excel = 1;

    const url = '/atendimentos/report';

    const file = await this.$api.file(url, params);

    delete params.excel;

    const urlDownload = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    const filename = `Relatório-de-atendimento-${data}.xlsx`;

    link.href = urlDownload;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  async findByClient(clientId, filters) {
    const url = `/cliente/${clientId}/atendimentos`;
    return this.$api.get(url, filters);
  }

  async findCategories() {
    const url = '/tipos-atendimento';
    return this.$api.get(url);
  }

  async remove(id) {
    const url = `${this.$url}/${id}`;
    return this.$api.delete(url);
  }

  async dynamicReports(filters) {
    const url = '/atendimento/relatorios/dinamicos';

    if (filters && filters.export === 1) {
      const file = await this.$api.get(url, filters);

      const link = document.createElement('a');

      link.href = `${process.env.VUE_APP_URL_API}${file.path}`;
      link.setAttribute('download', file.filename);
      document.body.appendChild(link);
      link.click();
      return null;
    }

    return this.$api.get(url, filters);
  }

  async reportsSellers(filters) {
    const url = `${this.$url}/relatorios/vendedores`;
    return this.$api.get(url, filters);
  }
}

export default new AtendimentoService();
