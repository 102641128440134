<style lang="scss"></style>

<template>
  <div id="modal-tipo-produto" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4 class="modal-title">{{ id > 0 ? 'ALTERAR' : 'CADASTRAR' }} TIPO DE PRODUTO</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-block shadow0 m0 pb0">
                  <div class="row form-group">
                    <div class="form-group m0 col-md-8" :class="{ 'has-error': $v.nome.$error }">
                      <label class="control-label">Nome</label>
                      <input
                        type="text"
                        class="form-control underlined"
                        v-model.trim="$v.nome.$model"
                      />
                      <template v-if="$v.nome.$dirty">
                        <span
                          v-if="!$v.nome.required"
                          class="has-error lblerror lblerror"
                        >Campo obrigatório</span>
                      </template>
                    </div>
                    <div class="form-group m0 col-md-4" :class="{ 'has-error': $v.nome.$error }">
                      <label class="control-label">Unidade de Medida</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbUnidadeMedida"
                            type="radio"
                            value="KG"
                            v-model.trim="unidadeMedida"
                          />
                          <span>KG</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbUnidadeMedida"
                            type="radio"
                            value="UN"
                            v-model.trim="unidadeMedida"
                          />
                          <span>UN</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbUnidadeMedida"
                            type="radio"
                            value="UN/KG"
                            v-model.trim="unidadeMedida"
                          />
                          <span>UN/KG</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Medida?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMedida"
                            type="radio"
                            :value="true"
                            v-model.trim="temMedida"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMedida"
                            type="radio"
                            :value="false"
                            v-model.trim="temMedida"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Acabamento?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemAcabamento"
                            type="radio"
                            :value="true"
                            v-model.trim="temAcabamento"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemAcabamento"
                            type="radio"
                            :value="false"
                            v-model.trim="temAcabamento"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Aditivo?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemAditivo"
                            type="radio"
                            :value="true"
                            v-model.trim="temAditivo"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemAditivo"
                            type="radio"
                            :value="false"
                            v-model.trim="temAditivo"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Composição?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemComposicao"
                            type="radio"
                            :value="true"
                            v-model.trim="temComposicao"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemComposicao"
                            type="radio"
                            :value="false"
                            v-model.trim="temComposicao"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Cor?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemCor"
                            type="radio"
                            :value="true"
                            v-model.trim="temCor"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemCor"
                            type="radio"
                            :value="false"
                            v-model.trim="temCor"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Masterbatch?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMasterbatch"
                            type="radio"
                            :value="true"
                            v-model.trim="temMasterbatch"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMasterbatch"
                            type="radio"
                            :value="false"
                            v-model.trim="temMasterbatch"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Resina Base?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemResinaBase"
                            type="radio"
                            :value="true"
                            v-model.trim="temResinaBase"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemResinaBase"
                            type="radio"
                            :value="false"
                            v-model.trim="temResinaBase"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Material?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMaterial"
                            type="radio"
                            :value="true"
                            v-model.trim="temMaterial"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemMaterial"
                            type="radio"
                            :value="false"
                            v-model.trim="temMaterial"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Tem Faturamento?</label>
                      <div>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemFaturamento"
                            type="radio"
                            :value="true"
                            v-model.trim="temFaturamento"
                          />
                          <span>Sim</span>
                        </label>
                        <label>
                          <input
                            class="radio"
                            name="rdbTemFaturamento"
                            type="radio"
                            :value="false"
                            v-model.trim="temFaturamento"
                          />
                          <span>Não</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? "SALVANDO..." : "SALVAR" }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../helpers';
import TipoProdutoService from '../../../services/TipoProdutoService';

export default {
  name: 'modal-tipo-produto',
  data() {
    return {
      enviando: false,
      id: 0,
      nome: '',
      unidadeMedida: 'KG',
      temMedida: false,
      temAcabamento: false,
      temAditivo: true,
      temComposicao: false,
      temCor: true,
      temMasterbatch: true,
      temResinaBase: true,
      temMaterial: false,
      temFaturamento: true,
    };
  },
  validations: {
    nome: {
      required,
    },
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-tipo-produto').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.tipoProdutoId = null;
    });

    if (this.$parent.tipoProdutoId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.id = 0;
      this.nome = '';
      this.unidadeMedida = 'KG';
      this.temMedida = false;
      this.temAcabamento = false;
      this.temAditivo = true;
      this.temComposicao = false;
      this.temCor = true;
      this.temMasterbatch = true;
      this.temResinaBase = true;
      this.temMaterial = false;
      this.temFaturamento = true;
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.tipoProdutoId === 0) return;

        const tipoProduto = await TipoProdutoService.findById(
          this.$parent.tipoProdutoId,
        );

        this.id = tipoProduto.id;
        this.nome = tipoProduto.nome;
        this.unidadeMedida = tipoProduto.unidadeMedida;
        this.temMedida = tipoProduto.temMedida;
        this.temAcabamento = tipoProduto.temAcabamento;
        this.temAditivo = tipoProduto.temAditivo;
        this.temComposicao = tipoProduto.temComposicao;
        this.temCor = tipoProduto.temCor;
        this.temMasterbatch = tipoProduto.temMasterbatch;
        this.temResinaBase = tipoProduto.temResinaBase;
        this.temMaterial = tipoProduto.temMaterial;
        this.temFaturamento = tipoProduto.temFaturamento;
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-tipo-produto').modal('show');
      }
    },
    async salvar() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const {
          id,
          nome,
          unidadeMedida,
          temMedida,
          temAcabamento,
          temAditivo,
          temComposicao,
          temCor,
          temMasterbatch,
          temResinaBase,
          temMaterial,
          temFaturamento,
        } = this;

        const tipoProduto = {
          id,
          nome,
          unidadeMedida,
          temMedida,
          temAcabamento,
          temAditivo,
          temComposicao,
          temCor,
          temMasterbatch,
          temResinaBase,
          temMaterial,
          temFaturamento,
        };

        await TipoProdutoService.save(tipoProduto);

        this.$toast.success('Tipo de produto salvo com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-tipo-produto').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
