import moment from 'moment';
import Api from './Api';

class OrdemProducaoService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/ordens-producao', filters);
  }

  async findById(id) {
    return this.$api.get(`/ordem-producao/${id}`);
  }

  async verified(id, conferido) {
    return this.$api.put(`/ordem-producao/${id}/conferencia`, {
      conferido,
    });
  }

  async down(id, data) {
    return this.$api.put(`/ordem-producao/${id}/baixa`, data);
  }

  async collect(id, data) {
    return this.$api.put(`/ordem-producao/${id}/coleta`, data);
  }

  async export(filters) {
    const params = filters;

    const url = '/ordem-producao/exportar';

    const file = await this.$api.file(url, params);

    delete params.excel;

    const urlDownload = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    const filename = `Relatório-de-ordem-producao-${data}.xlsx`;

    link.href = urlDownload;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
}

export default new OrdemProducaoService();
