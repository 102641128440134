import Index from './Index.vue';
import DashboardRoutes from './dashboard/routes';
import AtendimentoRoutes from './atendimento/routes';
import ClienteRoutes from './cliente/routes';
import ConfiguracaoRoutes from './configuracao/routes';
import ProdutoRoutes from './produto/routes';
import VendedorRoutes from './vendedor/routes';
import UsuarioRoutes from './usuario/routes';
import MeusDadosRoutes from './meus-dados/routes';
import PedidoRoutes from './pedido/routes';
import OrdemProducaoRoutes from './ordem-producao/routes';
import RelatorioRoutes from './relatorio/routes';

const sharedRoutes = [];

const children = sharedRoutes.concat(DashboardRoutes, AtendimentoRoutes, ClienteRoutes,
  ProdutoRoutes, ConfiguracaoRoutes, VendedorRoutes, PedidoRoutes, UsuarioRoutes, MeusDadosRoutes,
  OrdemProducaoRoutes, RelatorioRoutes);

export default {
  path: '/',
  component: Index,
  props: true,
  meta: {
    authenticated: true,
  },
  children,
};
