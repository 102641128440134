<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Importar Pedidos</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block">
            <h3 class="title">
              Selecione o arquivo de pedidos que deseja importar
            </h3>
          </div>
          <form @submit="submitForm">
            <div class="form-group m0">
              <input
                type="file"
                id="file"
                name="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.ms-excel"
                ref="file"
                v-on:change="handleFileUpload()"
              />
            </div>

            <div class="form-group m0 mt-3">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "IMPORTANDO..." : "IMPORTAR" }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { showLoading, closeLoading } from '../../../helpers';
import PedidoService from '../../../services/PedidoService';

export default {
  name: 'pedido-importar',
  data() {
    return {
      enviando: false,
      file: null,
    };
  },
  methods: {
    handleFileUpload() {
      const arquivo = this.$refs.file.files[0];
      this.file = arquivo;
    },
    async submitForm(e) {
      e.preventDefault();

      if (!this.file) {
        this.$toast.error('Por favor informe o arquivo para importar.');
        return;
      }

      try {
        this.enviando = true;

        showLoading();

        const { message } = await PedidoService.import(this.file);

        this.file = null;
        e.target.reset();

        this.$toast.success(message);
      } catch (error) {
        this.$toast.error('Desculpe, não foi possível importar o arquivo.');
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
