import Api from './Api';

class CorService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/cores', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/cor', data);
    }

    return this.$api.put(`/cor/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/cor/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/cor/${id}`);
  }
}

export default new CorService();
