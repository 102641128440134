<style lang="scss"></style>

<template>
  <div class="main-wrapper">
    <div class="app header-fixed footer-fixed sidebar-fixed" id="app">
      <shared-header></shared-header>
      <shared-menu></shared-menu>
      <router-view />
    </div>
  </div>
</template>

<script>
import SharedComponents from '../../components/shared';

export default {
  name: 'shared',
  props: {
    accessDenied: null,
  },
  components: {
    sharedHeader: SharedComponents.Header,
    sharedMenu: SharedComponents.Menu,
  },
  created() {
    if (this.accessDenied) {
      this.$toast.error('Você não tem permissão para acessa está pagina.');
    }
  },
  sockets: {
    messageImportarPedidos(data) {
      const { type, message } = data;
      if (type === 'failed') {
        this.$toast.error(message);
      } else {
        this.$toast.success(message);
      }
    },
  },
};
</script>
