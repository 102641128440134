<style lang="scss"></style>

<template>
  <div id="modal-remove-cor" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">ATENÇÃO!</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="title-block" v-if="cor">
                  <h3 class="title adasd">
                    Tem certeza que deseja remover a cor
                    <span class="text-info">{{ cor.nome }}</span>?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="remover"
            :disabled="enviando"
          >{{ enviando ? "REMOVENDO..." : "SIM" }}</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">NÃO</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, closeLoading } from '../../../helpers';
import CorService from '../../../services/CorService';

export default {
  name: 'modal-remove-cor',
  data() {
    return {
      enviando: false,
      cor: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-remove-cor').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.removeCorId = null;
    });

    if (this.$parent.removeCorId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.cor = null;
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.removeCorId === 0) return;

        this.cor = await CorService.findById(this.$parent.removeCorId);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-remove-cor').modal('show');
      }
    },
    async remover() {
      try {
        this.enviando = true;

        showLoading();

        await CorService.remove(this.cor.id);

        this.$toast.success('Cor removida com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-remove-cor').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
