import Api from './Api';

class RamoAtividadeService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/ramo-atividade', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/ramo-atividade', data);
    }

    return this.$api.put(`/ramo-atividade/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/ramo-atividade/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/ramo-atividade/${id}`);
  }
}

export default new RamoAtividadeService();
