import Api from './Api';

class VendedorService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/vendedores', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/vendedor', data);
    }

    return this.$api.put(`/vendedor/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/vendedor/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/vendedor/${id}`);
  }
}

export default new VendedorService();
