<style lang="scss"></style>

<template>
  <div  class="row">
    <div class="col-md-12">
      <div v-if="atendimentos" class="card card-block">
        <div class="title-block">
          <h3 class="title text-uppercase">
            {{ title }}
            ({{ atendimentos.count }})
          </h3>
        </div>

        <div class="row form-group">
          <div class="form-group m0 col-md-11"></div>
          <div class="form-group m0 col-md-1 pl0">
            <select
              class="c-select form-control underlined"
              v-model="filters.limit"
              @change="load(1)"
            >
              <option value>Qtd</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <table class="table table-striped">
            <thead class="thead-inverse">
              <tr>
                <th>#</th>
                <th>Cliente</th>
                <th>Contato</th>
                <th>Telefone</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="atendimentos && atendimentos.data.length > 0">
              <tr
                v-for="item in atendimentos.data"
                :key="item.id"
                v-tooltip="{
                  content: createTooltip(item),
                  placement: 'left',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip']
                }"
              >
                <th scope="row">
                  <router-link
                    :to="{ name: 'atendimento-detalhes',
                          params: { id: item.id } }"
                    class="link-detalhes text-info"
                  >{{ item.protocolo }}</router-link>
                </th>
                <td>{{ item.cliente }}</td>
                <td>{{ item.contato }}</td>
                <td>{{ item.telefone }}</td>
                <td>
                  <template v-if="podeEditar">
                    <router-link
                      target="_blank"
                      :to="{ name: 'cliente-atendimento-editar',
                      params: { id: item.clienteId, atendimentoId: item.id } }"
                      class="btn btn-sm btn-info-outline m0"
                      :class="{ disabled: !item.clienteId }"
                    >EDITAR</router-link>
                  </template>
                  <template v-else>
                    <router-link
                      target="_blank"
                      :to="{ name: 'cliente-atendimento-cadastrar',
                      params: { id: item.clienteId } }"
                      class="btn btn-sm btn-info-outline m0"
                      :class="{ disabled: !item.clienteId }"
                    >NOVO</router-link>
                  </template>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="atendimentos.data.length <= 0">
              <tr>
                <td colspan="7">
                  <p class="m0 p0 text-center">Nenhum atendimento encontrado!</p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div v-if="atendimentos.pages > 1" class="form-group text-right">
          <paginate
            v-model="atendimentos.page"
            :page-count="atendimentos.pages"
            :click-handler="nextPrevPageAtendimentos"
            :prev-text="'Anterior'"
            :next-text="'Próxima'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :prev-class="'page-item'"
            :next-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Paginate from 'vuejs-paginate';

export default {
  name: 'dashboard-atendimentos',
  components: {
    Paginate,
  },
  props: {
    atendimentos: Object,
    title: String,
    podeEditar: Boolean,
  },
  data() {
    return {
      filters: {
        page: '1',
        limit: '5',
      },
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YY');
    },
    createTooltip(item) {
      const tooltip = `
        <b>Retorno:</b><br/> ${this.formatDate(item.retorno)}
        <br/><br/>
        <b>Campanha:</b><br/> ${item.campanha}
        <br/><br/>
        <b>Observação:</b><br/> ${item.observacao}
        <br/><br/>
        <b>Tipo:</b><br/> ${item.tipo}
        <br/><br/>
        <b>Vendedor:</b><br/> ${item.vendedor}
        <br/><br/>
        <b>Ramo de Atividade:</b><br/> ${item.ramoAtividade}
      `;

      return tooltip;
    },
    async load(page) {
      this.$emit('load', { ...this.filters, page });
    },
    async nextPrevPageAtendimentos(page) {
      return this.load(page);
    },
  },
};
</script>
