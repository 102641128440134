import Index from './Index.vue';
import Cliente from './Cliente.vue';
import Detail from './Detail.vue';
import Report from './Report.vue';
import Import from './Import.vue';
import AuthService from '../../../services/AuthService';

const validateBeforeEnter = (to, from, next) => {
  if (AuthService.isAdmin() || AuthService.isOperacional() || AuthService.isComercial()) {
    return next();
  }

  return next({
    name: 'home',
    params: {
      accessDenied: true,
    },
  });
};

export default [{
  path: 'pedido',
  name: 'pedido',
  beforeEnter: validateBeforeEnter,
  component: Index,
}, {
  path: 'pedido/detalhes/:id',
  name: 'pedido-detalhes',
  component: Detail,
}, {
  path: 'pedidos',
  name: 'pedido-relatorios',
  component: Report,
}, {
  path: 'pedido/cliente',
  name: 'pedido-cliente',
  component: Cliente,
  beforeEnter: validateBeforeEnter,
  props: true,
}, {
  path: 'pedido/importar',
  name: 'pedido-importar',
  beforeEnter: validateBeforeEnter,
  component: Import,
}];
