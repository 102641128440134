<style lang="scss" scoped>
.sidebar {
  padding-bottom: 0;
  .sidebar-container {
    bottom: 0;
  }
}
</style>

<template>
  <div>
    <aside class="sidebar">
      <div class="sidebar-container">
        <div class="sidebar-header">
          <div class="brand">
            <div class="m-auto">
              <img src="../../assets/logo.png" class="img-fluid" style="width: 80%;" alt />
            </div>
          </div>
        </div>
        <nav class="menu">
          <ul class="sidebar-menu metismenu" id="sidebar-menu">
            <router-link tag="li" active-class="active" exact :to="{ name: 'home' }">
              <a href>
                <i class="fa fa-home"></i>
                Início
              </a>
              <ul class="sidebar-nav" style="height: 0"></ul>
            </router-link>
            <li :class="activeMenu(['atendimento', 'atendimento-relatorios'])">
              <a href>
                <i class="fa fa-phone"></i> Atendimento
                <i class="fa arrow"></i>
              </a>

              <ul
                class="sidebar-nav"
                :style="activeSubmenu(['atendimento', 'atendimento-relatorios'])"
              >
                <router-link tag="li" active-class="active" exact :to="{ name: 'atendimento' }">
                  <a href>
                    <i class="fa fa-plus"></i>
                    Novo
                  </a>
                </router-link>
                <router-link
                  tag="li"
                  active-class="active"
                  :to="{ name: 'atendimento-relatorios' }"
                >
                  <a href>
                    <i class="fa fa-table"></i>
                    Consultar
                  </a>
                </router-link>
              </ul>
            </li>

            <li :class="activeMenu(['pedido', 'pedido-relatorios'])">
              <a href>
                <i class="fa fa-calendar"></i> Pedido
                <i class="fa arrow"></i>
              </a>

              <ul class="sidebar-nav"
                :style="activeSubmenu(['pedido', 'pedido-relatorios', 'pedido-importar'])"
              >
                <router-link
                  tag="li"
                  active-class="active"
                  exact
                  :to="{ name: 'pedido' }"
                  v-if="isAdmin || isOperacional || isComercial"
                >
                  <a href>
                    <i class="fa fa-plus"></i>
                    Novo
                  </a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'pedido-relatorios' }">
                  <a href>
                    <i class="fa fa-table"></i>
                    Consultar
                  </a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'pedido-importar' }">
                  <a href>
                    <i class="fa fa-upload"></i>
                    Importar
                  </a>
                </router-link>
              </ul>
            </li>

            <router-link tag="li" active-class="active" :to="{ name: 'ordem-producao' }">
              <a href>
                <i class="fa fa-gavel"></i>
                Produção
              </a>
              <ul class="sidebar-nav" style="height: 0"></ul>
            </router-link>

            <router-link tag="li" active-class="active" :to="{ name: 'clientes' }">
              <a href>
                <i class="fa fa-suitcase"></i>
                Cliente
              </a>
              <ul class="sidebar-nav" style="height: 0"></ul>
            </router-link>

            <li
              v-if="isAdmin || isOperacional"
              :class="activeMenu(
                ['produto-tipo', 'produto-acabamento', 'produto-aditivo',
                'produto-composicao', 'produto-cor', 'produto-material',
                'produto-resina' ]
              )"
            >
              <a href>
                <i class="fa fa-dropbox"></i> Produto
                <i class="fa arrow"></i>
              </a>

              <ul
                class="sidebar-nav"
                :style="activeSubmenu(
                  ['produto-tipo', 'produto-acabamento', 'produto-aditivo',
                'produto-composicao', 'produto-cor', 'produto-material',
                'produto-resina' ]
                )"
              >
                <router-link tag="li" active-class="active" :to="{ name: 'produto-tipo' }">
                  <a href>Tipo</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-acabamento' }">
                  <a href>Acabamento</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-aditivo' }">
                  <a href>Aditivo</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-composicao' }">
                  <a href>Composição</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-cor' }">
                  <a href>Cor</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-material' }">
                  <a href>Material</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'produto-resina' }">
                  <a href>Resina</a>
                </router-link>
              </ul>
            </li>
            <router-link
              v-if="isAdmin || isOperacional"
              tag="li"
              active-class="active"
              :to="{ name: 'vendedor' }"
            >
              <a href>
                <i class="fa fa-group"></i>
                Vendedor
              </a>
              <ul class="sidebar-nav" style="height: 0"></ul>
            </router-link>
            <router-link v-if="isAdmin" tag="li" active-class="active" :to="{ name: 'usuario' }">
              <a href>
                <i class="fa fa-group"></i>
                Usuário
              </a>
              <ul class="sidebar-nav" style="height: 0"></ul>
            </router-link>
            <li
              v-if="isAdmin || isOperacional"
              :class="activeMenu(
                [
                  'estado',
                  'cidade',
                  'campanha',
                  'config-atendimento',
                  'config-faturamento',
                  'ramo-atividade'
                ]
              )"
            >
              <a href>
                <i class="fa fa-cogs"></i> Configuração
                <i class="fa arrow"></i>
              </a>

              <ul
                class="sidebar-nav"
                :style="activeSubmenu(
                  [
                    'estado',
                    'cidade',
                    'campanha',
                    'config-atendimento',
                    'config-faturamento',
                    'ramo-atividade'
                  ]
                )"
              >
                <router-link tag="li" active-class="active" :to="{ name: 'config-atendimento' }">
                  <a href>Atendimento</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'campanha' }">
                  <a href>Campanha</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'cidade' }">
                  <a href>Cidade</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'estado' }">
                  <a href>Estado</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'config-faturamento' }">
                  <a href>Faturamento</a>
                </router-link>
                <router-link tag="li" active-class="active" :to="{ name: 'ramo-atividade' }">
                  <a href>Ramo de Atividade</a>
                </router-link>
              </ul>
            </li>

            <li
              :class="activeMenu([
                'relatorio-atendimentos',
                'relatorio-atendimentos-vendedores',
                'relatorio-vendas',
                'relatorio-vendas-vendedores'
              ])"
            >
              <a href>
                <i class="fa fa-print"></i> Relatório
                <i class="fa arrow"></i>
              </a>

              <ul
                class="sidebar-nav"
                :style="activeSubmenu([
                  'relatorio-atendimentos',
                  'relatorio-atendimentos-vendedores',
                  'relatorio-vendas',
                  'relatorio-vendas-vendedores'
                ])"
              >
                <router-link
                  v-if="isAdmin || isOperacional || isComercial"
                  tag="li"
                  active-class="active"
                  :to="{ name: 'relatorio-atendimentos' }"
                >
                  <a href>Atendimentos</a>
                </router-link>
                <router-link
                  v-if="isAdmin || isOperacional || isComercial"
                  tag="li"
                  active-class="active"
                  :to="{ name: 'relatorio-atendimentos-vendedores' }"
                >
                  <a href>Atendimentos Vendedores</a>
                </router-link>
                <router-link
                  tag="li"
                  active-class="active"
                  :to="{ name: 'relatorio-vendas-vendedores' }"
                >
                  <a href>Vendedores</a>
                </router-link>
                <router-link
                  v-if="isAdmin || isOperacional || isComercial"
                  exact
                  tag="li"
                  active-class="active"
                  :to="{ name: 'relatorio-vendas' }"
                >
                  <a href>Vendas</a>
                </router-link>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
    <div class="sidebar-overlay" id="sidebar-overlay"></div>
    <div class="sidebar-mobile-menu-handle" id="sidebar-mobile-menu-handle"></div>
    <div class="mobile-menu-handle"></div>
  </div>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  name: 'shared-menu',
  computed: {
    isAdmin() {
      return AuthService.isAdmin();
    },
    isOperacional() {
      return AuthService.isOperacional();
    },
    isComercial() {
      return AuthService.isComercial();
    },
  },
  methods: {
    activeSubmenu(names) {
      const obj = this.activeMenu(names);
      const height = obj.active ? 'auto' : '0';

      return { height };
    },
    activeMenu(names) {
      const routeActive = names.filter(n => n === this.$route.name);

      if (routeActive.length <= 0) return {};

      return {
        active: true,
        open: true,
      };
    },
  },
};
</script>
