import Api from './Api';

class TipoMaterialService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/tipos-material', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/tipo-material', data);
    }

    return this.$api.put(`/tipo-material/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/tipo-material/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/tipo-material/${id}`);
  }
}

export default new TipoMaterialService();
