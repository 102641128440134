import moment from 'moment';
import Api from './Api';

class RelatorioService {
  constructor() {
    this.$url = '/relatorio';
    this.$api = Api;
  }

  async findAll() {
    const url = '/relatorios';
    return this.$api.get(url);
  }

  async findAllProductsBy(relatorioId) {
    const url = `${this.$url}/${relatorioId}/produtos`;
    return this.$api.get(url);
  }

  async findAllSellersBy(relatorioId) {
    const url = `${this.$url}/${relatorioId}/vendedores`;
    return this.$api.get(url);
  }

  async findAllSalesBy(relatorioId, filters) {
    const url = `${this.$url}/${relatorioId}/vendas`;
    return this.$api.get(url, filters);
  }

  async findAllSalesSellersBy(relatorioId, filters) {
    const url = `${this.$url}/${relatorioId}/vendas/vendedores`;
    return this.$api.get(url, filters);
  }

  async exportSalesBy(relatorioId, filters) {
    const url = `${this.$url}/${relatorioId}/vendas/exportar`;

    const file = await this.$api.file(url, filters);

    const urlDownload = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    const filename = `Relatório-de-Vendas-${data}.xlsx`;

    link.href = urlDownload;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  async exportSellersBy(relatorioId, filters) {
    const url = `${this.$url}/${relatorioId}/vendas/vendedores/exportar`;

    const file = await this.$api.file(url, filters);

    const urlDownload = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    const filename = `Relatório-de-Vendedores-${data}.xlsx`;

    link.href = urlDownload;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  async findClientsWithouContacts(filters) {
    const url = `${this.$url}/atendimentos/clientes-sem-atendimento`;
    return this.$api.get(url, filters);
  }

  async exportClientsWithouContacts(filters) {
    const url = `${this.$url}/atendimentos/clientes-sem-atendimento/exportar`;

    const file = await this.$api.file(url, filters);

    const urlDownload = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');

    const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    const filename = `Relatório-de-Clientes-Sem-Atendimentos-${data}.xlsx`;

    link.href = urlDownload;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
}

export default new RelatorioService();
