var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.atendimentos)?_c('div',{staticClass:"card card-block"},[_c('div',{staticClass:"title-block"},[_c('h3',{staticClass:"title text-uppercase"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n          ("+_vm._s(_vm.atendimentos.count)+")\n        ")])]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"form-group m0 col-md-11"}),_c('div',{staticClass:"form-group m0 col-md-1 pl0"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.limit),expression:"filters.limit"}],staticClass:"c-select form-control underlined",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filters, "limit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.load(1)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Qtd")]),_c('option',{attrs:{"value":"5"}},[_vm._v("5")]),_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"20"}},[_vm._v("20")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])])]),_c('div',{staticClass:"form-group"},[_c('table',{staticClass:"table table-striped"},[_vm._m(0),(_vm.atendimentos && _vm.atendimentos.data.length > 0)?_c('tbody',_vm._l((_vm.atendimentos.data),function(item){return _c('tr',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.createTooltip(item),
                placement: 'left',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip']
              }),expression:"{\n                content: createTooltip(item),\n                placement: 'left',\n                classes: ['info'],\n                targetClasses: ['it-has-a-tooltip']\n              }"}],key:item.id},[_c('th',{attrs:{"scope":"row"}},[_c('router-link',{staticClass:"link-detalhes text-info",attrs:{"to":{ name: 'atendimento-detalhes',
                        params: { id: item.id } }}},[_vm._v(_vm._s(item.protocolo))])],1),_c('td',[_vm._v(_vm._s(item.cliente))]),_c('td',[_vm._v(_vm._s(item.contato))]),_c('td',[_vm._v(_vm._s(item.telefone))]),_c('td',[(_vm.podeEditar)?[_c('router-link',{staticClass:"btn btn-sm btn-info-outline m0",class:{ disabled: !item.clienteId },attrs:{"target":"_blank","to":{ name: 'cliente-atendimento-editar',
                    params: { id: item.clienteId, atendimentoId: item.id } }}},[_vm._v("EDITAR")])]:[_c('router-link',{staticClass:"btn btn-sm btn-info-outline m0",class:{ disabled: !item.clienteId },attrs:{"target":"_blank","to":{ name: 'cliente-atendimento-cadastrar',
                    params: { id: item.clienteId } }}},[_vm._v("NOVO")])]],2)])}),0):_vm._e(),(_vm.atendimentos.data.length <= 0)?_c('tfoot',[_vm._m(1)]):_vm._e()])]),(_vm.atendimentos.pages > 1)?_c('div',{staticClass:"form-group text-right"},[_c('paginate',{attrs:{"page-count":_vm.atendimentos.pages,"click-handler":_vm.nextPrevPageAtendimentos,"prev-text":'Anterior',"next-text":'Próxima',"container-class":'pagination',"page-class":'page-item',"prev-class":'page-item',"next-class":'page-item',"page-link-class":'page-link',"prev-link-class":'page-link',"next-link-class":'page-link'},model:{value:(_vm.atendimentos.page),callback:function ($$v) {_vm.$set(_vm.atendimentos, "page", $$v)},expression:"atendimentos.page"}})],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"thead-inverse"},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Cliente")]),_c('th',[_vm._v("Contato")]),_c('th',[_vm._v("Telefone")]),_c('th')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('p',{staticClass:"m0 p0 text-center"},[_vm._v("Nenhum atendimento encontrado!")])])])}]

export { render, staticRenderFns }