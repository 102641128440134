import moment from 'moment';

import Api from './Api';

class ClienteService {
  constructor() {
    this.$url = '/cliente';
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/clientes', filters);
  }

  async findById(id) {
    const url = `${this.$url}/${id}`;

    return this.$api.get(url);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post(`${this.$url}`, data);
    }

    return this.$api.put(`${this.$url}/${id}`, data);
  }

  async disable(id) {
    return this.$api.delete(`${this.$url}/${id}`);
  }

  async enable(id) {
    return this.$api.put(`${this.$url}/${id}/ativar`);
  }

  async findByDocument(document) {
    const url = `${this.$url}/documento/${document}`;

    return this.$api.get(url);
  }

  async reports(filters) {
    const url = `${this.$url}/relatorios`;

    if (filters.export === 1) {
      const file = await this.$api.file(url, filters);

      const urlDownload = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');

      const data = moment(new Date()).format('DD-MM-YYYY-HH-mm');
      const filename = `Relatório-de-clientes-${data}.xlsx`;

      link.href = urlDownload;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      return null;
    }

    return this.$api.get(url, filters);
  }
}

export default new ClienteService();
