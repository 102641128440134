import Index from './Index.vue';
import Create from './Create.vue';
import Edit from './Edit.vue';
import Detail from './Detail.vue';
import AuthService from '../../../../services/AuthService';

const validateBeforeEnter = (to, from, next) => {
  if (AuthService.isAdmin() || AuthService.isOperacional() || AuthService.isComercial()) {
    return next();
  }

  return next({
    name: 'home',
    params: {
      accessDenied: true,
    },
  });
};

export default [{
  path: 'pedidos',
  name: 'cliente-pedidos',
  beforeEnter: validateBeforeEnter,
  component: Index,
}, {
  path: 'pedido',
  name: 'cliente-pedido-cadastrar',
  beforeEnter: validateBeforeEnter,
  component: Create,
}, {
  path: 'pedido/:pedidoId',
  name: 'cliente-pedido-editar',
  beforeEnter: validateBeforeEnter,
  component: Edit,
}, {
  path: 'pedido/:pedidoId/detalhes',
  name: 'cliente-pedido-detalhes',
  beforeEnter: validateBeforeEnter,
  component: Detail,
}];
