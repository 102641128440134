<style lang="scss"></style>

<template>
  <article v-if="cliente && cliente.id" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Pedidos do Cliente</h3>
    </div>
    <section class="section">
      <div class="row sameheight-container">
        <div class="col-md-12">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Dados</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Código</label>
                  <p class="form-control underlined">{{ cliente.codigo || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">CNPJ/CPF</label>
                  <p class="form-control underlined">{{ cliente.documento }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ cliente.nome }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row sameheight-container">
        <div class="col-md-8">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Endereço</h3>
            </div>
            <div class="read-form">
              <div class="form-group">
                <label class="control-label">CEP</label>
                <p class="form-control underlined">{{ cliente.cep || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Logradouro</label>
                <p class="form-control underlined">{{ cliente.endereco || '-' }}</p>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Número</label>
                  <p class="form-control underlined">{{ cliente.numero || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-8">
                  <label class="control-label">Complemento</label>
                  <p class="form-control underlined">{{ cliente.complemento || '-' }}</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-5">
                  <label class="control-label">Bairro</label>
                  <p class="form-control underlined">{{ cliente.bairro || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-5">
                  <label class="control-label">Cidade</label>
                  <p class="form-control underlined">{{ cliente.cidade.nome || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-2">
                  <label class="control-label">UF</label>
                  <p class="form-control underlined">{{ cliente.cidade.estado.uf || '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Contato</h3>
            </div>
            <div class="read-form">
              <div class="form-group">
                <label class="control-label">E-mail</label>
                <p class="form-control underlined">{{ cliente.email || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Telefone</label>
                <p class="form-control underlined">{{ cliente.telefone || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Celular</label>
                <p class="form-control underlined">{{ cliente.celular || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Comercial</label>
                <p class="form-control underlined">{{ cliente.comercial || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="pedidos">
      <div class="title-block">
        <h3 class="title">Últimos Pedidos</h3>
      </div>
      <section class="section sameheight-container">
        <div class="row">
          <div class="col-md-12">
            <form @submit.prevent="filterPedidos(1)">
              <div class="card card-block">
                <div class="row form-group">
                  <div class="form-group m0 col-md-3">
                    <label class="control-label">Protocolo</label>
                    <input
                      type="text"
                      class="form-control underlined asdas"
                      v-model.trim="filters.prot"
                    />
                  </div>

                  <div class="form-group m0 col-md-3">
                    <label class="control-label">Num. Pedido</label>
                    <input type="text" class="form-control underlined" v-model.trim="filters.num" />
                  </div>

                  <div class="form-group m0 col-md-6">
                    <label class="control-label">Vendedor</label>
                    <input
                      type="text"
                      class="form-control underlined asd"
                      v-model.trim="filters.seller"
                    />
                  </div>
                </div>

                <div class="row form-group">
                  <div class="form-group m0 col-md-6">
                    <label class="control-label">Data</label>
                    <div class="row">
                      <div class="col-md-5">
                        <vc-date-picker
                          v-model="dateStart"
                          locale="pt-BR"
                          :max-date="dateEnd"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                        >
                          <input
                            type="tel"
                            v-mask="'##/##/####'"
                            class="form-control underlined"
                            slot-scope="{ inputProps, inputEvents }"
                            v-bind="inputProps"
                            v-on="inputEvents"
                          />
                        </vc-date-picker>
                      </div>
                      <div class="col-md-2 text-center">
                        <p class="p0 m0" style="margin-top: 7px;">até</p>
                      </div>
                      <div class="col-md-5">
                        <vc-date-picker
                          v-model="dateEnd"
                          locale="pt-BR"
                          :min-date="dateStart"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                        >
                          <input
                            type="tel"
                            v-mask="'##/##/####'"
                            class="form-control underlined"
                            slot-scope="{ inputProps, inputEvents }"
                            v-bind="inputProps"
                            v-on="inputEvents"
                          />
                        </vc-date-picker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group text-right m0">
                  <button
                    type="submit"
                    class="btn btn-primary m0"
                    :disabled="enviando"
                  >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block text-right">
                <router-link
                  :to="{ name: 'cliente-pedido-cadastrar',
                         params: { id: clienteId} }"
                  class="btn btn-primary m0"
                >CADASTRAR PEDIDO</router-link>
              </div>

              <div class="row form-group">
                <div class="form-group m0 col-md-11"></div>
                <div class="form-group m0 col-md-1 pl0">
                  <select
                    class="c-select form-control underlined"
                    v-model="filters.limit"
                    @change="filterAtendimentos(1)"
                  >
                    <option value>Qtd</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <table class="table table-striped">
                  <thead class="thead-inverse">
                    <tr>
                      <th width="20%">#</th>
                      <th width="20%">Num. Pedido</th>
                      <th width="20%">Data Pedido</th>
                      <th width="20%">Vendedor</th>
                      <th width="20%"></th>
                    </tr>
                  </thead>
                  <tbody v-if="pedidos &&
                     pedidos.data.length > 0">
                    <tr
                      v-for="item in pedidos.data"
                      :key="item.id"
                      v-tooltip="{
                        content: `<b>OPs:</b><br/> ${item.itens}<br/><br/>
                                  <b>Data de Entrega:</b><br/> ${formatDate(item.dataEntrega)}
                                  <br/><br/>
                                  <b>Faturamento:</b><br/> ${item.faturamento}`,
                        placement: 'left',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip']
                      }"
                    >
                      <th scope="row">
                        <router-link
                          :to="{ name: 'cliente-pedido-detalhes',
                          params: { id: clienteId, pedidoId: item.id } }"
                          class="link-detalhes text-info"
                        >{{ item.protocolo }}</router-link>
                      </th>
                      <td>{{ item.numPedido }}</td>
                      <td>{{ formatDate(item.dataPedido) }}</td>
                      <td>{{ item.vendedor }}</td>
                      <td>
                        <router-link
                          :to="{ name: 'cliente-pedido-editar',
                          params: { id: clienteId, pedidoId: item.id } }"
                          class="btn btn-sm btn-info-outline m0"
                        >EDITAR</router-link>&nbsp;
                        <button
                          type="button"
                          class="btn btn-sm btn-danger-outline m0"
                          @click="remover(item.id)"
                        >REMOVER</button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="pedidos.data.length <= 0">
                    <tr>
                      <td colspan="7">
                        <p class="m0 p0 text-center">Nenhum pedido encontrado!</p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div v-if="pedidos.pages > 1" class="form-group text-right m0 p0">
                <paginate
                  v-model="pedidos.page"
                  :page-count="pedidos.pages"
                  :click-handler="nextPrevPagePedidos"
                  :prev-text="'Anterior'"
                  :next-text="'Próxima'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                ></paginate>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>

    <modal-remove-pedido :key="removePedidoId" @reload="reload"></modal-remove-pedido>
  </article>
</template>

<script>
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import { showLoading, closeLoading } from '../../../../helpers';

import ClienteService from '../../../../services/ClienteService';
import PedidoService from '../../../../services/PedidoService';

import ModalRemovePedido from '../../../../components/pedido/Remove.vue';

export default {
  name: 'cliente-atendimentos',
  components: {
    ModalRemovePedido,
    Paginate,
  },
  data() {
    return {
      enviando: false,
      removePedidoId: null,
      clienteId: null,
      cliente: null,
      styleFiltersDate: {
        class: 'form-control underlined',
      },
      dateStart: null,
      dateEnd: null,
      filters: {
        page: 1,
        limit: 5,
        prot: '',
        seller: '',
        num: '',
        start: '',
        end: '',
      },
      pedidos: null,
    };
  },
  watch: {
    dateStart(date) {
      if (!date) {
        this.filters.start = '';
        return;
      }

      this.filters.start = moment(date).format('DD/MM/YYYY');
    },
    dateEnd(date) {
      if (!date) {
        this.filters.end = '';
        return;
      }

      this.filters.end = moment(date).format('DD/MM/YYYY');
    },
  },
  created() {
    const clienteId = this.$route.params.id * 1;

    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'pedido' });
      return;
    }

    this.clienteId = clienteId;

    if (Object.keys(this.$route.query).length > 0) {
      // eslint-disable-next-line
      const { page, limit, seller, prot, num, start, end } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.filters.seller = seller;
      this.filters.prot = prot;
      this.filters.num = num;
      this.filters.start = start;
      this.filters.end = end;
    }

    this.load();
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YY');
    },
    remover(pedidoId) {
      this.removePedidoId = pedidoId;
    },
    reload() {
      this.filters.seller = '';
      this.filters.prot = '';
      this.filters.num = '';
      this.filters.start = '';
      this.filters.end = '';
      this.filterPedidos(1);
    },
    async load() {
      try {
        showLoading();

        this.cliente = await ClienteService.findById(this.clienteId);
        this.pedidos = await this.findPedidos();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterPedidos(page) {
      try {
        this.enviando = true;

        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.pedidos = await PedidoService.findByClient(
          this.clienteId,
          this.filters,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async findPedidos() {
      return PedidoService.findByClient(this.clienteId, this.filters);
    },
    async nextPrevPagePedidos(page) {
      return this.filterPedidos(page);
    },
  },
};
</script>
