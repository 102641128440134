<style lang="scss"></style>

<template>
  <article v-if="cliente && cliente.id" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Atendimentos do Cliente
        <template v-if="cliente.encerrado">
        - <font style="color: red">CLIENTE ENCERRADO</font>
        </template>
      </h3>
    </div>
    <section class="section">
      <div class="row sameheight-container">
        <div class="col-md-12">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Dados</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Código</label>
                  <p class="form-control underlined">{{ cliente.codigo || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">CNPJ/CPF</label>
                  <p class="form-control underlined">{{ cliente.documento }}</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ cliente.nome }}</p>
                </div>
              </div>

              <div v-if="cliente.grupo" class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Grupo</label>
                  <p class="form-control underlined">{{ cliente.grupo.nome }}</p>
                </div>
                <div class="form-group m0 col-md-8">
                  <label class="control-label">Clientes</label>
                  <p class="form-control underlined">{{
                    (cliente.grupo.clientes || []).join(', ').trim() || '-'
                  }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row sameheight-container">
        <div class="col-md-8">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Endereço</h3>
            </div>
            <div class="read-form">
              <div class="form-group">
                <label class="control-label">CEP</label>
                <p class="form-control underlined">{{ cliente.cep || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Logradouro</label>
                <p class="form-control underlined">{{ cliente.endereco || '-' }}</p>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Número</label>
                  <p class="form-control underlined">{{ cliente.numero || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-8">
                  <label class="control-label">Complemento</label>
                  <p class="form-control underlined">{{ cliente.complemento || '-' }}</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-5">
                  <label class="control-label">Bairro</label>
                  <p class="form-control underlined">{{ cliente.bairro || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-5">
                  <label class="control-label">Cidade</label>
                  <p class="form-control underlined">{{ cliente.cidade.nome || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-2">
                  <label class="control-label">UF</label>
                  <p class="form-control underlined">{{ cliente.cidade.estado.uf || '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">Contato</h3>
            </div>
            <div class="read-form">
              <div class="form-group">
                <label class="control-label">E-mail</label>
                <p class="form-control underlined">{{ cliente.email || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Telefone</label>
                <p class="form-control underlined">{{ cliente.telefone || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Celular</label>
                <p class="form-control underlined">{{ cliente.celular || '-' }}</p>
              </div>
              <div class="form-group">
                <label class="control-label">Comercial</label>
                <p class="form-control underlined">{{ cliente.comercial || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="ultimoAtendimento" class="row sameheight-container">
        <div class="col-md-12">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title">
                Último Atendimento: {{ ultimoAtendimento.vendedor || '-' }}
                às {{ ultimoAtendimento.data }}
              </h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Contato</label>
                  <p
                    class="form-control underlined asdsa"
                  >{{ ultimoAtendimento.contato || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">E-mail</label>
                  <p
                    class="form-control underlined asdsad"
                  >{{ ultimoAtendimento.email || '-' }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Tipo</label>
                  <p class="form-control underlined">{{ ultimoAtendimento.tipo || '-' }}</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Histórico</label>
                  <p
                    class="form-control underlined asdsa"
                  >{{ ultimoAtendimento.historico || '-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="boxAtendimento">
      <div class="title-block">
        <h3 class="title">Últimos Atendimentos</h3>
      </div>
      <section class="section sameheight-container">
        <div class="row">
          <div class="col-md-12">
            <form @submit.prevent="filterAtendimentos(1)">
              <div class="card card-block">
                <div class="title-block">
                  <h3 class="title">Filtros</h3>
                </div>

                <div class="row form-group">
                  <div class="form-group m0 col-md-2">
                    <label class="control-label">Protocolo</label>
                    <input
                      type="text"
                      class="form-control underlined asdsad"
                      v-model.trim="filters.prot"
                    />
                  </div>
                  <div class="form-group m0 col-md-6">
                    <label class="control-label">Data</label>
                    <div class="row">
                      <div class="col-md-5">
                        <vc-date-picker
                          v-model="dateStart"
                          locale="pt-BR"
                          :max-date="dateEnd"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                        >
                          <input
                            type="tel"
                            v-mask="'##/##/####'"
                            class="form-control underlined"
                            slot-scope="{ inputProps, inputEvents }"
                            v-bind="inputProps"
                            v-on="inputEvents"
                          />
                        </vc-date-picker>
                      </div>
                      <div class="col-md-2 text-center">
                        <p class="p0 m0" style="margin-top: 7px;">até</p>
                      </div>
                      <div class="col-md-5">
                        <vc-date-picker
                          v-model="dateEnd"
                          locale="pt-BR"
                          :min-date="dateStart"
                          :popover="{ placement: 'bottom', visibility: 'click' }"
                        >
                          <input
                            type="tel"
                            v-mask="'##/##/####'"
                            class="form-control underlined"
                            slot-scope="{ inputProps, inputEvents }"
                            v-bind="inputProps"
                            v-on="inputEvents"
                          />
                        </vc-date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Tipo Atendimento</label>
                    <select class="c-select form-control underlined" v-model="filters.categoryId">
                      <option value>Todos</option>
                      <option
                        v-for="tipo in tiposAtendimentos"
                        :key="tipo.id"
                        :value="tipo.id"
                      >{{ tipo.nome }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group text-right m0">
                  <button
                    type="submit"
                    class="btn btn-primary m0"
                    :disabled="enviando"
                  >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block text-right">
                <router-link
                  v-if="boxAtendimento.podeFazerAtendimento"
                  :to="{ name: 'cliente-atendimento-cadastrar',
                         params: { id: clienteId} }"
                  class="btn btn-primary m0"
                >CADASTRAR ATENDIMENTO</router-link>
              </div>

              <div class="row form-group">
                <div class="form-group m0 col-md-11"></div>
                <div class="form-group m0 col-md-1 pl0">
                  <select
                    class="c-select form-control underlined"
                    v-model="filters.limit"
                    @change="filterAtendimentos(1)"
                  >
                    <option value>Qtd</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <table class="table table-striped">
                  <thead class="thead-inverse">
                    <tr>
                      <th width="10%">#</th>
                      <th width="10%">Data</th>
                      <th width="20%">Contato</th>
                      <th width="15%">Telefone</th>
                      <th width="15%">Tipo</th>
                      <th v-if="boxAtendimento.podeFazerAtendimento" width="20%"></th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="boxAtendimento.atendimentos &&
                     boxAtendimento.atendimentos.data.length > 0"
                  >
                    <tr
                      v-for="item in boxAtendimento.atendimentos.data"
                      :key="item.id"
                      v-tooltip="{
                    content: `<b>Retorno:</b><br/> ${formatDate(item.retorno)}
                      <br/><br/>
                      <b>Campanha:</b><br/> ${item.campanha}<br/><br/>
                      <b>Vendedor:</b><br/> ${
                        item.ultimoContato ? item.ultimoContato.vendedor : '-'
                      }<br/><br/>
                      <b>Histórico:</b><br/> ${
                        item.ultimoContato ? item.ultimoContato.descricao : '-'
                      }<br/><br/>
                      <b>Ramo de atividade:</b><br/> ${item.ramo_atividade || '-'}`,
                    placement: 'left',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip']
                  }"
                    >
                      <th scope="row">
                        <router-link
                          :to="{ name: 'cliente-atendimento-detalhes',
                          params: { id: clienteId, atendimentoId: item.id } }"
                          class="link-detalhes text-info"
                        >{{ item.protocolo }}</router-link>
                      </th>
                      <td>{{ formatDate(item.data) }}</td>
                      <td>{{ item.contato }}</td>
                      <td>{{ item.telefone }}</td>
                      <td>{{ item.tipo }}</td>
                      <td
                        v-if="boxAtendimento.podeFazerAtendimento"
                        align="right">
                        <router-link
                          :to="{ name: 'cliente-atendimento-editar',
                          params: { id: clienteId, atendimentoId: item.id } }"
                          class="btn btn-sm btn-info-outline m0"
                          :class="{ disabled: !item.podeEditar }"
                        >EDITAR</router-link>&nbsp;
                        <button
                          type="button"
                          class="btn btn-sm btn-danger-outline m0"
                          :class="{ disabled: !item.podeEditar }"
                          @click="remover(item.id, item.podeEditar)"
                        >REMOVER</button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="boxAtendimento.atendimentos.data.length <= 0">
                    <tr>
                      <td colspan="7">
                        <p class="m0 p0 text-center">Nenhum atendimento encontrado!</p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div v-if="boxAtendimento.atendimentos.pages > 1" class="form-group text-right m0 p0">
                <paginate
                  v-model="boxAtendimento.atendimentos.page"
                  :page-count="boxAtendimento.atendimentos.pages"
                  :click-handler="nextPrevPageAtendimentos"
                  :prev-text="'Anterior'"
                  :next-text="'Próxima'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                ></paginate>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>

    <modal-remove-atendimento
      :key="removeAtendimentoId"
      @reload="reload"
      class="asd asd asd asd asd"
    ></modal-remove-atendimento>
  </article>
</template>

<script>
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import { showLoading, closeLoading } from '../../../../helpers';
import ClienteService from '../../../../services/ClienteService';
import AtendimentoService from '../../../../services/AtendimentoService';

import ModalRemoveAtendimento from '../../../../components/atendimentos/Remove.vue';

export default {
  name: 'cliente-atendimentos',
  components: {
    ModalRemoveAtendimento,
    Paginate,
  },
  data() {
    return {
      removeAtendimentoId: null,
      enviando: false,
      clienteId: null,
      cliente: {},
      boxAtendimento: null,
      ultimoAtendimento: null,
      tiposAtendimentos: [],
      styleFiltersDate: {
        class: 'form-control underlined',
      },
      dateStart: null,
      dateEnd: null,
      filters: {
        limit: '5',
        page: '1',
        prot: '',
        categoryId: '',
        start: '',
        end: '',
      },
    };
  },
  watch: {
    dateStart(date) {
      if (!date) {
        this.filters.start = '';
        return;
      }

      this.filters.start = moment(date).format('DD/MM/YYYY');
    },
    dateEnd(date) {
      if (!date) {
        this.filters.end = '';
        return;
      }

      this.filters.end = moment(date).format('DD/MM/YYYY');
    },
  },
  created() {
    const clienteId = this.$route.params.id * 1;

    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'atendimento' });
      return;
    }

    this.clienteId = clienteId;
    if (Object.keys(this.$route.query).length > 0) {
      // eslint-disable-next-line
      const { limit, page, prot, categoryId, start, end } = this.$route.query;
      this.filters.limit = limit;
      this.filters.page = page;
      this.filters.prot = prot;
      this.filters.categoryId = categoryId;
      this.filters.start = start;
      this.filters.end = end;
    }

    this.load();
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YY');
    },
    remover(atendimentoId, podeEditar) {
      if (!podeEditar) return;
      this.removeAtendimentoId = atendimentoId;
    },
    reload() {
      this.filters.prot = '';
      this.filters.categoryId = '';
      this.filters.start = '';
      this.filters.end = '';
      this.filterAtendimentos(1);
    },
    loadUltimoAtendimento() {
      const { atendimentos: { data: atendimentos } } = this.boxAtendimento;
      if (!atendimentos || atendimentos.length <= 0) return;

      const [atendimento] = atendimentos.filter(item => item.ultimoContato !== null);
      this.ultimoAtendimento = {
        data: atendimento.ultimoContato.data,
        vendedor: atendimento.ultimoContato.vendedor,
        contato: atendimento.contato,
        email: atendimento.email,
        tipo: atendimento.tipo,
        historico: atendimento.ultimoContato.descricao,
      };
    },
    async load() {
      try {
        showLoading();

        this.cliente = await ClienteService.findById(this.clienteId);

        this.tiposAtendimentos = await AtendimentoService.findCategories();

        this.boxAtendimento = await this.findAtendimentos();

        this.loadUltimoAtendimento();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterAtendimentos(page) {
      try {
        this.enviando = true;

        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.boxAtendimento = await this.findAtendimentos();

        this.loadUltimoAtendimento();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async findAtendimentos() {
      return AtendimentoService.findByClient(this.cliente.id, this.filters);
    },
    async nextPrevPageAtendimentos(page) {
      return this.filterAtendimentos(page);
    },
  },
};
</script>
