import Index from './Index.vue';
import TipoRoutes from './tipo/routes';
import TipoAcabamentoRoutes from './acabamento/routes';
import TipoAditivoRoutes from './aditivo/routes';
import TipoComposicaoRoutes from './composicao/routes';
import CorRoutes from './cor/routes';
import TipoMaterialRoutes from './material/routes';
import TipoResinaRoutes from './resina/routes';


import AuthService from '../../../services/AuthService';

const sharedRoutes = [];

const children = sharedRoutes.concat(TipoRoutes, TipoAcabamentoRoutes, TipoAditivoRoutes,
  TipoComposicaoRoutes, CorRoutes, TipoMaterialRoutes, TipoResinaRoutes);

export default {
  path: 'produto/',
  component: Index,
  beforeEnter: (to, from, next) => {
    if (AuthService.isAdmin() || AuthService.isOperacional()) return next();

    return next({
      name: 'home',
      params: {
        accessDenied: true,
      },
    });
  },
  children,
};
