import Api from './Api';

class PedidoService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/pedidos', filters);
  }

  async findByClient(clientId, filters) {
    const url = `/cliente/${clientId}/pedidos`;
    return this.$api.get(url, filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/pedido', data);
    }

    return this.$api.put(`/pedido/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/pedido/${id}`);
  }

  async remove(id) {
    const url = `/pedido/${id}`;
    return this.$api.delete(url);
  }

  async import(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.$api.post('/pedidos/importar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new PedidoService();
