import Api from './Api';

class CidadeService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/cidades', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/cidade', data);
    }

    return this.$api.put(`/cidade/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/cidade/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/cidade/${id}`);
  }
}

export default new CidadeService();
