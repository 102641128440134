<style lang="scss"></style>

<template>
  <article v-if="cliente && dados.pedido.id !== 0" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Editar Pedido - #{{ dados.pedido.protocolo }}</h3>
    </div>
    <section class="section">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Cliente</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div class="form-group m0 col-md-6">
                    <label class="control-label">Nome</label>
                    <p class="form-control underlined">{{ cliente.nome }}</p>
                  </div>
                  <div class="form-group m0 col-md-3">
                    <label class="control-label">CNPJ/CPF</label>
                    <p class="form-control underlined">{{ cliente.documento }}</p>
                  </div>
                  <div class="form-group m0 col-md-3">
                    <label class="control-label">UF</label>
                    <p class="form-control underlined">{{ cliente.uf }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Pedido</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.numPedido.$error }"
                  >
                    <label class="control-label">Número</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.dados.pedido.numPedido.$model"
                    />
                    <template v-if="$v.dados.pedido.numPedido.$dirty">
                      <span
                        v-if="!$v.dados.pedido.numPedido.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.dataPedido.$error }"
                  >
                    <label class="control-label">Data</label>
                    <vc-date-picker
                      v-model.trim="$v.dados.pedido.dataPedido.$model"
                      locale="pt-BR"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                    <template v-if="$v.dados.pedido.dataPedido.$dirty">
                      <span
                        v-if="!$v.dados.pedido.dataPedido.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.vendedorId.$error }"
                  >
                    <label class="control-label">Vendedor</label>
                    <select
                      class="c-select form-control underlined"
                      v-model.trim="$v.dados.pedido.vendedorId.$model"
                    >
                      <option value>Selecione um Vendedor</option>
                      <option
                        v-for="vendedor in vendedores"
                        :key="vendedor.id"
                        :value="vendedor.id"
                      >{{ vendedor.apelido }}</option>
                    </select>
                    <template v-if="$v.dados.pedido.vendedorId.$dirty">
                      <span
                        v-if="!$v.dados.pedido.vendedorId.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.dataEntrega.$error }"
                  >
                    <label class="control-label">Data de Entrega</label>
                    <vc-date-picker
                      v-model.trim="$v.dados.pedido.dataEntrega.$model"
                      locale="pt-BR"
                      :min-date="new Date()"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                    <template v-if="$v.dados.pedido.dataEntrega.$dirty">
                      <span
                        v-if="!$v.dados.pedido.dataEntrega.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.transportadora.$error }"
                  >
                    <label class="control-label">Transportadora</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.dados.pedido.transportadora.$model"
                    />
                    <template v-if="$v.dados.pedido.transportadora.$dirty">
                      <span
                        v-if="!$v.dados.pedido.transportadora.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group col-md-4"
                    :class="{ 'has-error': $v.dados.pedido.faturamentoId.$error }"
                  >
                    <label class="control-label">Faturamento</label>
                    <select
                      class="c-select form-control underlined"
                      v-model="$v.dados.pedido.faturamentoId.$model"
                    >
                      <option value>Selecionar um faturamento...</option>
                      <option
                        v-for="faturamento in faturamentos"
                        :key="faturamento.id"
                        :value="faturamento.id"
                      >{{ faturamento.nome }} ({{ faturamento.percentual }}%)</option>
                    </select>
                    <template v-if="$v.dados.pedido.faturamentoId.$dirty">
                      <span
                        v-if="!$v.dados.pedido.faturamentoId.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">Ordem Produção</h3>
              </div>
              <div class="read-form">
                <div class="row">
                  <div class="form-group m0 col-md-12">
                    <table class="table m0 table-striped">
                      <thead class="thead-inverse">
                        <tr>
                          <th width="20%">Núm. OP</th>
                          <th width="25%">Produto</th>
                          <th width="15%">Qtd</th>
                          <th width="15%">Preço</th>
                          <th width="20%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(op, index) in dados.pedido.itens"
                          :key="index"
                          v-tooltip="{
                            content: montarTooltipOP(op),
                            placement: 'left',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip']
                          }"
                        >
                          <th scope="row">{{ op.numOP }}</th>
                          <td>{{ op.produto }}</td>
                          <td>
                            {{ op.quantidade | currency(maskDefault) }}
                            {{ op.unidadeMedida }}
                          </td>
                          <td>{{ op.preco | currency }}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm btn-info-outline m0"
                              @click="editarOrdemProducao(op, index)"
                            >EDITAR</button>
                            &nbsp;
                            <button
                              type="button"
                              class="btn btn-sm btn-danger-outline m0"
                              @click="removerOrdemProducao(index)"
                            >REMOVER</button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr v-if="dados.pedido.itens.length === 0">
                          <td colspan="7">
                            <p class="m0 p0 text-center">Insira pelo menos 1 ordem de produção</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" align="right" style="padding: 1rem 0;">
                            <button
                              type="button"
                              class="btn m0 btn-sm btn-primary"
                              @click.prevent="addNovaOrdemProducao"
                            >ADICIONAR NOVA ORDEM DE PRODUÇÃO</button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div class="form-group" :class="{ 'has-error': $v.dados.pedido.itens.$error }">
                      <template v-if="$v.dados.pedido.itens.$dirty">
                        <span
                          v-if="!$v.dados.pedido.itens.minLength
                          || !$v.dados.pedido.itens.required"
                          class="has-error lblerror"
                        >Insira pelo menos 1 ordem de produção</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="dados.enviando"
            >{{ dados.enviando ? 'EDITANDO...' : 'EDITAR' }}</button>
            &nbsp;&nbsp;
            <button
              @click.prevent="cancelar"
              type="button"
              class="btn btn-secondary"
              :disabled="dados.enviando"
            >CANCELAR</button>
          </div>
        </div>
      </form>
    </section>

    <modal-ordem-producao :key="ordemProducaoId" @reload="reloadOP"></modal-ordem-producao>
  </article>
</template>

<script>
import moment from 'moment';
import { required, minLength } from 'vuelidate/lib/validators';

import { showLoading, closeLoading } from '../../../../helpers';

import VendedorService from '../../../../services/VendedorService';
import TipoFaturamentoService from '../../../../services/TipoFaturamentoService';
import PedidoService from '../../../../services/PedidoService';

import ModalOrdemProducao from '../../../../components/pedido/ordem-producao/Modal.vue';

export default {
  name: 'cliente-pedido-editar',
  data() {
    return {
      maskDefault: {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
      },
      pedidoId: 0,
      clienteId: null,
      cliente: null,
      faturamentos: [],
      vendedores: [],
      ordemProducaoId: null,
      ordemProducao: null,
      dados: {
        enviando: false,
        pedido: {
          id: 0,
          protocolo: '',
          numPedido: '',
          dataPedido: '',
          vendedorId: '',
          dataEntrega: '',
          transportadora: '',
          faturamentoId: '',
          itens: [],
        },
      },
    };
  },
  validations() {
    const validates = {
      dados: {
        pedido: {
          numPedido: {
            required,
          },
          dataPedido: {
            required,
          },
          vendedorId: {
            required,
          },
          dataEntrega: {
            required,
          },
          transportadora: {
            required,
          },
          faturamentoId: {
            required,
          },
          itens: {
            required,
            minLength: minLength(1),
          },
        },
      },
    };

    return validates;
  },
  components: {
    ModalOrdemProducao,
  },
  created() {
    const main = document.querySelector('.main-wrapper');
    if (main) {
      main.scrollTop = 0;
    }

    const clienteId = this.$route.params.id * 1;

    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'pedido' });
      return;
    }

    const pedidoId = this.$route.params.pedidoId * 1;
    if (!pedidoId || Number.isNaN(pedidoId)) {
      this.$router.push({
        name: 'cliente-atendimentos',
        params: { id: this.clienteId },
      });
      return;
    }

    this.clienteId = clienteId;
    this.pedidoId = pedidoId;

    this.loadOrder();
  },
  methods: {
    addNovaOrdemProducao() {
      this.ordemProducaoId = 0;
    },
    montarTooltipOP(op) {
      let tooltip = '';

      tooltip += `<b>Cores:</b><br/> ${op.cores}`;
      tooltip += `<br/><br/> <b>Masterbatch:</b><br/> ${op.masterbatch || '-'}`;

      if (op.resina) {
        tooltip += `<br/><br/> <b>Resina:</b><br/> ${op.resina}`;
      }

      if (op.acabamento) {
        tooltip += `<br/><br/> <b>Acabamento:</b><br/> ${op.acabamento}`;
      }

      if (op.composicao) {
        tooltip += `<br/><br/> <b>Composição:</b><br/> ${op.composicao}`;
      }

      if (op.material) {
        tooltip += `<br/><br/> <b>Material:</b><br/> ${op.material}`;
      }

      if (op.comprimento || op.largura || op.espessura) {
        tooltip += '<br/><br/> <b>Medida:</b><br/> ';
        if (op.comprimento) {
          tooltip += `${op.comprimento}`;
        }

        if (op.largura) {
          tooltip += ` x ${op.largura}`;
        }

        if (op.espessura) {
          tooltip += ` x ${op.espessura.toFixed(2).replace('.', ',')}`;
        }
        tooltip += ` (${op.tipoMedida})`;
      }

      return tooltip;
    },
    editarOrdemProducao(op, index) {
      const data = op;
      const id = data.id || index + 1;
      data.id = id;

      this.ordemProducao = data;
      this.ordemProducaoId = id;
    },
    removerOrdemProducao(index) {
      const op = this.dados.pedido.itens.filter((item, i) => i !== index * 1);
      this.dados.pedido.itens = op;
    },
    async loadOrder() {
      try {
        showLoading();

        const {
          cliente,
          faturamento,
          vendedor,
          ...pedido
        } = await PedidoService.findById(this.pedidoId);

        this.cliente = cliente;

        this.dados.pedido.id = pedido.id;
        this.dados.pedido.protocolo = pedido.protocolo;
        this.dados.pedido.numPedido = pedido.numPedido;
        this.dados.pedido.dataPedido = moment(pedido.dataPedido, 'DD/MM/YYYY').toDate();
        this.dados.pedido.vendedorId = vendedor.id;
        this.dados.pedido.dataEntrega = moment(pedido.dataEntrega, 'DD/MM/YYYY').toDate();
        this.dados.pedido.transportadora = pedido.transportadora;
        this.dados.pedido.faturamentoId = faturamento.id;
        this.dados.pedido.itens = pedido.itens;

        this.load();
      } catch (error) {
        closeLoading();
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({
          name: 'cliente-pedidos',
          params: { id: this.clienteId },
        });
      }
    },
    async load() {
      try {
        this.vendedores = await VendedorService.findAll();
        this.faturamentos = await TipoFaturamentoService.findAll();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async submit() {
      let formIsValid = true;
      this.$v.dados.pedido.$touch();

      if (this.$v.dados.pedido.$invalid) {
        formIsValid = false;
      }

      if (!formIsValid) {
        this.$toast.info(
          'Por favor, verifique se todos os campos foi preenchido corretamente!',
        );
        return;
      }

      try {
        const {
          pedido: {
            id,
            numPedido,
            dataPedido,
            vendedorId,
            dataEntrega,
            transportadora,
            faturamentoId,
            itens,
          },
        } = this.dados;

        this.dados.enviando = true;
        showLoading();

        const novoPedido = {
          id,
          clienteId: this.clienteId,
          numPedido,
          dataPedido: moment(dataPedido, 'DD/MM/YYYY').toJSON(),
          vendedorId,
          dataEntrega: moment(dataEntrega, 'DD/MM/YYYY').toJSON(),
          transportadora,
          faturamentoId,
          itens,
        };

        await PedidoService.save(novoPedido);

        this.$toast.success('Pedido editado com sucesso!');

        this.$router.push({
          name: 'cliente-pedidos',
          params: { id: this.clienteId },
        });
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.dados.enviando = false;
      }
    },
    cancelar() {
      this.$router.push({
        name: 'cliente-pedidos',
        params: { id: this.clienteId },
      });
    },
    reloadOP(data) {
      if (data.id === 0) {
        this.dados.pedido.itens.push(data);
      } else {
        this.dados.pedido.itens = this.dados.pedido.itens.map((i, index) => {
          let item = i;
          const id = item.id || index + 1;
          if (id === data.id) {
            item = data;
          }

          return item;
        });
      }
    },
  },
};
</script>
