<style lang="scss">
.final-de-semana,
.footer-qtd-restantes {
  &.restante {
    color: red;
  }
  &.ultrapassada {
    color: green;
  }
  background: rgb(183, 222, 232) !important;
}

.border1px {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 0px solid #dee2e6 !important;
}
.valign {
  vertical-align: middle !important;
}
.footer-subtotal {
  background: #ccc;
}
</style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Relatório de Vendas Vendedores</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <form @submit.prevent="filterData">
            <div class="row form-group">
              <div class="form-group m0 col-md-3" :class="{ 'has-error': $v.filters.mes.$error }">
                <label class="control-label">Mês</label>
                <select class="c-select form-control underlined" v-model="filters.mes">
                  <option value>Selecione um Mês</option>
                  <option value="1">Janeiro</option>
                  <option value="2">Fevereiro</option>
                  <option value="3">Março</option>
                  <option value="4">Abril</option>
                  <option value="5">Maio</option>
                  <option value="6">Junho</option>
                  <option value="7">Julho</option>
                  <option value="8">Agosto</option>
                  <option value="9">Setembro</option>
                  <option value="10">Outubro</option>
                  <option value="11">Novembro</option>
                  <option value="12">Dezembro</option>
                </select>
                <template v-if="$v.filters.mes.$dirty">
                  <span v-if="!$v.filters.mes.required" class="has-error">Campo obrigatório</span>
                </template>
              </div>
              <div class="form-group m0 col-md-3" :class="{ 'has-error': $v.filters.ano.$error }">
                <label class="control-label">Ano</label>
                <select class="c-select form-control underlined" v-model="filters.ano">
                  <option value>Seleciona um Ano</option>
                  <option v-for="ano in anos" :key="ano" :value="ano">{{ ano }}</option>
                </select>
                <template v-if="$v.filters.ano.$dirty">
                  <span v-if="!$v.filters.ano.required" class="has-error">Campo obrigatório</span>
                </template>
              </div>
              <div
                class="form-group m0 col-md-3"
                :class="{ 'has-error': $v.filters.relatorioId.$error, 'col-md-6': isVendedor }"
              >
                <label class="control-label">Tipo de Relatório</label>
                <select
                  class="c-select form-control underlined"
                  v-model="filters.relatorioId"
                  @change="loadVendedores"
                >
                  <option value>Selecione um Relatório</option>
                  <option
                    v-for="relatorio in relatorios"
                    :key="relatorio.id"
                    :value="relatorio.id"
                  >{{ relatorio.nome }}</option>
                </select>
                <template v-if="$v.filters.relatorioId.$dirty">
                  <span
                    v-if="!$v.filters.relatorioId.required"
                    class="has-error asdas"
                  >Campo obrigatório</span>
                </template>
              </div>
              <div v-if="!isVendedor" class="form-group m0 col-md-3">
                <label class="control-label">Vendedor</label>
                <select class="c-select form-control underlined" v-model.trim="filters.vendedorId">
                  <option value>Todos</option>
                  <option
                    v-for="item in vendedores"
                    :key="item.usuarioId || -1"
                    :value="item.usuarioId || -1"
                  >{{ item.vendedor }}</option>
                </select>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-4">
                <label class="control-label">Material</label>
                <select
                  class="c-select form-control underlined"
                  v-model="filters.material"
                  multiple
                >
                  <option
                    v-for="material in materiais"
                    :key="material.id"
                    :value="material.id"
                  >{{ material.nome }}</option>
                </select>
              </div>

              <div class="form-group m0 col-md-4">
                <label class="control-label">Composição</label>
                <select
                  class="c-select form-control underlined"
                  v-model="filters.composicao"
                  multiple
                >
                  <option
                    v-for="composicao in composicoes"
                    :key="composicao.id"
                    :value="composicao.id"
                  >{{ composicao.nome }}</option>
                </select>
              </div>

              <div class="form-group m0 col-md-4">
                <label class="control-label">Cor</label>
                <select class="c-select form-control underlined" v-model="filters.cor" multiple>
                  <option v-for="cor in cores" :key="cor.id" :value="cor.id">{{ cor.nome }}</option>
                </select>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-3">
                <label class="control-label">Comprimento</label>
                <money
                  v-model.trim="filters.c"
                  v-bind="maskDefault"
                  class="form-control underlined"
                ></money>
              </div>

              <div class="form-group m0 col-md-3">
                <label class="control-label">Largura</label>
                <money
                  v-model.trim="filters.l"
                  v-bind="maskDefault"
                  class="form-control underlined"
                ></money>
              </div>

              <div class="form-group m0 col-md-2">
                <label class="control-label">Espessura</label>
                <money
                  v-model.trim="filters.e"
                  v-bind="maskEspessura"
                  class="form-control underlined"
                ></money>
              </div>

              <div class="form-group m0 col-md-4">
                <label class="control-label">Faturamento</label>
                <select class="c-select form-control underlined" v-model="filters.faturamento">
                  <option value>Selecione um Faturamento</option>
                  <option
                    v-for="faturamento in faturamentos"
                    :key="faturamento.id"
                    :value="faturamento.id"
                  >{{ faturamento.nome }}</option>
                </select>
              </div>
            </div>

            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <template v-if="result">
      <div class="form-group text-right m0 p0 pt-5">
        <button
          type="button"
          class="btn btn-primary m0 asdf"
          @click="exportRelatorio"
          :disabled="exportando"
        >{{ exportando ? "EXPORTANDO..." : "EXPORTAR" }}</button>
      </div>

      <div v-for="data in result" :key="data.vendedor">
        <div class="title-block">
          <h3 class="title">Relatório de Vendas - {{ data.nome }} - {{ data.vendedor }}</h3>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-block">
              <div class="title-block">
                <h3 class="title">
                  {{ data.titulo }}
                  -
                  {{ data.subtitulo }}
                </h3>
              </div>

              <div class="read-form">
                <div class="row">
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Qtd. Dias Utéis</label>
                    <p class="form-control underlined">{{ data.diasUteis }}</p>
                  </div>
                  <div class="form-group m0 col-md-4">
                    <label class="control-label">Meta por dia</label>
                    <p class="form-control underlined">{{ data.metaPorDia }}</p>
                  </div>
                </div>
              </div>

              <div class="form-group" style="overflow: auto;">
                <table class="table table-striped">
                  <thead class="thead-inverse">
                    <tr>
                      <th rowspan="5" class="valign" width="10%">Dia</th>
                      <th class="p-0 m-0 border-0">
                        <rel-header :produtos="data.produtos"></rel-header>
                      </th>
                      <th rowspan="5" class="valign" width="15%">Qtd</th>
                      <th rowspan="5" class="valign" width="15%">Qtd Total</th>
                      <th rowspan="5" class="valign" width="15%">Meta</th>
                      <th rowspan="5" class="valign" width="15%">%</th>
                    </tr>
                  </thead>
                  <tbody v-if="data && data.dias.length > 0">
                    <tr
                      v-for="item in data.dias"
                      :key="item.id"
                      :class="{ 'final-de-semana': item.ehFDS }"
                    >
                      <th scope="row">{{ item.dia }}</th>
                      <td>
                        <template v-if="item.ehFDS"></template>
                        <template v-else>
                          <rel-content :produtos="data.produtos" :dia="item.dia"></rel-content>
                        </template>
                      </td>
                      <td>
                        <template v-if="item.ehFDS"></template>
                        <template v-else>{{ item.qtd || 0 }}</template>
                      </td>
                      <td>
                        <template v-if="item.ehFDS"></template>
                        <template v-else>{{ item.qtdTotal || 0 }}</template>
                      </td>
                      <td>
                        <template v-if="item.ehFDS"></template>
                        <template v-else>{{ item.meta || 0 }}</template>
                      </td>
                      <td>
                        <template v-if="item.ehFDS"></template>
                        <template v-else>{{ item.percentual || 0 }}%</template>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="footer-subtotal">
                      <th>TT</th>
                      <th>
                        <rel-footer :produtos="data.produtos"></rel-footer>
                      </th>
                      <th>{{ data.qtd }}</th>
                      <th>{{ data.qtdTotal }}</th>
                      <th>{{ data.meta }}</th>
                      <th>{{ data.percentual || 0 }}%</th>
                    </tr>
                    <tr
                      class="footer-qtd-restantes"
                      :class="{ 'restante': !metaFoiBatida(data),
                      'ultrapassada': metaFoiUltrapassada(data) }"
                    >
                      <th colspan="5" class="text-center">
                        {{ metaFoiBatida(data) ?
                        'Quantidade ultrapassada' : 'Quantidade à vender'
                        }}
                      </th>
                      <th colspan="2">{{ data.qtdRestante }}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </article>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../../helpers';

import RelHeader from '../../../../components/relatorio/Header.vue';
import RelContent from '../../../../components/relatorio/Content.vue';
import RelFooter from '../../../../components/relatorio/Footer.vue';

import RelatorioService from '../../../../services/RelatorioService';
import TipoFaturamentoService from '../../../../services/TipoFaturamentoService';
import TipoMaterialService from '../../../../services/TipoMaterialService';
import TipoComposicaoService from '../../../../services/TipoComposicaoService';
import CorService from '../../../../services/CorService';

import AuthService from '../../../../services/AuthService';

export default {
  name: 'relatorio-vendas-vendedores',
  components: {
    RelHeader,
    RelContent,
    RelFooter,
  },
  data() {
    return {
      exportando: false,
      maskDefault: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      maskEspessura: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      enviando: false,
      relatorios: [],
      vendedores: [],
      materiais: [],
      composicoes: [],
      cores: [],
      faturamentos: [],
      result: null,
      filters: {
        mes: '',
        ano: '',
        relatorioId: '',
        vendedorId: '',
        material: [],
        composicao: [],
        cor: [],
        c: '',
        l: '',
        e: '',
        faturamento: '',
      },
    };
  },
  validations() {
    const validates = {
      mes: {
        required,
      },
      ano: {
        required,
      },
      relatorioId: {
        required,
      },
    };

    return { filters: validates };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      try {
        showLoading();
        this.relatorios = await RelatorioService.findAll();
        this.faturamentos = await TipoFaturamentoService.findAll();
        this.materiais = await TipoMaterialService.findAll();
        this.composicoes = await TipoComposicaoService.findAll();
        this.cores = await CorService.findAll();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async loadVendedores() {
      try {
        this.filters.vendedorId = '';
        this.vendedores = [];

        showLoading();

        this.vendedores = await RelatorioService.findAllSellersBy(
          this.filters.relatorioId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterData() {
      this.$v.filters.$touch();

      if (this.$v.filters.$invalid) return;

      try {
        this.enviando = true;

        const mes = this.filters.mes.toString().padStart(2, '0');
        const ano = this.filters.ano.toString();

        const {
          relatorioId,
          vendedorId,
          material,
          composicao,
          cor,
          c,
          l,
          e,
          faturamento,
        } = this.filters;

        const filters = {
          mesRef: `${mes}/${ano}`,
          vendedor: vendedorId,
          material: (material || []).join(','),
          composicao: (composicao || []).join(','),
          cor: (cor || []).join(','),
          c,
          l,
          e,
          faturamento,
        };

        showLoading();

        this.result = await RelatorioService.findAllSalesSellersBy(
          relatorioId,
          filters,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
      }
    },
    headersProduto() {
      return this.result.produtos;
    },
    headersMateriais(produto) {
      return produto.materiais;
    },
    headersComposicao(material) {
      return material.composicoes;
    },
    headersCores(composicao) {
      return composicao.cores;
    },
    generateKey(nome) {
      const data = new Date();

      const miliseconds = data.getMilliseconds();
      const seconds = data.getSeconds();
      const minutes = data.getMinutes();
      const hours = data.getMinutes();
      return `${nome}-${miliseconds}-${seconds}-${minutes}-${hours}`;
    },
    metaFoiBatida(data) {
      return data.qtdRestante >= 0;
    },
    metaFoiUltrapassada(data) {
      return data.qtdRestante > 0;
    },
    async exportRelatorio() {
      try {
        this.exportando = true;

        const mes = this.filters.mes.toString().padStart(2, '0');
        const ano = this.filters.ano.toString();

        const {
          relatorioId,
          vendedorId,
          material,
          composicao,
          cor,
          c,
          l,
          e,
          faturamento,
        } = this.filters;

        const filters = {
          mesRef: `${mes}/${ano}`,
          vendedor: vendedorId,
          material: (material || []).join(','),
          composicao: (composicao || []).join(','),
          cor: (cor || []).join(','),
          c,
          l,
          e,
          faturamento,
        };

        showLoading();

        await RelatorioService.exportSellersBy(relatorioId, filters);

        setTimeout(() => {
          closeLoading();

          this.exportando = false;
        }, 200);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      }
    },
  },
  computed: {
    meses() {
      const m = [];
      // eslint-disable-next-line
      for (let i = 1; i <= 12; i++) {
        m.push(i);
      }

      return m;
    },
    anos() {
      const a = [];
      // eslint-disable-next-line
      for (let i = new Date().getFullYear(); i >= 2005; i--) {
        a.push(i);
      }

      return a;
    },
    isVendedor() {
      return AuthService.isVendedor();
    },
  },
};
</script>
