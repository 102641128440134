import Index from './Index.vue';
import Detail from './Detail.vue';

export default [{
  path: 'ordem-producao',
  name: 'ordem-producao',
  component: Index,
}, {
  path: 'ordem-producao/:id',
  name: 'ordem-producao-detalhes',
  component: Detail,
}];
