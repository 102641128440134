<style lang="scss"></style>

<template>
  <div id="modal-desativar-cliente" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">ATENÇÃO!</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="title-block" v-if="cliente">
                  <h3 class="title adasd">
                    Tem certeza que deseja desativar o cliente:
                    <span
                      class="text-info"
                    >{{ cliente.nome }}</span> ?
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="remover"
            :disabled="enviando"
          >{{ enviando ? 'DESATIVANDO...' : 'SIM' }}</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">NÃO</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, closeLoading } from '../../helpers';
import ClienteService from '../../services/ClienteService';

export default {
  name: 'modal-desativar-cliente',
  data() {
    return {
      enviando: false,
      cliente: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-desativar-cliente').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.desativarClienteId = null;
    });

    if (this.$parent.desativarClienteId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.cliente = null;
    },
    async load() {
      try {
        showLoading();

        if (this.$parent.desativarClienteId === 0) return;

        this.cliente = await ClienteService.findById(
          this.$parent.desativarClienteId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-desativar-cliente').modal('show');
      }
    },
    async remover() {
      try {
        this.enviando = true;

        showLoading();

        await ClienteService.disable(this.cliente.id);

        this.$toast.success('Cliente desativado com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-desativar-cliente').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
