<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Gerenciar Ordens de Produção (OPs)</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="filterData(1)">
          <div class="card card-block">
            <div class="row form-group">
              <div class="form-group m0 col-md-9">
                <label class="control-label">Cliente</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.client" />
              </div>

              <div
                class="form-group m0 col-md-3"
                :class="{ 'has-error': $v.filters.document.$error }"
              >
                <label class="control-label">CPF/CNPJ</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.filters.document.$model"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
                <template v-if="$v.filters.document.$dirty">
                  <span
                    v-if="!$v.filters.document.isCPFCNPJValid"
                    class="has-error aasd"
                  >CPF/CNPJ inválido</span>
                </template>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-3">
                <label class="control-label">Num. Pedido</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.num" />
              </div>

              <div class="form-group m0 col-md-3">
                <label class="control-label">OP Pedido</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.numOP" />
              </div>

              <div class="form-group m0 col-md-6">
                <label class="control-label">Vendedor</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.seller" />
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-3">
                <label class="control-label">Protocolo</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.prot" />
              </div>

              <div class="form-group m0 col-md-6">
                <label class="control-label">Data</label>
                <div class="row">
                  <div class="col-md-5">
                    <vc-date-picker
                      v-model="dateStart"
                      locale="pt-BR"
                      :max-date="dateEnd"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                  </div>
                  <div class="col-md-2 text-center">
                    <p class="p0 m0" style="margin-top: 7px;">até</p>
                  </div>
                  <div class="col-md-5">
                    <vc-date-picker
                      v-model="dateEnd"
                      locale="pt-BR"
                      :min-date="dateStart"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-if="ordensProducao" class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block">
            <h3 class="title">
              {{ ordensProducao.count }}
              {{ ordensProducao.count > 1 ?
              "ORDENS DE PRODUÇÃO ENCONTRADO" :
              "ORDEM DE PRODUÇÃO ENCONTRADO"
              }}
            </h3>
          </div>

          <div class="row form-group">
            <div class="form-group m0 col-md-11"></div>
            <div class="form-group m0 col-md-1 pl0">
              <select
                class="c-select form-control underlined"
                v-model="filters.limit"
                @change="filterData(1)"
              >
                <option value>Qtd</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th width="15%">#</th>
                  <th width="18%">Produto</th>
                  <th width="15%">Qtd</th>
                  <th width="15%">Preço</th>
                  <th v-if="allowOptions" width="18%">Conferido?</th>
                  <th v-if="allowOptions"></th>
                </tr>
              </thead>
              <tbody v-if="ordensProducao &&
                     ordensProducao.data.length > 0">
                <tr
                  v-for="(item, index) in ordensProducao.data"
                  :key="item.id"
                  v-tooltip="{
                            content: montarTooltip(item),
                            placement: 'left',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip']
                          }"
                >
                  <th scope="row">
                    <router-link
                      target="_blank"
                      :to="{ name: 'ordem-producao-detalhes',
                          params: { id: item.id } }"
                      class="link-detalhes text-info"
                    >{{ item.numOP }}</router-link>
                  </th>
                  <td>{{ item.produto }}</td>
                  <td>
                    {{ item.quantidade | currency(maskDefault) }}
                    {{ item.unidadeMedida }}
                  </td>
                  <td>{{ item.preco | currency }}</td>
                  <td v-if="allowOptions">
                    <div>
                      <label class="m0">
                        <input
                          class="radio"
                          :name="`chkOpConferido${index}`"
                          type="radio"
                          :value="true"
                          v-model="item.conferido"
                          @change="opFoiConferido(item, true)"
                        />
                        <span>Sim</span>
                      </label>
                      <label class="m0">
                        <input
                          class="radio"
                          :name="`chkOpConferido${index}`"
                          type="radio"
                          :value="false"
                          v-model="item.conferido"
                          @change="opFoiConferido(item, false)"
                        />
                        <span>Não</span>
                      </label>
                    </div>
                  </td>
                  <td v-if="allowOptions">
                    <button
                      type="button"
                      class="btn btn-sm btn-info-outline m0"
                      @click.prevent="baixaOP(item)"
                    >BAIXAR</button>
                    &nbsp;
                    <button
                      type="button"
                      class="btn btn-sm btn-info-outline m0"
                      @click.prevent="coletaOP(item)"
                    >COLETA</button>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="ordensProducao.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum ordem de produção encontrada!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="ordensProducao.pages > 1" class="form-group text-right">
            <paginate
              v-model="ordensProducao.page"
              :page-count="ordensProducao.pages"
              :click-handler="nextPrevPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>

          <div v-if="ordensProducao.count > 0" class="form-group text-right m0 p0">
            <button
              type="button"
              class="btn btn-primary m0 asdf"
              @click="exportOrdensProducao"
              :disabled="exportando"
            >{{ exportando ? "EXPORTANDO..." : "EXPORTAR" }}</button>
          </div>
        </div>
      </div>
    </div>

    <ordem-producao-baixa :key="baixaOrdemProducaoId"></ordem-producao-baixa>
    <ordem-producao-coleta :key="coletaOrdemProducaoId"></ordem-producao-coleta>
  </article>
</template>

<script>
import Paginate from 'vuejs-paginate';
import moment from 'moment';

import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';

import OrdemProducaoBaixa from '../../../components/ordem-producao/Baixa.vue';
import OrdemProducaoColeta from '../../../components/ordem-producao/Coleta.vue';

import OrdemProducaoService from '../../../services/OrdemProducaoService';
import AuthService from '../../../services/AuthService';

export default {
  name: 'ordem-producao',
  components: {
    Paginate,
    OrdemProducaoBaixa,
    OrdemProducaoColeta,
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const {
        page,
        limit,
        document,
        client,
        seller,
        num,
        numOP,
        start,
        end,
      } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.filters.document = document;
      this.filters.client = client;
      this.filters.num = num;
      this.filters.seller = seller;
      this.filters.numOP = numOP;
      this.filters.start = start;
      this.filters.end = end;
    }

    this.load();
  },
  data() {
    return {
      maskDefault: {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
      },
      enviando: false,
      exportando: false,
      dateStart: null,
      dateEnd: null,
      baixaOrdemProducaoId: null,
      coletaOrdemProducaoId: null,
      ordensProducao: null,
      filters: {
        page: 1,
        limit: 5,
        document: '',
        client: '',
        seller: '',
        num: '',
        numOP: '',
        start: '',
        end: '',
      },
    };
  },
  validations: {
    filters: {
      document: {
        isCPFCNPJValid,
      },
    },
  },
  watch: {
    dateStart(date) {
      if (!date) {
        this.filters.start = '';
        return;
      }

      this.filters.start = moment(date).format('DD/MM/YYYY');
    },
    dateEnd(date) {
      if (!date) {
        this.filters.end = '';
        return;
      }

      this.filters.end = moment(date).format('DD/MM/YYYY');
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YY');
    },
    montarTooltip(op) {
      let tooltip = '';

      tooltip += `<b>Pedido:</b><br/> ${op.pedido.numPedido}`;
      tooltip += `<br/><br/> <b>Data:</b><br/> ${this.formatDate(
        op.pedido.dataPedido,
      )}`;
      tooltip += `<br/><br/> <b>Cliente:</b><br/> ${op.cliente}`;

      tooltip += `<br/><br/> <b>Cores:</b><br/> ${op.cores}`;
      tooltip += `<br/><br/> <b>Masterbatch:</b><br/> ${op.masterbatch || '-'}`;

      if (op.resina) {
        tooltip += `<br/><br/> <b>Resina:</b><br/> ${op.resina}`;
      }

      if (op.acabamento) {
        tooltip += `<br/><br/> <b>Acabamento:</b><br/> ${op.acabamento}`;
      }

      if (op.composicao) {
        tooltip += `<br/><br/> <b>Composição:</b><br/> ${op.composicao}`;
      }

      if (op.material) {
        tooltip += `<br/><br/> <b>Material:</b><br/> ${op.material}`;
      }

      if (op.medida && op.medida !== '-') {
        tooltip += '<br/><br/> <b>Medida:</b><br/> ';
        tooltip += `${op.medida} (${op.tipoMedida})`;
      }

      return tooltip;
    },
    async opFoiConferido(op, value) {
      try {
        showLoading();

        await OrdemProducaoService.verified(op.id, value);

        this.$toast.success('Ordem de produção salva com sucesso!');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async load() {
      try {
        showLoading();

        this.ordensProducao = await OrdemProducaoService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterData(page) {
      this.$v.filters.$touch();

      if (this.$v.filters.$invalid) return;

      try {
        this.enviando = true;

        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.ordensProducao = await OrdemProducaoService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async exportOrdensProducao() {
      this.exportando = true;

      try {
        showLoading();

        await OrdemProducaoService.export(this.filters);

        setTimeout(() => {
          closeLoading();

          this.exportando = false;
        }, 200);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      }
    },
    nextPrevPage(page) {
      this.filterData(page);
    },
    baixaOP(op) {
      this.baixaOrdemProducaoId = op.id;
    },
    coletaOP(op) {
      this.coletaOrdemProducaoId = op.id;
    },
  },
  computed: {
    isVendedor() {
      return AuthService.isVendedor();
    },
    allowOptions() {
      const isAdmin = AuthService.isAdmin();
      const isOpe = AuthService.isOperacional();
      const isCom = AuthService.isComercial();
      return isAdmin || isOpe || isCom;
    },
  },
};
</script>
