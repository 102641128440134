<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Gerenciar Vendedores</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <form @submit.prevent="filterData(1)">
            <div class="row form-group">
              <div class="form-group m0 col-md-6">
                <label class="control-label">Nome</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.nome" />
              </div>

              <div class="form-group m0 col-md-4" :class="{ 'has-error': $v.filters.email.$error }">
                <label class="control-label">E-mail</label>
                <input
                  type="email"
                  class="form-control underlined"
                  v-model.trim="$v.filters.email.$model"
                />
                <template v-if="$v.filters.email.$dirty">
                  <span v-if="!$v.filters.email.Email" class="has-error">E-mail inválido</span>
                </template>
              </div>

              <div
                class="form-group m0 col-md-2"
                :class="{ 'has-error': $v.filters.document.$error }"
              >
                <label class="control-label">CPF</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.filters.document.$model"
                  :mask="['###.###.###-##']"
                />
                <template v-if="$v.filters.document.$dirty">
                  <span
                    v-if="!$v.filters.document.isCPFCNPJValid"
                    class="has-error aasd"
                  >CPF inválido</span>
                </template>
              </div>
            </div>
            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? 'BUSCANDO...' : 'BUSCAR' }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="result" class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block text-right">
            <button
              type="button"
              class="btn btn-primary m0 asdasdsasdasd"
              @click="addEdit(0)"
              v-if="allowOptions"
            >ADICIONAR NOVO VENDEDOR</button>
          </div>
          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th width="25%">Apelido</th>
                  <th width="20%">E-mail</th>
                  <th width="20%">Telefone</th>
                  <th width="15%" v-if="allowOptions"></th>
                  <th width="15%">
                    <select
                      class="c-select form-control underlined"
                      v-model="filters.limit"
                      @change="filterData(1)"
                    >
                      <option value>Qtd</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody v-if="result && result.data.length > 0">
                <tr
                  v-for="item in result.data"
                  :key="item.id"
                  v-tooltip="{
                    content: `<b>Nome:</b><br/> ${item.nome}
                              <br/><br/>
                              <b>Cargo:</b><br/> ${item.cargo}
                              <br/><br/>
                              <b>CPF:</b><br/> ${item.cpf}`,
                    placement: 'left',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip']
                  }"
                >
                  <th scope="row">
                    <a
                      @click.prevent="view(item.id)"
                      href="#"
                      class="link-detalhes text-info"
                    >{{ item.apelido }}</a>
                  </th>
                  <td>{{ item.email }}</td>
                  <td>{{ item.telefone }}</td>
                  <td :colspan="allowOptions ? '2' : '1'" align="right">
                    <template v-if="allowOptions">
                      <button
                        type="button"
                        class="btn btn-sm btn-info-outline m0"
                        @click="addEdit(item.id)"
                      >EDITAR</button>
                      &nbsp;
                      <button
                        type="button"
                        class="btn btn-sm btn-danger-outline m0"
                        @click="remove(item.id)"
                      >REMOVER</button>
                    </template>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="result.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum resultado encontrado!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="result.pages > 1" class="form-group text-right">
            <paginate
              v-model="result.page"
              :page-count="result.pages"
              :click-handler="nextPrevPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>

    <detalhe-vendedor :key="detalheVendedorId"></detalhe-vendedor>
    <modal-vendedor :key="vendedorId" @reload="reload"></modal-vendedor>
    <modal-remove-vendedor :key="removeVendedorId" @reload="reload"></modal-remove-vendedor>
  </article>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { email as Email } from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';
import VendedorService from '../../../services/VendedorService';

import DetalheVendedor from '../../../components/vendedor/Detalhe.vue';
import ModalVendedor from '../../../components/vendedor/Modal.vue';
import ModalRemoveVendedor from '../../../components/vendedor/Remove.vue';

import AuthService from '../../../services/AuthService';

export default {
  name: 'vendedor',
  components: {
    Paginate,
    DetalheVendedor,
    ModalVendedor,
    ModalRemoveVendedor,
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const { page, limit, nome } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.filters.nome = nome;
    }

    this.load();
  },
  data() {
    return {
      enviando: false,
      result: null,
      detalheVendedorId: null,
      vendedorId: null,
      removeVendedorId: null,
      filters: {
        page: 1,
        limit: 5,
        nome: '',
        email: '',
        document: '',
      },
    };
  },
  validations: {
    filters: {
      email: {
        Email: value => Email(value.toLowerCase()),
      },
      document: {
        isCPFCNPJValid,
      },
    },
  },
  methods: {
    async load() {
      try {
        showLoading();

        this.result = await VendedorService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterData(page) {
      this.$v.filters.$touch();

      if (this.$v.filters.$invalid) return;

      try {
        this.enviando = true;

        this.filters.page = page;

        this.$router.push({ query: this.filters });

        showLoading();

        this.result = await VendedorService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    nextPrevPage(page) {
      this.filterData(page);
    },
    addEdit(item) {
      this.vendedorId = item;
    },
    remove(item) {
      this.removeVendedorId = item;
    },
    view(item) {
      this.detalheVendedorId = item;
    },
    reload() {
      this.filters.nome = '';
      this.filters.email = '';
      this.filters.document = '';
      this.filterData(1);
    },
  },
  computed: {
    allowOptions() {
      const isAdmin = AuthService.isAdmin();
      return isAdmin;
    },
  },
};
</script>
