import Index from './Index.vue';
import Create from './Create.vue';
import Edit from './Edit.vue';
import Detail from './Detail.vue';

export default [{
  path: 'atendimentos',
  name: 'cliente-atendimentos',
  component: Index,
}, {
  path: 'atendimento',
  name: 'cliente-atendimento-cadastrar',
  component: Create,
}, {
  path: 'atendimento/:atendimentoId',
  name: 'cliente-atendimento-editar',
  component: Edit,
}, {
  path: 'atendimento/:atendimentoId/detalhes',
  name: 'cliente-atendimento-detalhes',
  component: Detail,
}];
