import AtendimentosRoutes from './atendimentos/routes';
import PedidosRoutes from './pedidos/routes';

import Shared from './Shared.vue';
import Index from './Index.vue';

const sharedRoutes = [];

const children = sharedRoutes.concat(AtendimentosRoutes, PedidosRoutes);

export default [{
  path: 'cliente/:id',
  name: 'cliente-pedidos-atendimentos',
  component: Shared,
  children,
}, {
  path: 'clientes',
  name: 'clientes',
  component: Index,
}];
