<style lang="scss"></style>

<template>
  <article v-if="pedido" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Detalhes Pedido - #{{ pedido.protocolo }}</h3>
    </div>
    <section class="section">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Cliente</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-6">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ pedido.cliente.nome }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">CNPJ/CPF</label>
                  <p class="form-control underlined">{{ pedido.cliente.documento }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">UF</label>
                  <p class="form-control underlined">{{ pedido.cliente.uf }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Pedido</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Número</label>
                  <p class="form-control underlined">{{ pedido.numPedido }}</p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Data</label>
                  <p class="form-control underlined">{{ formatDate(pedido.dataPedido) }}</p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Vendedor</label>
                  <p class="form-control underlined">{{ pedido.vendedor.nome }}</p>
                </div>
              </div>

              <div class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Data de Entrega</label>
                  <p class="form-control underlined">{{ formatDate(pedido.dataEntrega) }}</p>
                </div>
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Transportadora</label>
                  <p class="form-control underlined">{{ pedido.transportadora }}</p>
                </div>

                <div class="form-group m0 col-md-4">
                  <label class="control-label">Nota Fiscal</label>
                  <p class="form-control underlined">{{ pedido.faturamento.nome }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Ordem Produção</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <table class="table m0 table-striped">
                    <thead class="thead-inverse">
                      <tr>
                        <th width="20%">Núm. OP</th>
                        <th width="25%">Produto</th>
                        <th width="15%">Qtd</th>
                        <th width="15%">Preço</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(op, index) in pedido.itens"
                        :key="index"
                        v-tooltip="{
                            content: montarTooltipOP(op),
                            placement: 'left',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip']
                          }"
                      >
                        <th scope="row">{{ op.numOP }}</th>
                        <td>{{ op.produto }}</td>
                        <td>
                          {{ op.quantidade | currency(maskDefault) }}
                          {{ op.unidadeMedida }}
                        </td>
                        <td>{{ op.preco | currency }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr v-if="pedido.itens.length === 0">
                        <td colspan="5">
                          <p class="m0 p0 text-center">Nenhuma ordem de produção encontrada!</p>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button @click.prevent="voltar" type="button" class="btn btn-secondary">VOLTAR</button>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import moment from 'moment';
import { showLoading, closeLoading } from '../../../../helpers';

import PedidoService from '../../../../services/PedidoService';

export default {
  name: 'cliente-pedido-detalhes',
  data() {
    return {
      maskDefault: {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
      },
      clienteId: null,
      pedidoId: null,
      pedido: null,
    };
  },
  created() {
    const main = document.querySelector('.main-wrapper');
    if (main) {
      main.scrollTop = 0;
    }

    const clienteId = this.$route.params.id * 1;
    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'pedido' });
      return;
    }

    const pedidoId = this.$route.params.pedidoId * 1;
    if (!pedidoId || Number.isNaN(pedidoId)) {
      this.$router.push({
        name: 'cliente-pedidos',
        params: { id: this.clienteId },
      });
      return;
    }

    this.clienteId = clienteId;
    this.pedidoId = pedidoId;

    this.loadOrder();
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    },
    montarTooltipOP(op) {
      let tooltip = '';

      tooltip += `<b>Cores:</b><br/> ${op.cores}`;
      tooltip += `<br/><br/> <b>Masterbatch:</b><br/> ${op.masterbatch || '-'}`;

      if (op.resina) {
        tooltip += `<br/><br/> <b>Resina:</b><br/> ${op.resina}`;
      }

      if (op.acabamento) {
        tooltip += `<br/><br/> <b>Acabamento:</b><br/> ${op.acabamento}`;
      }

      if (op.composicao) {
        tooltip += `<br/><br/> <b>Composição:</b><br/> ${op.composicao}`;
      }

      if (op.material) {
        tooltip += `<br/><br/> <b>Material:</b><br/> ${op.material}`;
      }

      if (op.comprimento || op.largura || op.espessura) {
        tooltip += '<br/><br/> <b>Medida:</b><br/> ';
        if (op.comprimento) {
          tooltip += `${op.comprimento}`;
        }

        if (op.largura) {
          tooltip += ` x ${op.largura}`;
        }

        if (op.espessura) {
          tooltip += ` x ${op.espessura.toFixed(2).replace('.', ',')}`;
        }
        tooltip += ` (${op.tipoMedida})`;
      }

      return tooltip;
    },
    async loadOrder() {
      try {
        showLoading();

        this.pedido = await PedidoService.findById(this.pedidoId);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({
          name: 'cliente-pedidos',
          params: { id: this.clienteId },
        });
      } finally {
        closeLoading();
      }
    },
    voltar() {
      this.$router.push({
        name: 'cliente-pedidos',
        params: { id: this.clienteId },
      });
    },
  },
};
</script>
