<style lang="scss"></style>

<template>
  <div id="modal-usuario-detalhe" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content" v-if="usuario">
        <div class="modal-header">
          <h4 class="modal-title">DETALHES DO USUÁRIO #{{ usuario.nome }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-block shadow0 m0 pb0">
                <div class="read-form">
                  <div class="row">
                    <div class="form-group m0 col-md-6">
                      <label class="control-label">Nome</label>
                      <p class="form-control underlined">{{ usuario.nome }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Apelido</label>
                      <p class="form-control underlined">{{ usuario.apelido }}</p>
                    </div>

                    <div class="form-group m0 col-md-2">
                      <label class="control-label">CPF</label>
                      <p class="form-control underlined">{{ usuario.cpf }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-6">
                      <label class="control-label">E-mail</label>
                      <p class="form-control underlined">{{ usuario.email }}</p>
                    </div>

                    <div class="form-group m0 col-md-4">
                      <label class="control-label">Cargo</label>
                      <p class="form-control underlined">{{ usuario.cargo }}</p>
                    </div>

                    <div class="form-group m0 col-md-2">
                      <label class="control-label">Telefone</label>
                      <p class="form-control underlined">{{ usuario.telefone }}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group m0 col-md-8">
                      <label class="control-label">Acessos</label>

                      <div>
                        <label v-for="perfil in perfis" :key="perfil.id">
                          <input
                            class="checkbox"
                            :checked="findPerfil(perfil)"
                            type="checkbox"
                            disabled
                          />
                          <span>{{ perfil.nome }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, closeLoading } from '../../helpers';
import UsuarioService from '../../services/UsuarioService';

export default {
  name: 'usuario-detalhe',
  data() {
    return {
      perfis: [],
      usuario: null,
    };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-usuario-detalhe').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.detalheUsuarioId = null;
    });

    if (this.$parent.detalheUsuarioId === null) return;

    this.load();
  },
  methods: {
    clear() {
      this.perfis = [];
      this.usuario = null;
    },
    findPerfil(perfil) {
      return this.usuario.acessos.filter(a => a.id === perfil.id).length > 0;
    },
    async load() {
      try {
        showLoading();

        this.perfis = this.$parent.perfis;

        this.usuario = await UsuarioService.findById(
          this.$parent.detalheUsuarioId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-usuario-detalhe').modal('show');
      }
    },
  },
};
</script>
