<style lang="scss"></style>

<template>
  <div id="modal-cliente" class="modal modal-max-poli fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4 class="modal-title">{{ cliente.id > 0 ? 'ALTERAR' : 'CADASTRAR' }} CLIENTE</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-block shadow0 m0 pb0">
                  <div class="read-form">
                    <div class="title-block">
                      <h3 class="title">Dados</h3>
                    </div>
                    <div class="row form-group">
                      <div class="form-group m0 col-md-3">
                        <label class="control-label">Código</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.codigo"
                        />
                      </div>

                      <div
                        class="form-group m0 col-md-3"
                        :class="{ 'has-error': $v.cliente.documento.$error }"
                      >
                        <label class="control-label">CNPJ/CPF</label>
                        <the-mask
                          class="form-control underlined"
                          v-model.trim="$v.cliente.documento.$model"
                          :masked="true"
                          :mask="['###.###.###-##', '##.###.###/####-##']"
                        />
                        <template v-if="$v.cliente.documento.$dirty">
                          <span
                            v-if="!$v.cliente.documento.required"
                            class="has-error lblerror"
                          >Campo obrigatório</span>
                          <span
                            v-if="$v.cliente.documento.required &&
                            !$v.cliente.documento.isCPFCNPJValid"
                            class="has-error"
                          >CPF/CNPJ inválido</span>
                        </template>
                      </div>

                      <div
                        class="form-group m0 col-md-6"
                        :class="{ 'has-error': $v.cliente.nome.$error }"
                      >
                        <label class="control-label">Nome</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="$v.cliente.nome.$model"
                        />
                        <template v-if="$v.cliente.nome.$dirty">
                          <span
                            v-if="!$v.cliente.nome.required"
                            class="has-error lblerror lblerror"
                          >Campo obrigatório</span>
                        </template>
                      </div>
                    </div>

                    <div class="row form-group">
                      <div
                        class="form-group m0 col-md-4"
                        :class="{ 'has-error': $v.cliente.telefone.$error }"
                      >
                        <label class="control-label">Telefone</label>
                        <the-mask
                          class="form-control underlined"
                          v-model.trim="$v.cliente.telefone.$model"
                          minlength="14"
                          maxlength="15"
                          :masked="true"
                          :mask="['(##) ####-####', '(##) #####-####']"
                        />
                        <template v-if="$v.cliente.telefone.$dirty">
                          <span
                            v-if="!$v.cliente.telefone.required"
                            class="has-error lblerror"
                          >Campo obrigatório</span>
                          <span
                            v-if="$v.cliente.telefone.required &&
                            !$v.cliente.telefone.minlength"
                            class="has-error"
                          >Telefone inválido</span>
                        </template>
                      </div>

                      <div
                        class="form-group m0 col-md-4"
                        :class="{ 'has-error': $v.cliente.celular.$error }"
                      >
                        <label class="control-label">Celular</label>
                        <the-mask
                          class="form-control underlined"
                          v-model.trim="$v.cliente.celular.$model"
                          minlength="14"
                          maxlength="15"
                          :masked="true"
                          :mask="['(##) ####-####', '(##) #####-####']"
                        />
                        <template v-if="$v.cliente.celular.$dirty">
                          <span
                            v-if="!$v.cliente.celular.minlength"
                            class="has-error"
                          >Celular inválido</span>
                        </template>
                      </div>

                      <div
                        class="form-group m0 col-md-4"
                        :class="{ 'has-error': $v.cliente.comercial.$error }"
                      >
                        <label class="control-label">Comercial</label>
                        <the-mask
                          class="form-control underlined"
                          v-model.trim="$v.cliente.comercial.$model"
                          minlength="14"
                          maxlength="15"
                          :masked="true"
                          :mask="['(##) ####-####', '(##) #####-####']"
                        />
                        <template v-if="$v.cliente.comercial.$dirty">
                          <span
                            v-if="!$v.cliente.comercial.minlength"
                            class="has-error"
                          >Telefone inválido</span>
                        </template>
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="form-group m0 col-md-12">
                        <label class="control-label">E-mail</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.email"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="form-group m0 col-md-6">
                        <label class="control-label">Grupo</label>
                        <select
                          class="c-select form-control underlined"
                          v-model="cliente.groupId"
                          @change="selecionarGrupo"
                        >
                          <option value>Nenhum</option>
                          <option
                            v-for="grupo in grupoClientes"
                            :key="grupo.id"
                            :value="grupo.id"
                          >{{ grupo.nome }}</option>
                          <option value="-1">Novo Grupo</option>
                        </select>
                      </div>
                      <div v-if="cliente.groupId && (cliente.groupId * 1) === -1 "
                        class="form-group m0 col-md-6"
                        :class="{ 'has-error': $v.cliente.group.$error }"
                      >
                        <label class="control-label">Nome do grupo</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="$v.cliente.group.$model"
                        />
                        <template v-if="$v.cliente.group.$dirty">
                          <span
                            v-if="!$v.cliente.group.required"
                            class="has-error"
                          >Campo obrigatório</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card card-block shadow0 m0 pb0">
                  <div class="read-form">
                    <div class="title-block">
                      <h3 class="title">Endereco</h3>
                    </div>
                    <div class="row form-group">
                      <div
                        class="form-group m0 col-md-3"
                        :class="{ 'has-error': $v.cliente.cep.$error }"
                      >
                        <label class="control-label">CEP</label>
                        <the-mask
                          class="form-control underlined"
                          v-model.trim="$v.cliente.cep.$model"
                          minlength="9"
                          maxlength="9"
                          :masked="true"
                          :mask="['#####-###']"
                        />
                        <template v-if="$v.cliente.cep.$dirty">
                          <span
                            v-if="!$v.cliente.cep.required"
                            class="has-error lblerror"
                          >Campo obrigatório</span>
                          <span
                            v-if="$v.cliente.cep.required &&
                            !$v.cliente.cep.minlength"
                            class="has-error"
                          >CEP inválido</span>
                        </template>
                      </div>

                      <div class="form-group m0 col-md-9">
                        <label class="control-label">Endereço</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.endereco"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <div class="form-group m0 col-md-3">
                        <label class="control-label">Número</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.numero"
                        />
                      </div>
                      <div class="form-group m0 col-md-4">
                        <label class="control-label">Complemento</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.complemento"
                        />
                      </div>

                      <div class="form-group m0 col-md-5">
                        <label class="control-label">Bairro</label>
                        <input
                          type="text"
                          class="form-control underlined"
                          v-model.trim="cliente.bairro"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <div
                        class="form-group m0 col-md-4"
                        :class="{ 'has-error': $v.cliente.estadoId.$error }"
                      >
                        <label class="control-label">Estado</label>
                        <select
                          class="c-select form-control underlined"
                          v-model.trim="$v.cliente.estadoId.$model"
                          @change="buscarCidades()"
                        >
                          <option value>Selecione um Estado</option>
                          <option
                            v-for="estado in estados"
                            :key="estado.id"
                            :value="estado.id"
                          >{{ estado.uf }}</option>
                        </select>
                        <template v-if="$v.cliente.estadoId.$dirty">
                          <span
                            v-if="!$v.cliente.estadoId.required"
                            class="has-error lblerror lblerror"
                          >Campo obrigatório</span>
                        </template>
                      </div>

                      <div
                        class="form-group m0 col-md-6"
                        :class="{ 'has-error': $v.cliente.cidadeId.$error }"
                      >
                        <label class="control-label">Cidade</label>
                        <select
                          class="c-select form-control underlined"
                          v-model.trim="$v.cliente.cidadeId.$model"
                        >
                          <option value>Selecione uma Cidade</option>
                          <option
                            v-for="cidade in cidades"
                            :key="cidade.id"
                            :value="cidade.id"
                          >{{ cidade.nome }}</option>
                        </select>
                        <template v-if="$v.cliente.cidadeId.$dirty">
                          <span
                            v-if="!$v.cliente.cidadeId.required"
                            class="has-error lblerror lblerror"
                          >Campo obrigatório</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? 'SALVANDO...' : 'SALVAR' }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../helpers';
import ClienteService from '../../services/ClienteService';
import EstadoService from '../../services/EstadoService';
import GrupoClienteService from '../../services/GrupoClienteService';

export default {
  name: 'modal-cliente',
  data() {
    return {
      estados: [],
      cidades: [],
      grupoClientes: [],
      enviando: false,
      cliente: {
        id: 0,
        codigo: '',
        documento: '',
        nome: '',
        email: '',
        telefone: '',
        celular: '',
        comercial: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidadeId: '',
        estadoId: '',
        groupId: '',
        group: '',
      },
    };
  },
  validations() {
    const validates = {
      documento: {
        required,
        isCPFCNPJValid,
      },
      nome: {
        required,
      },
      telefone: {
        required: requiredIf(
          () => this.cliente.telefone,
        ),
        minlength: minLength(10),
      },
      celular: {
        minlength: minLength(10),
      },
      comercial: {
        minlength: minLength(10),
      },
      cep: {
        required: requiredIf(
          () => this.cliente.cep,
        ),
        minlength: minLength(9),
      },
      group: {
        required: requiredIf(
          () => this.cliente.groupId && (this.cliente.groupId * 1) === -1,
        ),
      },
      cidadeId: {
        required,
      },
      estadoId: {
        required,
      },
    };

    return { cliente: validates };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-cliente').on('hidden.bs.modal', () => {
      this.clear();
      this.$parent.clienteId = null;
    });

    if (this.$parent.clienteId === null) return;

    this.load();
  },
  methods: {
    selecionarGrupo() {
      if (this.cliente.groupId && (this.cliente.groupId * 1) === -1) {
        this.cliente.group = '';
      }
    },
    clearClient() {
      this.cliente.id = 0;
      this.cliente.documento = '';
      this.cliente.nome = '';
      this.cliente.email = '';

      this.cliente.telefone = '';
      this.cliente.celular = '';
      this.cliente.comercial = '';

      this.cliente.cep = '';
      this.cliente.endereco = '';
      this.cliente.numero = '';
      this.cliente.complemento = '';
      this.cliente.bairro = '';
      this.cliente.cidadeId = '';

      this.cliente.codigo = '';

      this.cliente.groupId = '';
      this.cliente.group = '';
    },
    clear() {
      this.enviando = false;
      this.cidades = [];
      this.grupoClientes = [];
      this.clearClient();
    },
    async load() {
      try {
        showLoading();

        this.estados = this.$parent.estados;

        this.grupoClientes = await GrupoClienteService.findAll();

        if (this.$parent.clienteId === 0) return;

        const cliente = await ClienteService.findById(this.$parent.clienteId);
        const estadoId = cliente.cidade.estado.id;
        const cidadeId = cliente.cidade.id;
        this.cidades = await EstadoService.findCities(estadoId);

        this.cliente.id = cliente.id;
        this.cliente.documento = cliente.documento;
        this.cliente.nome = cliente.nome;
        this.cliente.email = cliente.email;

        this.cliente.telefone = cliente.telefone;
        this.cliente.celular = cliente.celular;
        this.cliente.comercial = cliente.comercial;

        this.cliente.cep = cliente.cep;
        this.cliente.endereco = cliente.endereco;
        this.cliente.numero = cliente.numero;
        this.cliente.complemento = cliente.complemento;
        this.cliente.bairro = cliente.bairro;
        this.cliente.estadoId = estadoId;
        this.cliente.cidadeId = cidadeId;

        this.cliente.codigo = cliente.codigo;

        this.cliente.groupId = '';
        this.cliente.group = '';

        if (cliente.grupo) {
          this.cliente.groupId = cliente.grupo.id;
          this.cliente.group = cliente.grupo.nome;
        }
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-cliente').modal('show');
      }
    },
    async salvar() {
      this.$v.cliente.$touch();

      if (this.$v.cliente.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const model = this.cliente;
        delete model.estadoId;

        await ClienteService.save(model);

        this.$toast.success('Cliente salvo com sucesso!');

        this.$emit('reload');

        // eslint-disable-next-line
        $('#modal-cliente').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async buscarCidades() {
      try {
        this.cliente.cidadeId = '';

        if (!this.cliente.estadoId || this.cliente.estadoId <= 0) {
          this.cidades = [];
          return;
        }

        showLoading();

        this.cidades = await EstadoService.findCities(this.cliente.estadoId);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
  },
};
</script>
