import Api from './Api';

class EstadoService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/estados', filters);
  }

  async save(data) {
    const id = data.id || 0;

    if (id === 0) {
      return this.$api.post('/estado', data);
    }

    return this.$api.put(`/estado/${id}`, data);
  }

  async findById(id) {
    return this.$api.get(`/estado/${id}`);
  }

  async remove(id) {
    return this.$api.delete(`/estado/${id}`);
  }

  async findCities(id) {
    return this.$api.get(`/estado/${id}/cidades`);
  }
}

export default new EstadoService();
