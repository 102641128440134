<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Consultar Atendimentos</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="filterAtendimentos(1)">
          <div class="card card-block">
            <div class="row form-group">
              <div class="form-group m0 col-md-7">
                <label class="control-label">Cliente</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.client" />
              </div>

              <div
                class="form-group m0 col-md-3"
                :class="{ 'has-error': $v.filters.document.$error }"
              >
                <label class="control-label">CPF/CNPJ</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.filters.document.$model"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
                <template v-if="$v.filters.document.$dirty">
                  <span
                    v-if="!$v.filters.document.isCPFCNPJValid"
                    class="has-error aasd"
                  >CPF/CNPJ inválido</span>
                </template>
              </div>

              <div class="form-group m0 col-md-2">
                <label class="control-label">Estado</label>
                <select class="c-select form-control underlined" v-model="filters.stateId">
                  <option value>Todos</option>
                  <option
                    v-for="estado in estados"
                    :key="estado.id"
                    :value="estado.id"
                  >{{ estado.uf }}</option>
                </select>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col">
                <label class="control-label">Vendedor</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.seller" />
              </div>

              <div class="form-group m0 col">
                <label class="control-label">Campanha</label>
                <input
                  type="text"
                  class="form-control underlined asd asd"
                  v-model.trim="filters.campaign"
                />
              </div>

              <div class="form-group m0 col">
                <label class="control-label">Ramo de Atividade</label>
                <input
                  type="text"
                  class="form-control underlined asd asd"
                  v-model.trim="filters.ramoAtividade"
                />
              </div>

              <div class="form-group m0 col">
                <label class="control-label">Tipo de Atendimento</label>
                <input
                  type="text"
                  class="form-control underlined asdas"
                  v-model.trim="filters.category"
                />
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-7">
                <label class="control-label">Contato</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.contact" />
              </div>

              <div
                class="form-group m0 col-md-5"
                :class="{ 'has-error': $v.filters.emailContact.$error }"
              >
                <label class="control-label">E-mail</label>
                <input
                  type="email"
                  class="form-control underlined"
                  v-model.trim="$v.filters.emailContact.$model"
                />
                <template v-if="$v.filters.emailContact.$dirty">
                  <span
                    v-if="!$v.filters.emailContact.email"
                    class="has-error assdaasd"
                  >E-mail inválido</span>
                </template>
              </div>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-3">
                <label class="control-label">Protocolo</label>
                <input type="text" class="form-control underlined" v-model.trim="filters.prot" />
              </div>

              <div class="form-group m0 col-md-6">
                <label class="control-label">Data</label>
                <div class="row">
                  <div class="col-md-5">
                    <vc-date-picker
                      v-model="dateStart"
                      locale="pt-BR"
                      :max-date="dateEnd"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                  </div>
                  <div class="col-md-2 text-center">
                    <p class="p0 m0" style="margin-top: 7px;">até</p>
                  </div>
                  <div class="col-md-5">
                    <vc-date-picker
                      v-model="dateEnd"
                      locale="pt-BR"
                      :min-date="dateStart"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                    >
                      <input
                        type="tel"
                        v-mask="'##/##/####'"
                        class="form-control underlined"
                        slot-scope="{ inputProps, inputEvents }"
                        v-bind="inputProps"
                        v-on="inputEvents"
                      />
                    </vc-date-picker>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-if="atendimentos" class="row">
      <div class="col-md-12">
        <div class="card card-block">
          <div class="title-block">
            <h3 class="title">
              {{ atendimentos.count }}
              {{ atendimentos.count > 1 ? "ATENDIMENTOS ENCONTRADO" : "ATENDIMENTO ENCONTRADO" }}
            </h3>
          </div>

          <div class="row form-group">
            <div class="form-group m0 col-md-11"></div>
            <div class="form-group m0 col-md-1 pl0">
              <select
                class="c-select form-control underlined"
                v-model="filters.limit"
                @change="filterAtendimentos(1)"
              >
                <option value>Qtd</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th>#</th>
                  <th>Cliente</th>
                  <th>Data</th>
                  <th>Contato</th>
                  <th>Telefone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="atendimentos && atendimentos.data.length > 0">
                <tr
                  v-for="item in atendimentos.data"
                  :key="item.id"
                  v-tooltip="{
                    content: `<b>Tipo:</b><br/> ${item.tipo}
                              <br/><br/>
                              <b>Observação:</b><br/> ${item.observacao}
                              <br/><br/>
                              <b>Campanha:</b><br/> ${item.campanha}
                              <br/><br/>
                              <b>Vendedor:</b><br/> ${item.vendedor}
                              <br/><br/>
                              <b>Ramo de Atividade:</b><br/> ${item.ramoAtividade}`,
                    placement: 'left',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip']
                  }"
                >
                  <th scope="row">
                    <router-link
                      :to="{ name: 'atendimento-detalhes',
                          params: { id: item.id } }"
                      class="link-detalhes text-info"
                    >{{ item.protocolo }}</router-link>
                  </th>
                  <td>{{ item.cliente }}</td>
                  <td>{{ formatDate(item.data) }}</td>
                  <td>{{ item.contato }}</td>
                  <td>{{ item.telefone }}</td>
                  <td>
                    <router-link
                      target="_blank"
                      :to="{ name: 'cliente-atendimento-editar',
                      params: { id: item.clienteId, atendimentoId: item.id } }"
                      class="btn btn-sm btn-info-outline m0"
                      :class="{ disabled: !item.clienteId || !item.podeEditar }"
                    >EDITAR</router-link>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="atendimentos.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum atendimento encontrado!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="atendimentos.pages > 1" class="form-group text-right">
            <paginate
              v-model="atendimentos.page"
              :page-count="atendimentos.pages"
              :click-handler="nextPrevPageAtendimentos"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>

          <div v-if="atendimentos.count > 0" class="form-group text-right m0 p0">
            <button
              type="button"
              class="btn btn-primary m0 asdf"
              @click="exportAtendimentos"
              :disabled="exportando"
            >{{ exportando ? "EXPORTANDO..." : "EXPORTAR" }}</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import moment from 'moment';
import { email } from 'vuelidate/lib/validators';
import Paginate from 'vuejs-paginate';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';

import AtendimentoService from '../../../services/AtendimentoService';
import EstadoService from '../../../services/EstadoService';

export default {
  name: 'atendimento-report',
  components: {
    Paginate,
  },
  created() {
    this.load();
  },
  data() {
    return {
      enviando: false,
      exportando: false,
      estados: [],
      dateStart: null,
      dateEnd: null,
      filters: {
        page: '1',
        limit: '5',
        client: '',
        contact: '',
        emailContact: '',
        document: '',
        seller: '',
        campaign: '',
        ramoAtividade: '',
        category: '',
        stateId: '',
        prot: '',
        start: '',
        end: '',
      },
      atendimentos: null,
    };
  },
  validations: {
    filters: {
      emailContact: {
        email: value => email(value.toLowerCase()),
      },
      document: {
        isCPFCNPJValid,
      },
    },
  },
  watch: {
    dateStart(date) {
      if (!date) {
        this.filters.start = '';
        return;
      }

      this.filters.start = moment(date).format('DD/MM/YYYY');
    },
    dateEnd(date) {
      if (!date) {
        this.filters.end = '';
        return;
      }

      this.filters.end = moment(date).format('DD/MM/YYYY');
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YY');
    },
    searchParamsAndFilter(initial) {
      if (Object.keys(this.$route.query).length > 0) {
        const {
          page,
          limit,
          client,
          contact,
          emailContact,
          document,
          seller,
          campaign,
          ramoAtividade,
          category,
          stateId,
          prot,
          start,
          end,
        } = this.$route.query;
        this.filters.page = page;
        this.filters.limit = limit;
        this.filters.client = client;
        this.filters.contact = contact;
        this.filters.emailContact = emailContact;
        this.filters.document = document;
        this.filters.seller = seller;
        this.filters.campaign = campaign;
        this.filters.ramoAtividade = ramoAtividade;
        this.filters.category = category;
        this.filters.stateId = stateId;
        this.filters.prot = prot;
        this.filters.start = start;
        this.filters.end = end;

        this.filterAtendimentos(page, initial);
      } else {
        this.filterAtendimentos(1, initial);
      }
    },
    async load() {
      try {
        showLoading();

        this.searchParamsAndFilter(true);

        this.estados = await EstadoService.findAll();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async filterAtendimentos(page, initial) {
      this.$v.filters.$touch();

      if (this.$v.filters.$invalid) return;

      try {
        this.enviando = true;

        this.filters.page = page;

        if (!initial) {
          this.$router.push({ query: this.filters });
        }

        showLoading();

        this.atendimentos = await AtendimentoService.findAll(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async exportAtendimentos() {
      this.exportando = true;

      try {
        showLoading();

        await AtendimentoService.exportExcel(this.filters);

        setTimeout(() => {
          closeLoading();

          this.exportando = false;
        }, 200);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      }
    },
    async nextPrevPageAtendimentos(page) {
      return this.filterAtendimentos(page);
    },
  },
};
</script>
