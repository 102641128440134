import Index from './Index.vue';
import AtendimentosRoutes from './atendimento/routes';
import AtendimentosVendedoresRoutes from './atendimentos-vendedor/routes';
import VendasRoutes from './venda/routes';
import VendedoresRoutes from './vendedor/routes';

const sharedRoutes = [];

const children = sharedRoutes.concat(AtendimentosRoutes,
  AtendimentosVendedoresRoutes,
  VendasRoutes,
  VendedoresRoutes);

export default {
  path: '/',
  component: Index,
  children,
};
