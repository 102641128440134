<style lang="scss"></style>

<template>
  <div
    id="modal-pedido-ordem-producao"
    class="modal modal-max-poli fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <form @submit.prevent="salvar">
          <div class="modal-header">
            <h4
              class="modal-title"
            >{{ ordemProducao.id !== 0 ? 'ALTERAR' : 'CADASTRAR' }} ORDEM DE PRODUÇÃO</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="row">
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': $v.ordemProducao.numOP.$error }"
                >
                  <label class="control-label">Número</label>
                  <input
                    type="text"
                    class="form-control underlined"
                    v-model="$v.ordemProducao.numOP.$model"
                  />
                  <template v-if="$v.ordemProducao.numOP.$dirty">
                    <span
                      v-if="!$v.ordemProducao.numOP.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div
                  class="form-group col-md-8"
                  :class="{ 'has-error': $v.ordemProducao.produtoId.$error }"
                >
                  <label class="control-label">Produto</label>
                  <select
                    class="c-select form-control underlined"
                    @change.prevent="selecionarTipoProduto"
                    v-model="$v.ordemProducao.produtoId.$model"
                  >
                    <option value>Selecione um produto...</option>
                    <option
                      v-for="tipo in tiposProduto"
                      :key="tipo.id"
                      :value="tipo.id"
                    >{{ tipo.nome }}</option>
                  </select>
                  <template v-if="$v.ordemProducao.produtoId.$dirty">
                    <span
                      v-if="!$v.ordemProducao.produtoId.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>

              <div
                class="row form-group"
                v-if="tipoProduto && tipoProduto.temCor"
                :class="{ 'has-error': $v.ordemProducao.coresId.$error }"
              >
                <div class="form-group col-md-4 m0">
                  <label class="control-label">Cores</label>
                  <select
                    id="dplCor1"
                    class="c-select form-control underlined"
                    @change.prevent="selecionarCor"
                  >
                    <option value>Selecione uma cor...</option>
                    <option
                      v-for="tipoCor in tipoCores"
                      :key="tipoCor.id"
                      :value="tipoCor.id"
                    >{{ tipoCor.nome }}</option>
                  </select>
                </div>
                <div class="form-group col-md-4 m0">
                  <label class="control-label">&nbsp;</label>
                  <select
                    id="dplCor2"
                    class="c-select form-control underlined"
                    @change.prevent="selecionarCor"
                  >
                    <option value>Selecione uma cor...</option>
                    <option
                      v-for="tipoCor in tipoCores"
                      :key="tipoCor.id"
                      :value="tipoCor.id"
                    >{{ tipoCor.nome }}</option>
                  </select>
                </div>
                <div class="form-group col-md-4 m0">
                  <label class="control-label">&nbsp;</label>
                  <select
                    id="dplCor3"
                    class="c-select form-control underlined"
                    @change.prevent="selecionarCor"
                  >
                    <option value>Selecione uma cor...</option>
                    <option
                      v-for="tipoCor in tipoCores"
                      :key="tipoCor.id"
                      :value="tipoCor.id"
                    >{{ tipoCor.nome }}</option>
                  </select>
                </div>
                <template v-if="$v.ordemProducao.coresId.$dirty">
                  <span
                    v-if="$v.ordemProducao.coresId.$error"
                    class="has-error lblerror col-md-12"
                  >Informe pelo menos 1 cor</span>
                </template>
              </div>

              <div
                class="row"
                v-if="tipoProduto &&
                (tipoProduto.temMasterbatch || tipoProduto.temResinaBase)"
              >
                <div class="form-group col-md-4" v-if="tipoProduto && tipoProduto.temMasterbatch">
                  <label class="control-label">MasterBatch</label>
                  <the-mask
                    class="form-control underlined"
                    v-model.trim="ordemProducao.masterbatch"
                    :masked="true"
                    :mask="['AA####']"
                  />
                </div>

                <div
                  class="form-group col-md-8"
                  :class="{ 'has-error': $v.ordemProducao.resinaId.$error }"
                  v-if="tipoProduto && tipoProduto.temResinaBase"
                >
                  <label class="control-label">Resina</label>
                  <select
                    class="c-select form-control underlined"
                    v-model.trim="$v.ordemProducao.resinaId.$model"
                  >
                    <option value>Selecione uma resina...</option>
                    <option
                      v-for="tipo in tiposResina"
                      :key="tipo.id"
                      :value="tipo.id"
                    >{{ tipo.nome }}</option>
                  </select>
                  <template v-if="$v.ordemProducao.resinaId.$dirty">
                    <span
                      v-if="!$v.ordemProducao.resinaId.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>

              <div
                class="row"
                v-if="tipoProduto &&
                (tipoProduto.temAcabamento || tipoProduto.temComposicao || tipoProduto.temMaterial)"
              >
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': $v.ordemProducao.acabamentoId.$error }"
                  v-if="tipoProduto && tipoProduto.temAcabamento"
                >
                  <label class="control-label">Acabamento</label>
                  <select
                    class="c-select form-control underlined"
                    v-model.trim="$v.ordemProducao.acabamentoId.$model"
                  >
                    <option value>Selecione um acabamento...</option>
                    <option
                      v-for="tipo in tiposAcabamento"
                      :key="tipo.id"
                      :value="tipo.id"
                    >{{ tipo.nome }}</option>
                  </select>
                  <template v-if="$v.ordemProducao.acabamentoId.$dirty">
                    <span
                      v-if="!$v.ordemProducao.acabamentoId.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>

                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': $v.ordemProducao.composicaoId.$error }"
                  v-if="tipoProduto && tipoProduto.temComposicao"
                >
                  <label class="control-label">Composição</label>
                  <select
                    class="c-select form-control underlined"
                    v-model.trim="$v.ordemProducao.composicaoId.$model"
                  >
                    <option value>Selecione uma composição...</option>
                    <option
                      v-for="tipo in tiposComposicao"
                      :key="tipo.id"
                      :value="tipo.id"
                    >{{ tipo.nome }}</option>
                  </select>
                  <template v-if="$v.ordemProducao.composicaoId.$dirty">
                    <span
                      v-if="!$v.ordemProducao.composicaoId.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>

                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': $v.ordemProducao.materialId.$error }"
                  v-if="tipoProduto && tipoProduto.temMaterial"
                >
                  <label class="control-label">Material</label>
                  <select
                    class="c-select form-control underlined"
                    v-model.trim="$v.ordemProducao.materialId.$model"
                  >
                    <option value>Selecione um material...</option>
                    <option
                      v-for="tipo in tiposMaterial"
                      :key="tipo.id"
                      :value="tipo.id"
                    >{{ tipo.nome }}</option>
                  </select>
                  <template v-if="$v.ordemProducao.materialId.$dirty">
                    <span
                      v-if="!$v.ordemProducao.materialId.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>

              <div class="row" v-if="tipoProduto && tipoProduto.temMedida">
                <div class="form-group col-md-3">
                  <label class="control-label">Tipo Medida</label>
                  <select
                    class="c-select form-control underlined"
                    v-model="ordemProducao.tipoMedida"
                    @change="selecionarTipoMedida"
                  >
                    <option value="chapa">Chapa</option>
                    <option value="bobina">Bobina</option>
                  </select>
                </div>

                <div
                  class="form-group col-md-3"
                  :class="{ 'has-error': validate && $v.ordemProducao.comprimento.$error }"
                >
                  <label class="control-label">Comprimento</label>
                  <money
                    v-model.trim="$v.ordemProducao.comprimento.$model"
                    v-bind="maskDefault"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.ordemProducao.comprimento.$dirty">
                    <span
                      v-if="$v.ordemProducao.comprimento.$error"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>

                <div
                  class="form-group col-md-3"
                  :class="{ 'has-error': validate && $v.ordemProducao.largura.$error }"
                  v-if="ordemProducao.tipoMedida === 'chapa' "
                >
                  <label class="control-label">Largura</label>
                  <money
                    v-model.trim="$v.ordemProducao.largura.$model"
                    v-bind="maskDefault"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.ordemProducao.largura.$dirty">
                    <span
                      v-if="$v.ordemProducao.largura.$error"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div
                  class="form-group col-md-3"
                  :class="{ 'has-error': validate && $v.ordemProducao.espessura.$error }"
                >
                  <label class="control-label">Espessura</label>
                  <money
                    v-model.trim="$v.ordemProducao.espessura.$model"
                    v-bind="maskEspessura"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.ordemProducao.espessura.$dirty">
                    <span
                      v-if="$v.ordemProducao.espessura.$error"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>

              <div class="row" v-if="tipoProduto">
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': validate && $v.ordemProducao.quantidade.$error }"
                >
                  <label class="control-label">Quantidade</label>
                  <money
                    v-model.trim="$v.ordemProducao.quantidade.$model"
                    v-bind="maskDefault"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.ordemProducao.quantidade.$dirty">
                    <span
                      v-if="$v.ordemProducao.quantidade.$error"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': $v.ordemProducao.unidadeMedida.$error }"
                >
                  <label class="control-label">Unidade de Medida</label>
                  <select
                    class="c-select form-control underlined"
                    v-model.trim="$v.ordemProducao.unidadeMedida.$model"
                    :disabled="tipoProduto && tipoProduto.unidadeMedida !== 'UN/KG'"
                  >
                    <option value>Selecione uma unidade de medida...</option>
                    <option value="UN">UN</option>
                    <option value="KG">KG</option>
                  </select>
                  <template v-if="$v.ordemProducao.unidadeMedida.$dirty">
                    <span
                      v-if="!$v.ordemProducao.unidadeMedida.required"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
                <div
                  class="form-group col-md-4"
                  :class="{ 'has-error': validate && $v.ordemProducao.preco.$error }"
                >
                  <label class="control-label">Preço</label>
                  <money
                    v-model.trim="$v.ordemProducao.preco.$model"
                    v-bind="maskPrice"
                    class="form-control underlined"
                  ></money>
                  <template v-if="validate && $v.ordemProducao.preco.$dirty">
                    <span
                      v-if="$v.ordemProducao.preco.$error"
                      class="has-error lblerror"
                    >Campo obrigatório</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="enviando"
            >{{ enviando ? 'SALVANDO...' : 'SALVAR' }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCELAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  minValue,
  requiredIf,
} from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../../helpers';
import TipoProdutoService from '../../../services/TipoProdutoService';
import TipoAcabamentoService from '../../../services/TipoAcabamentoService';
import TipoComposicaoService from '../../../services/TipoComposicaoService';
import CorService from '../../../services/CorService';
import TipoResinaService from '../../../services/TipoResinaService';
import TipoMaterialService from '../../../services/TipoMaterialService';

export default {
  name: 'modal-pedido-ordem-producao',
  data() {
    return {
      maskDefault: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      maskEspessura: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      maskPrice: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      enviando: false,
      tiposProduto: [],
      tipoProduto: null,
      tiposAcabamento: [],
      tiposComposicao: [],
      tipoCores: [],
      tiposResina: [],
      tiposMaterial: [],
      validate: false,
      ordemProducao: {
        id: 0,
        numOP: '',
        produtoId: '',
        acabamentoId: '',
        composicaoId: '',
        coresId: [],
        masterbatch: '',
        resinaId: '',
        materialId: '',
        tipoMedida: 'chapa',
        comprimento: '',
        largura: '',
        espessura: '',
        quantidade: '',
        unidadeMedida: '',
        preco: '',
      },
    };
  },
  validations() {
    let validates = {
      numOP: {
        required,
      },
      produtoId: {
        required,
      },
    };

    if (this.ordemProducao.produtoId !== '') {
      validates = {
        numOP: {
          required,
        },
        produtoId: {
          required,
        },
        acabamentoId: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temAcabamento,
          ),
        },
        composicaoId: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temComposicao,
          ),
        },
        coresId: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temCor,
          ),
          minLength: minLength(1),
        },
        resinaId: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temResinaBase,
          ),
        },
        materialId: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temMaterial,
          ),
        },
        comprimento: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temMedida,
          ),
          minValue: minValue(0.0001),
        },
        largura: {
          // eslint-disable-next-line
          required: requiredIf(model => {
            const ehChapa = model.tipoMedida === 'chapa';
            return this.tipoProduto && this.tipoProduto.temMedida && ehChapa;
          }),
          minValue: minValue(0.0001),
        },
        espessura: {
          required: requiredIf(
            () => this.tipoProduto && this.tipoProduto.temMedida,
          ),
          minValue: minValue(0.0001),
        },
        quantidade: {
          required,
          minValue: minValue(0.0001),
        },
        unidadeMedida: {
          required,
        },
        preco: {
          required,
          minValue: minValue(0.01),
        },
      };
    }

    return { ordemProducao: validates };
  },
  mounted() {
    // eslint-disable-next-line
    $('#modal-pedido-ordem-producao').on('hidden.bs.modal', () => {
      this.$parent.ordemProducaoId = null;
      this.$parent.ordemProducao = null;
    });

    if (this.$parent.ordemProducaoId === null) return;

    this.load();
  },
  methods: {
    async selecionarTipoProduto() {
      const { produtoId } = this.ordemProducao;

      const tipoProduto = this.tiposProduto.filter(
        item => item.id === (produtoId || 0) * 1,
      )[0];

      this.tipoProduto = tipoProduto;
      this.validate = false;

      this.ordemProducao.tipoMedida = '';
      this.ordemProducao.comprimento = '';
      this.ordemProducao.largura = '';
      this.ordemProducao.espessura = '';
      if (this.tipoProduto.temMedida) {
        this.ordemProducao.tipoMedida = 'chapa';
        this.ordemProducao.comprimento = '';
        this.ordemProducao.largura = '';
        this.ordemProducao.espessura = '';
      }

      this.ordemProducao.unidadeMedida = '';
      if (this.tipoProduto.unidadeMedida !== 'UN/KG') {
        this.ordemProducao.unidadeMedida = this.tipoProduto.unidadeMedida;
      }

      if (this.tipoProduto.temCor) {
        this.tipoCores = await CorService.findAll();
      }

      if (this.tipoProduto.temResinaBase) {
        this.tiposResina = await TipoResinaService.findAll();
      }

      if (this.tipoProduto.temAcabamento) {
        this.tiposAcabamento = await TipoAcabamentoService.findAll();
      }

      if (this.tipoProduto.temComposicao) {
        this.tiposComposicao = await TipoComposicaoService.findAll();
      }

      if (this.tipoProduto.temMaterial) {
        this.tiposMaterial = await TipoMaterialService.findAll();
      }
    },
    selecionarTipoMedida() {
      const { tipoMedida } = this.ordemProducao;

      if (tipoMedida === 'bobina') {
        this.ordemProducao.largura = '';
      }
    },
    selecionarCor() {
      const cor1 = document.querySelector('#dplCor1').value;
      const cor2 = document.querySelector('#dplCor2').value;
      const cor3 = document.querySelector('#dplCor3').value;

      this.ordemProducao.coresId = [];
      if (cor1) {
        this.ordemProducao.coresId.push(cor1 * 1);
      }

      if (cor2) {
        this.ordemProducao.coresId.push(cor2 * 1);
      }

      if (cor3) {
        this.ordemProducao.coresId.push(cor3 * 1);
      }
    },
    async load() {
      try {
        showLoading();

        this.tiposProduto = await TipoProdutoService.findAll();

        const op = this.$parent.ordemProducao;

        if (op !== null) {
          this.ordemProducao.id = op.id;
          this.ordemProducao.numOP = op.numOP;
          this.ordemProducao.produtoId = op.produtoId;

          await this.selecionarTipoProduto();

          this.ordemProducao.acabamentoId = op.acabamentoId;
          this.ordemProducao.composicaoId = op.composicaoId;
          this.ordemProducao.coresId = op.coresId;

          // eslint-disable-next-line
          this.ordemProducao.coresId.map((cor, index) => {
            const el = document.querySelector(`#dplCor${index + 1}`);
            if (el) {
              el.value = `${cor}`;
            }
          });

          this.ordemProducao.masterbatch = op.masterbatch || '';
          this.ordemProducao.resinaId = op.resinaId;
          this.ordemProducao.materialId = op.materialId;
          this.ordemProducao.tipoMedida = op.tipoMedida;
          this.ordemProducao.comprimento = op.comprimento;
          this.ordemProducao.largura = op.largura;
          this.ordemProducao.espessura = op.espessura;
          this.ordemProducao.quantidade = op.quantidade;
          this.ordemProducao.unidadeMedida = op.unidadeMedida;
          this.ordemProducao.preco = op.preco;
        }
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        // eslint-disable-next-line
        $('#modal-pedido-ordem-producao').modal('show');
      }
    },
    async salvar() {
      this.validate = true;

      this.$v.ordemProducao.$touch();

      if (this.$v.ordemProducao.$invalid) {
        this.$toast.info(
          'Por favor, verifique se todos os campos foi preenchido corretamente!',
        );
        return;
      }

      try {
        this.enviando = true;

        showLoading();

        const data = Object.assign({}, this.ordemProducao);
        data.numOP = data.numOP.toUpperCase();
        data.masterbatch = data.masterbatch.toUpperCase();

        if (data.produtoId) {
          data.produto = this.tiposProduto.filter(
            t => t.id === data.produtoId * 1,
          )[0].nome;
        }

        if (data.acabamentoId) {
          data.acabamento = this.tiposAcabamento.filter(
            t => t.id === data.acabamentoId * 1,
          )[0].nome;
        }

        if (data.composicaoId) {
          data.composicao = this.tiposComposicao.filter(
            t => t.id === data.composicaoId * 1,
          )[0].nome;
        }

        if (data.resinaId) {
          data.resina = this.tiposResina.filter(
            t => t.id === data.resinaId * 1,
          )[0].nome;
        }

        if (data.materialId) {
          data.material = this.tiposMaterial.filter(
            t => t.id === data.materialId * 1,
          )[0].nome;
        }

        if (data.coresId && data.coresId.length > 0) {
          data.cores = (
            data.coresId
              // eslint-disable-next-line
              .map(corId => {
                const cor = this.tipoCores.filter(t => t.id === corId * 1)[0];
                if (cor) {
                  return cor.nome;
                }
              })
              .join(', ') || '-'
          ).trim();
        }

        this.$emit('reload', data);

        // eslint-disable-next-line
        $('#modal-pedido-ordem-producao').modal('hide');
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
      }
    },
  },
};
</script>
