import jwtDecode from 'jwt-decode';
import Api from './Api';
import Permissions from './PermissionService';

class AuthService {
  constructor() {
    this.$api = Api;
  }

  async auth(email, senha) {
    return this.$api.post('/login', {
      email,
      senha,
    });
  }

  verifyToken = () => {
    this.$api.get('/verificar-token').then().catch(() => this.removeAuth());
  }

  isAuthenticated = () => {
    const auth = this.getAuth();
    const data = new Date() / 1000;

    if (auth && auth.exp < data) return false;

    return auth;
  }

  setAuth = (token) => {
    localStorage.setItem('tkn', token);
  }

  getAuth = () => {
    const token = localStorage.getItem('tkn');

    if (!token) return null;

    const data = jwtDecode(token);
    data.token = token;

    return data;
  };

  removeAuth = () => {
    localStorage.removeItem('tkn');
  }

  isAdmin() {
    const auth = this.getAuth();

    if (!auth) return false;

    return auth.acessos.some(a => a.toLowerCase() === Permissions.ADMIN);
  }

  isOperacional() {
    const auth = this.getAuth();

    if (!auth) return false;

    return auth.acessos.some(a => a.toLowerCase() === Permissions.OPERACIONAL);
  }

  isComercial() {
    const auth = this.getAuth();

    if (!auth) return false;

    return auth.acessos.some(a => a.toLowerCase() === Permissions.COMERCIAL);
  }

  isVendedor() {
    const auth = this.getAuth();

    if (!auth) return false;

    if (this.isAdmin() || this.isOperacional() || this.isComercial()) return false;

    return auth.acessos.some(a => a.toLowerCase() === Permissions.VENDEDOR);
  }
}

export default new AuthService();
