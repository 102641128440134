<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Novo Pedido</h3>
    </div>
    <section class="section">
      <div class="row sameheight-container">
        <div class="col-md-6 m-auto">
          <div class="card card-block sameheight-item">
            <div class="title-block">
              <h3 class="title"></h3>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <label class="control-label">Consultar Cliente por:</label>
                <div>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="1"
                      v-model.trim="tipoBuscaId"
                    />
                    <span>CPF/CNPJ</span>
                  </label>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="2"
                      v-model.trim="tipoBuscaId"
                    />
                    <span>Nome</span>
                  </label>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="3"
                      v-model.trim="tipoBuscaId"
                    />
                    <span>Telefone</span>
                  </label>
                  <label>
                    <input
                      class="radio"
                      name="inline-radios"
                      type="radio"
                      value="4"
                      v-model.trim="tipoBuscaId"
                    />
                    <span>Código</span>
                  </label>
                </div>
              </div>

              <div
                v-if="tipoBuscaId === '1'"
                class="form-group"
                :class="{ 'has-error': $v.cpfCnpj.$error }"
              >
                <label class="control-label">Informe o CNPJ/CPF do Cliente:</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.cpfCnpj.$model"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
                <template v-if="$v.cpfCnpj.$dirty">
                  <span v-if="!$v.cpfCnpj.required" class="has-error">Campo obrigatório</span>
                  <span
                    v-if="$v.cpfCnpj.required && !$v.cpfCnpj.isCPFCNPJValid"
                    class="has-error"
                  >CPF/CNPJ inválido</span>
                </template>
              </div>

              <div
                v-if="tipoBuscaId === '2'"
                class="form-group"
                :class="{ 'has-error': $v.nome.$error }"
              >
                <label class="control-label">Informe o Nome do Cliente:</label>
                <input type="text" class="form-control underlined" v-model.trim="$v.nome.$model" />
                <template v-if="$v.nome.$dirty">
                  <span v-if="!$v.nome.required" class="has-error">Campo obrigatório</span>
                </template>
              </div>

              <div
                v-if="tipoBuscaId === '3'"
                class="form-group"
                :class="{ 'has-error': $v.telefone.$error }"
              >
                <label class="control-label">Informe o Telefone do Cliente:</label>
                <the-mask
                  class="form-control underlined"
                  v-model.trim="$v.telefone.$model"
                  minlength="14"
                  maxlength="15"
                  :masked="true"
                  :mask="['(##) ####-####', '(##) #####-####']"
                />
                <template v-if="$v.telefone.$dirty">
                  <span v-if="!$v.telefone.required" class="has-error">Campo obrigatório</span>
                  <span
                    v-if="$v.telefone.required &&
                            !$v.telefone.minlength"
                    class="has-error"
                  >Telefone inválido</span>
                </template>
              </div>

              <div
                v-if="tipoBuscaId === '4'"
                class="form-group"
                :class="{ 'has-error': $v.codigo.$error }"
              >
                <label class="control-label">Informe o Código do Cliente:</label>
                <input
                  type="text"
                  class="form-control underlined asd"
                  v-model.trim="$v.codigo.$model"
                />
                <template v-if="$v.codigo.$dirty">
                  <span v-if="!$v.codigo.required" class="has-error">Campo obrigatório</span>
                </template>
              </div>

              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  :disabled="enviando"
                >{{ enviando ? 'VERIFICANDO...' : 'CONSULTAR' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div v-if="result" class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">
                {{ result.count }}
                {{ result.count > 1 ? "CLIENTES ENCONTRADOS" : "CLIENTE ENCONTRADO" }}
              </h3>
            </div>

            <div class="row form-group">
              <div class="form-group m0 col-md-11"></div>
              <div class="form-group m0 col-md-1 pl0">
                <select
                  class="c-select form-control underlined"
                  v-model="filters.limit"
                  @change="buscarClientes(1)"
                >
                  <option value>Qtd</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <table class="table table-striped">
                <thead class="thead-inverse">
                  <tr>
                    <th width="30%">CPF/CNPJ</th>
                    <th width="40%">Nome</th>
                    <th width="30%">Cidade - UF</th>
                  </tr>
                </thead>
                <tbody v-if="result && result.data.length > 0">
                  <tr v-for="item in result.data" :key="item.id">
                    <th scope="row">
                      <a
                        @click.prevent="novoPedido(item.id)"
                        href="#"
                        class="link-detalhes text-info"
                      >{{ item.documento }}</a>
                    </th>
                    <td>{{ item.nome }}</td>
                    <td>{{ item.cidade }} - {{ item.estado }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="result.pages > 1" class="form-group text-right">
              <paginate
                v-model="result.page"
                :page-count="result.pages"
                :click-handler="nextPrevPage"
                :prev-text="'Anterior'"
                :next-text="'Próxima'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { required, minLength } from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';
import ClienteService from '../../../services/ClienteService';

export default {
  name: 'pedido',
  components: {
    Paginate,
  },
  data() {
    return {
      temQuery: false,
      filters: {
        page: '1',
        limit: '5',
      },
      result: null,
      tipoBuscaId: '1',
      cpfCnpj: '',
      nome: '',
      telefone: '',
      codigo: '',
      enviando: false,
    };
  },
  validations() {
    let validates = {};

    if (this.tipoBuscaId === '1') {
      validates = {
        cpfCnpj: {
          required,
          isCPFCNPJValid,
        },
      };
    }

    if (this.tipoBuscaId === '2') {
      validates = {
        nome: {
          required,
        },
      };
    }
    if (this.tipoBuscaId === '3') {
      validates = {
        telefone: {
          required,
          minlength: minLength(10),
        },
      };
    }

    if (this.tipoBuscaId === '4') {
      validates = {
        codigo: {
          required,
        },
      };
    }

    return validates;
  },
  watch: {
    tipoBuscaId() {
      if (this.temQuery) return;
      this.nome = '';
      this.telefone = '';
      this.cpfCnpj = '';
      this.codigo = '';

      this.$v.$reset();
    },
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      this.temQuery = true;
      const {
        page,
        limit,
        nome,
        telefone,
        cpfCnpj,
        codigo,
        tipoBuscaId,
      } = this.$route.query;
      this.filters.page = page;
      this.filters.limit = limit;
      this.tipoBuscaId = tipoBuscaId;
      this.nome = nome;
      this.telefone = telefone;
      this.cpfCnpj = cpfCnpj;
      this.codigo = codigo;
      this.buscarClientes(1);
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.enviando = true;

      await this.buscarClientes(1);
    },
    async buscarClientes(page) {
      try {
        showLoading();

        this.filters.nome = this.nome;
        this.filters.document = this.cpfCnpj;
        this.filters.telefone = this.telefone;
        this.filters.tipoBuscaId = this.tipoBuscaId;
        this.filters.codigo = this.codigo;
        this.filters.page = page;

        const data = await ClienteService.findAll(this.filters);
        if (data.count === 1) {
          const {
            data: [cliente],
          } = data;
          this.$router.push({
            name: 'cliente-pedidos',
            params: { id: cliente.id },
          });
        } else if (data.count === 0) {
          this.$router.push({
            name: 'pedido-cliente',
            params: {
              document: this.cpfCnpj,
              nome: this.nome,
              telefone: this.telefone,
              codigo: this.codigo,
            },
          });
        } else {
          this.$router.push({ query: this.filters });
          this.result = data;
        }
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
        this.enviando = false;
        this.temQuery = false;
      }
    },
    novoPedido(clienteId) {
      this.$router.push({
        name: 'cliente-pedidos',
        params: { id: clienteId },
      });
    },
    async nextPrevPage(page) {
      return this.buscarClientes(page);
    },
  },
};
</script>
