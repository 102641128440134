<style lang="scss"></style>

<template>
  <table class="table p-0 m-0">
    <thead class="thead-inverse">
      <tr>
        <th class="text-center" :colspan="rowspanProdutos">Produtos</th>
      </tr>
      <tr>
        <th
          class="p-0 m-0 border-0"
          v-for="produto in headersProduto()"
          :key="generateKey(produto.nome)"
        >
          <table class="table p-0 m-0">
            <thead class="thead-inverse">
              <tr>
                <template v-if="produto.nome">
                  <th
                    class="border1px text-center"
                    :colspan="headersMateriais(produto).length"
                    :key="generateKey(produto.nome)"
                  >{{ produto.nome }}</th>
                </template>
              </tr>
              <tr>
                <th
                  class="p-0 m-0 border-0"
                  v-for="material in headersMateriais(produto)"
                  :key="generateKey(material.nome)"
                >
                  <table class="table p-0 m-0">
                    <thead class="thead-inverse">
                      <tr>
                        <template v-if="material.nome">
                          <th
                            class="border1px text-center"
                            :colspan="headersComposicao(material).length"
                          >{{ material.nome }}</th>
                        </template>
                      </tr>

                      <tr>
                        <th
                          class="p-0 m-0 border-0"
                          v-for="composicao in headersComposicao(material)"
                          :key="generateKey(composicao.nome)"
                        >
                          <table class="table p-0 m-0">
                            <thead class="thead-inverse">
                              <tr>
                                <template v-if="composicao.nome">
                                  <th
                                    class="border1px text-center"
                                    :colspan="headersCores(composicao).length"
                                  >{{ composicao.nome }}</th>
                                </template>
                              </tr>

                              <tr>
                                <th
                                  class="p-0 m-0 border-0"
                                  v-for="cor in headersCores(composicao)"
                                  :key="generateKey(cor.nome)"
                                >
                                  <table class="table p-0 m-0" style="min-width:110px; width:100%">
                                    <thead class="thead-inverse">
                                      <tr>
                                        <template v-if="cor.nome">
                                          <th class="border1px text-center">{{ cor.nome }}</th>
                                        </template>
                                      </tr>
                                    </thead>
                                  </table>
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </th>
              </tr>
            </thead>
          </table>
        </th>
      </tr>
    </thead>
  </table>
</template>

<script>
export default {
  name: 'rel-header',
  props: {
    produtos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    headersProduto() {
      return this.produtos;
    },
    headersMateriais(produto) {
      return produto.materiais;
    },
    headersComposicao(material) {
      return material.composicoes;
    },
    headersCores(composicao) {
      return composicao.cores;
    },
    generateKey(nome) {
      const data = new Date();

      const miliseconds = data.getMilliseconds();
      const seconds = data.getSeconds();
      const minutes = data.getMinutes();
      const hours = data.getMinutes();
      return `${nome}-${miliseconds}-${seconds}-${minutes}-${hours}`;
    },
  },
  computed: {
    rowspanProdutos() {
      const qtdProduto = this.produtos.filter(p => p.nome).length;
      return qtdProduto;
    },
  },
};
</script>
