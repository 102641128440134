<template>
  <div class="auth">
    <div class="auth-container">
      <div class="card">
        <header class="auth-header">
          <h1 class="auth-title">
            <img src="../../assets/logo.png" class="img-fluid" style="width: 80%;" alt />
          </h1>
        </header>
        <div class="auth-content">
          <form @submit.prevent="logar">
            <div class="form-group" :class="{ 'has-error': $v.email.$error }">
              <label class="control-label">E-mail</label>
              <input type="email" class="form-control underlined" v-model.trim="$v.email.$model" />
              <template v-if="$v.email.$dirty">
                <span v-if="!$v.email.required" class="has-error asdsa">Campo obrigatório</span>
                <span
                  v-if="$v.email.required
                        && !$v.email.Email"
                  class="has-error"
                >E-mail inválido</span>
              </template>
            </div>

            <div class="form-group" :class="{ 'has-error': $v.senha.$error }">
              <label class="control-label">Senha</label>
              <input
                type="password"
                class="form-control underlined asdasdsa"
                v-model.trim="$v.senha.$model"
              />
              <template v-if="$v.senha.$dirty">
                <span v-if="!$v.senha.required" class="has-error asdas">Campo obrigatório</span>
                <span
                  v-if="$v.senha.required && !$v.senha.minlength"
                  class="has-error"
                >Min. 8 caracteres</span>
              </template>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-block btn-primary"
                :disabled="enviando"
              >{{ enviando ? "ENTRANDO..." : "ENTRAR" }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email as Email, minLength } from 'vuelidate/lib/validators';
import { showLoading, closeLoading } from '../../helpers';

import AuthService from '../../services/AuthService';

export default {
  name: 'login-index',
  props: {
    msg: null,
  },
  created() {
    if (this.msg) {
      this.$toast.error(this.msg);
    }
  },
  data() {
    return {
      enviando: false,
      email: '',
      senha: '',
    };
  },
  validations: {
    email: {
      required,
      Email: value => Email(value.toLowerCase()),
    },
    senha: {
      required,
      minlength: minLength(8),
    },
  },
  methods: {
    async logar() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const { token } = await AuthService.auth(this.email, this.senha);

        AuthService.setAuth(token);

        this.$toast.success('Usuário logado com sucesso!');

        const { p } = this.$route.query;

        if (p) {
          window.location.href = p;
        } else {
          window.location.href = process.env.VUE_APP_BASE_URL;
        }
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
