import Index from './Index.vue';
import AuthService from '../../../../services/AuthService';

export default {
  path: 'relatorio/vendas',
  name: 'relatorio-vendas',
  beforeEnter: (to, from, next) => {
    if (AuthService.isAdmin() || AuthService.isOperacional() || AuthService.isComercial()) {
      return next();
    }

    return next({
      name: 'home',
      params: {
        accessDenied: true,
      },
    });
  },
  component: Index,
};
