<style lang="scss"></style>

<template>
  <article v-if="atendimento" class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Detalhes Atendimento - #{{ atendimento.protocolo }}</h3>
    </div>
    <section class="section">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Cliente</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-6">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ atendimento.cliente.nome }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">CNPJ/CPF</label>
                  <p class="form-control underlined">{{ atendimento.cliente.documento }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">UF</label>
                  <p class="form-control underlined">{{ atendimento.cliente.estado }}</p>
                </div>
              </div>

              <div v-if="atendimento.cliente.grupo" class="row">
                <div class="form-group m0 col-md-4">
                  <label class="control-label">Grupo</label>
                  <p class="form-control underlined">{{ atendimento.cliente.grupo.nome }}</p>
                </div>
                <div class="form-group m0 col-md-8">
                  <label class="control-label">Clientes</label>
                  <p class="form-control underlined">{{
                    (atendimento.cliente.grupo.clientes || []).join(', ').trim() || '-'
                  }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Contato</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <label class="control-label">Nome</label>
                  <p class="form-control underlined">{{ atendimento.contato }}</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group m0 col-md-9">
                  <label class="control-label">E-mail</label>
                  <p class="form-control underlined">{{ atendimento.email }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">Telefone</label>
                  <p class="form-control underlined">{{ atendimento.telefoneContato }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Atendimento</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-9">
                  <label class="control-label">Tipo</label>
                  <p class="form-control underlined">{{ atendimento.tipo }}</p>
                </div>
                <div class="form-group m0 col-md-3">
                  <label class="control-label">Retorno</label>
                  <p class="form-control underlined">{{ formatDate(atendimento.retorno) }}</p>
                </div>
              </div>

              <div class="row">
                <div class="form-group m0 col-md-6">
                  <label class="control-label">Campanha</label>
                  <p class="form-control underlined">{{ atendimento.campanha }}</p>
                </div>
                <div class="form-group m0 col-md-6">
                  <label class="control-label">Ramo de Atividade</label>
                  <p class="form-control underlined">{{ atendimento.ramoAtividade ||'-' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="ehOrcamento">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Orçamento</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <table class="table m0 table-striped">
                    <thead class="thead-inverse">
                      <tr>
                        <th width="20%">Produto</th>
                        <th width="20%">Cor</th>
                        <th width="30%">Medida (C x L x E)</th>
                        <th width="15%">Quantidade</th>
                        <th width="15%">Preço</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(orcamento, index) in atendimento.orcamentos" :key="index">
                        <td>{{ orcamento.produto }}</td>
                        <td>{{ orcamento.cor }}</td>
                        <td>
                          {{ orcamento.medida }}
                          {{ orcamento.tipoMedida ? `(${orcamento.tipoMedida})` : '' }}
                        </td>
                        <td>
                          {{ orcamento.qtd | currency(maskDefault) }}
                          {{ orcamento.unidadeMedida }}
                        </td>
                        <td>{{ orcamento.preco | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <div class="title-block">
              <h3 class="title">Histórico</h3>
            </div>
            <div class="read-form">
              <div class="row">
                <div class="form-group m0 col-md-12">
                  <table class="table m0 table-striped">
                    <thead class="thead-inverse">
                      <tr>
                        <th width="20%">Data</th>
                        <th width="60%">Histórico</th>
                        <th width="20%">Vendedor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="contato in atendimento.contatos" :key="contato.id">
                        <th>{{ contato.data }}</th>
                        <td>{{ contato.descricao }}</td>
                        <td>{{ contato.vendedor }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button @click.prevent="voltar" type="button" class="btn btn-secondary">VOLTAR</button>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import moment from 'moment';
import { showLoading, closeLoading } from '../../../../helpers';

import AtendimentoService from '../../../../services/AtendimentoService';

export default {
  name: 'cliente-atendimento-detalhes',
  data() {
    return {
      maskDefault: {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 0,
        fractionSeparator: ',',
      },
      clienteId: null,
      atendimentoId: null,
      atendimento: null,
    };
  },
  created() {
    const main = document.querySelector('.main-wrapper');
    if (main) {
      main.scrollTop = 0;
    }

    const clienteId = this.$route.params.id * 1;
    if (!clienteId || Number.isNaN(clienteId)) {
      this.$router.push({ name: 'atendimento' });
      return;
    }

    const atendimentoId = this.$route.params.atendimentoId * 1;
    if (!atendimentoId || Number.isNaN(atendimentoId)) {
      this.$router.push({
        name: 'cliente-atendimentos',
        params: { id: this.clienteId },
      });
      return;
    }

    this.clienteId = clienteId;
    this.atendimentoId = atendimentoId;

    this.loadAtendiment();
  },
  computed: {
    ehOrcamento() {
      const { orcamentos } = this.atendimento;
      return orcamentos && orcamentos.length > 0;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';

      return moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    },
    async loadAtendiment() {
      try {
        showLoading();

        this.atendimento = await AtendimentoService.findById(
          this.atendimentoId,
        );
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
        this.$router.push({
          name: 'cliente-atendimentos',
          params: { id: this.clienteId },
        });
      } finally {
        closeLoading();
      }
    },
    voltar() {
      this.$router.push({
        name: 'cliente-atendimentos',
        params: { id: this.clienteId },
      });
    },
  },
};
</script>
