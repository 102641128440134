import Api from './Api';

class PerfilService {
  constructor() {
    this.$api = Api;
  }

  async findAll(filters) {
    return this.$api.get('/perfis', filters);
  }
}

export default new PerfilService();
