<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Meus Dados</h3>
    </div>
    <section class="section">
      <form @submit.prevent="salvar">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-block shadow0 m0 pb0">
              <div class="read-form">
                <div class="title-block">
                  <h3 class="title">Dados</h3>
                </div>

                <div class="row form-group">
                  <div class="form-group m0 col-md-8" :class="{ 'has-error': $v.data.nome.$error }">
                    <label class="control-label">Nome</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.data.nome.$model"
                    />
                    <template v-if="$v.data.nome.$dirty">
                      <span
                        v-if="!$v.data.nome.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group m0 col-md-4"
                    :class="{ 'has-error': $v.data.documento.$error }"
                  >
                    <label class="control-label">CPF</label>
                    <the-mask
                      class="form-control underlined"
                      v-model.trim="$v.data.documento.$model"
                      :masked="true"
                      :mask="['###.###.###-##']"
                    />
                    <template v-if="$v.data.documento.$dirty">
                      <span
                        v-if="!$v.data.documento.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                      <span
                        v-if="$v.data.documento.required &&
                            !$v.data.documento.isCPFCNPJValid"
                        class="has-error"
                      >CPF inválido</span>
                    </template>
                  </div>
                </div>

                <div class="row form-group">
                  <div
                    class="form-group m0 col-md-8"
                    :class="{ 'has-error': $v.data.cargo.$error }"
                  >
                    <label class="control-label">Cargo</label>
                    <input
                      type="text"
                      class="form-control underlined"
                      v-model.trim="$v.data.cargo.$model"
                    />
                    <template v-if="$v.data.cargo.$dirty">
                      <span
                        v-if="!$v.data.cargo.required"
                        class="has-error lblerror lblerror"
                      >Campo obrigatório</span>
                    </template>
                  </div>

                  <div
                    class="form-group m0 col-md-4"
                    :class="{ 'has-error': $v.data.telefone.$error }"
                  >
                    <label class="control-label">Telefone</label>
                    <the-mask
                      class="form-control underlined"
                      v-model.trim="$v.data.telefone.$model"
                      minlength="14"
                      maxlength="15"
                      :masked="true"
                      :mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <template v-if="$v.data.telefone.$dirty">
                      <span
                        v-if="!$v.data.telefone.required"
                        class="has-error lblerror"
                      >Campo obrigatório</span>
                      <span
                        v-if="$v.data.telefone.required &&
                            !$v.data.telefone.minlength"
                        class="has-error"
                      >Telefone inválido</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-block shadow0 m0 pb0">
              <div class="read-form">
                <div class="title-block">
                  <h3 class="title">Dados de Acesso</h3>
                </div>
                <div class="row form-group">
                  <div
                    class="form-group m0 col-md-12"
                    :class="{ 'has-error': $v.data.email.$error }"
                  >
                    <label class="control-label">E-mail</label>
                    <input
                      type="email"
                      class="form-control underlined"
                      v-model.trim="$v.data.email.$model"
                    />
                    <template v-if="$v.data.email.$dirty">
                      <span
                        v-if="!$v.data.email.required"
                        class="has-error asdsa asdas"
                      >Campo obrigatório</span>
                      <span
                        v-if="$v.data.email.required &&
                            !$v.data.email.Email"
                        class="has-error"
                      >E-mail inválido</span>
                    </template>
                  </div>
                </div>

                <div class="row form-group">
                  <div
                    class="form-group m0 col-md-6"
                    :class="{ 'has-error': $v.acesso.senha.$error }"
                  >
                    <label class="control-label">Senha</label>
                    <input
                      type="password"
                      class="form-control underlined"
                      v-model.trim="$v.acesso.senha.$model"
                    />
                    <template v-if="$v.acesso.senha.$dirty">
                      <span
                        v-if="!$v.acesso.senha.required"
                        class="has-error asdsa asdsa"
                      >Campo obrigatório</span>
                      <span
                        v-if="$v.acesso.senha.required &&
                            !$v.acesso.senha.minlength"
                        class="has-error"
                      >Min. 8 caracteres</span>
                    </template>
                  </div>

                  <div
                    class="form-group m0 col-md-6"
                    :class="{ 'has-error': $v.acesso.confSenha.$error }"
                  >
                    <label class="control-label">Confirmar Senha</label>
                    <input
                      type="password"
                      class="form-control underlined"
                      v-model.trim="$v.acesso.confSenha.$model"
                    />
                    <template v-if="$v.acesso.confSenha.$dirty">
                      <span
                        v-if="!$v.acesso.confSenha.required"
                        class="has-error adsada"
                      >Campo obrigatório</span>
                      <span
                        v-if="$v.acesso.confSenha.required &&
                            !$v.acesso.confSenha.minlength"
                        class="has-error"
                      >Min. 8 caracteres</span>
                      <span
                        v-if="$v.acesso.confSenha.required &&
                            $v.acesso.confSenha.minlength && !$v.acesso.confSenha.sameAsPassword"
                        class="has-error"
                      >Senhas não conferem</span>
                    </template>
                  </div>
                </div>

                <div class="row form-group">
                  <div class="form-group m0 col-md-12">
                    <p class="p0 m0" style="font-size:11px;">
                      A senha deve conter no mínimo 8 caracteres,
                      letras maiúsculas, minúsculass, números e símbolos
                    </p>
                  </div>
                </div>

                <div class="row form-group">
                  <div class="form-group m0 col-md-12 text-right">
                    <button
                      type="submit"
                      class="btn m0 btn-primary"
                      :disabled="enviando"
                    >{{ enviando ? "SALVANDO..." : "SALVAR" }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12"></div>
        </div>
      </form>
    </section>
  </article>
</template>

<script>
import {
  required,
  minLength,
  email as Email,
  sameAs,
} from 'vuelidate/lib/validators';
import { isCPFCNPJValid, showLoading, closeLoading } from '../../../helpers';
import UsuarioService from '../../../services/UsuarioService';
import AuthService from '../../../services/AuthService';

export default {
  name: 'meus-dados',
  data() {
    return {
      enviando: false,
      usuarioId: 0,
      data: {
        id: 0,
        nome: '',
        email: '',
        documento: '',
        telefone: '',
        cargo: '',
      },
      acesso: {
        senha: '',
        confSenha: '',
      },
    };
  },
  validations: {
    data: {
      nome: {
        required,
      },
      email: {
        required,
        Email: value => Email(value.toLowerCase()),
      },
      documento: {
        required,
        isCPFCNPJValid,
      },
      telefone: {
        required,
        minlength: minLength(10),
      },
      cargo: {
        required,
      },
      acessos: {
        required,
      },
    },
    acesso: {
      senha: {
        required,
        minlength: minLength(8),
      },
      confSenha: {
        required,
        minlength: minLength(8),
        sameAsPassword: sameAs('senha'),
      },
    },
  },
  created() {
    const usuarioLogado = AuthService.getAuth();

    this.usuarioId = usuarioLogado.id;

    this.load();
  },
  methods: {
    clear() {
      this.enviando = false;
      this.data.id = 0;
      this.data.nome = '';
      this.data.email = '';
      this.data.documento = '';
      this.data.telefone = '';
      this.data.cargo = '';
      this.data.acessos = [];
      this.acesso.senha = '';
      this.acesso.confSenha = '';
    },
    async load() {
      try {
        showLoading();

        const usuario = await UsuarioService.findById(this.usuarioId);

        this.data.id = usuario.id;
        this.data.nome = usuario.nome;
        this.data.email = usuario.email;
        this.data.documento = usuario.cpf;
        this.data.telefone = usuario.telefone;
        this.data.cargo = usuario.cargo;
        this.data.acessos = usuario.acessos.map(a => a.id);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async salvar() {
      this.$v.data.$touch();

      const id = this.data.id === 0;
      const senha = this.acesso.senha !== '';
      const confSenha = this.acesso.confSenha !== '';

      if (id || senha || confSenha) {
        this.$v.acesso.$touch();

        if (this.$v.acesso.$invalid) return;
      }

      if (this.$v.data.$invalid) return;

      try {
        this.enviando = true;

        showLoading();

        const usuario = {
          id: this.data.id,
          nome: this.data.nome,
          email: this.data.email,
          cargo: this.data.cargo,
          telefone: this.data.telefone,
          documento: this.data.documento,
          senha: this.acesso.senha,
          acessos: this.data.acessos,
        };

        await UsuarioService.save(usuario);

        this.$toast.success('Dados alterado com sucesso!');

        this.acesso.senha = '';
        this.acesso.confSenha = '';
        this.$v.acesso.$reset();
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
  },
};
</script>
