<style lang="scss"></style>

<template>
  <article class="content dashboard-page">
    <div class="title-block">
      <h3 class="title">Relatório de Atendimentos dos Vendedores</h3>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="buscarDados(1)">
          <div class="card card-block">
            <div class="row form-group">
              <div class="form-group m0 col-md-8">
                <label class="control-label">Vendedor</label>
                <select
                  class="c-select form-control underlined"
                  v-model="filters.sellerId"
                >
                  <option value>Todos</option>
                  <option
                    v-for="vendedor in vendedores"
                    :key="vendedor.id"
                    :value="vendedor.id"
                  >{{ vendedor.nome }}</option>
                </select>
              </div>
              <div class="form-group m0 col-md-4">
                <label class="control-label">Data</label>
                <vc-date-picker
                  v-model="range"
                  mode="range"
                  locale="pt-BR"
                  :max-date="new Date()"
                  :popover="{ placement: 'bottom', visibility: 'click' }"
                >
                  <input
                    type="tel"
                    v-mask="'##/##/#### - ##/##/####'"
                    class="form-control underlined"
                    slot-scope="{ inputProps, inputEvents }"
                    v-bind="inputProps"
                    v-on="inputEvents"
                  />
                </vc-date-picker>
              </div>
            </div>

            <div class="form-group text-right m0">
              <button
                type="submit"
                class="btn btn-primary m0"
                :disabled="enviando"
              >{{ enviando ? "BUSCANDO..." : "BUSCAR" }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-if="atendimentos" class="row">
      <div class="col-md-12">
        <div class="card card-block">

          <div class="row form-group">
            <div class="form-group m0 col-md-11"></div>
            <div class="form-group m0 col-md-1 pl0">
              <select
                class="c-select form-control underlined"
                v-model="filters.limit"
                @change="buscarDados(1)"
              >
                <option value>Qtd</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <table class="table table-striped">
              <thead class="thead-inverse">
                <tr>
                  <th>Vendedor</th>
                  <th>Qtd. Atendimentos</th>
                </tr>
              </thead>
              <tbody v-if="atendimentos && atendimentos.data.length > 0">
                <tr
                  v-for="item in atendimentos.data"
                  :key="item.id">
                  <td>{{ item.vendedor }}</td>
                  <td>{{ item.qtd_atendimentos }}</td>
                </tr>
              </tbody>
              <tfoot v-if="atendimentos.data.length <= 0">
                <tr>
                  <td colspan="7">
                    <p class="m0 p0 text-center">Nenhum resultado encontrado!</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div v-if="atendimentos.pages > 1" class="form-group text-right">
            <paginate
              v-model="atendimentos.page"
              :page-count="atendimentos.pages"
              :click-handler="nextPrevPage"
              :prev-text="'Anterior'"
              :next-text="'Próxima'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :prev-class="'page-item'"
              :next-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import { showLoading, closeLoading } from '../../../../helpers';
import VendedorService from '../../../../services/VendedorService';
import AtendimentoService from '../../../../services/AtendimentoService';

export default {
  name: 'relatorio-atendimentos-vendedores',
  components: {
    Paginate,
  },
  created() {
    this.load();
  },
  data() {
    return {
      enviando: false,
      exportando: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
      vendedores: [],
      filters: {
        page: '1',
        limit: '5',
        start: '',
        end: '',
        sellerId: '',
      },
      atendimentos: null,
    };
  },
  methods: {
    searchParamsAndFilter(initial) {
      if (Object.keys(this.$route.query).length > 0) {
        const {
          page,
          limit,
          start,
          end,
          sellerId,
        } = this.$route.query;
        this.filters.page = page;
        this.filters.limit = limit;
        this.filters.start = start;
        this.filters.end = end;
        this.filters.sellerId = sellerId;

        if (moment(start, 'DD/MM/YYYY').isValid()) {
          this.range.start = moment(start, 'DD/MM/YYYY').toDate();
        }

        if (moment(end, 'DD/MM/YYYY').isValid()) {
          this.range.end = moment(end, 'DD/MM/YYYY').toDate();
        }

        this.buscarDados(page, initial);
      } else {
        this.buscarDados(1, initial);
      }
    },
    async load() {
      try {
        showLoading();

        this.vendedores = await VendedorService.findAll();

        this.searchParamsAndFilter(true);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();
      }
    },
    async buscarDados(page, initial) {
      try {
        this.enviando = true;

        this.filters.page = page;

        if (moment(this.range.start).isValid()) {
          this.filters.start = moment(this.range.start).format('DD/MM/YYYY');
        }

        if (moment(this.range.end).isValid()) {
          this.filters.end = moment(this.range.end).format('DD/MM/YYYY');
        }

        if (!initial) {
          this.$router.push({ query: this.filters });
        }

        showLoading();

        this.atendimentos = await AtendimentoService.reportsSellers(this.filters);
      } catch (error) {
        this.$eventBus.$emit('notificationError', error);
      } finally {
        closeLoading();

        this.enviando = false;
      }
    },
    async nextPrevPage(page) {
      return this.buscarDados(page);
    },
  },
};
</script>
